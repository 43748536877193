@font-face {
    font-family: GOTHIC;
    src: url('./../fonts/GOTHIC.TTF');
  }

.login-parent-div{
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
} 

.login-parent-div img{
    width: 100%;
    height: 100%;
    position: absolute;

}
.loginElement{
    width: 32%;
    height: 440px;
    top: 50%;
    border-radius: 10px;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 8px black;
}
.loginLogoElem{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginLogoElem img{
    width: 120px;
    height: 30px;
}
.loginHeadingElem{
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.loginHeadingElem span{
    color: #000;
    font-size: 2.6vw;
    font-weight: 700;
}
.loginToggleBtn{
    width: 100%;
    height: 50px;
}
.loginInputElem{
    width: 100%;
    height: 130px;
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    justify-content: space-around;
}
.loginInput1{
    width: 66%; 
    height: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    border: 1px solid #ff7200;
}
.loginImg{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconxl{
    font-size: 1.3rem;
    color: #727376;
}
.loginInput{
    width:90%;
    height: 100%;
    padding-left: 5px;
}
.loginField{
    width: 99%;
    height: 100%;
    border: none;
    color: grey;
    color: #000;
    font-size: 0.85rem;
}
.loginField::placeholder{
    color: grey;
    font-size: 0.85rem;
}
.iconEye{
    font-size: 1rem;
    color: #727376;
    cursor: pointer;
}
.iconEyeabs{
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 1rem;
    color: #727376;
    cursor: pointer;
}
.loginBtnElem{
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loginBtnElem button{
    width: 25%;
    height: 32px;
    background-color: #ff7200;
    border-radius: 18px ;
    border: none;
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 10px;
    cursor: pointer;
}
.forgotPassSpan{
    color: grey;
    font-size: 0.8rem;
}
.clickPointer{
    cursor: pointer;
    color: #ff7200;
}
.clickPointer:hover{
    text-decoration: underline;
}


.userTypeDiv{
    width: 81%;
    height: 42px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ff7200;
    border-radius: 4px;
    background-color: #fff;
}
.userTypes{
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-left: 1px solid darkgray;
    border-right: 1px solid darkgray;
}
.userTypes:nth-child(1){
    border: none;
}
.userTypes:nth-last-child(1){
    border: none;
}
.userTypesActive{
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #ff7200;
    transition-duration: 0.8s;
}
.userTypeSpan{
    font-size: 0.9rem !important;
    color: #000 !important;
}
.userTypeActiveSpan{
    font-size: 0.9rem !important;
    color: #fff !important;
}
#user_pic{
    font-size: 33px;
    color: white;
    
}
#input-field{
    width: 90%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
   border: 1px solid white;
   border-left: none;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
   font-family:GOTHIC ;
}

::placeholder{color: white;
font-size: 1rem;}

#lock-pic{
    font-size: 33px;
    color: white;
    
}
#input-field1{
    width: 100%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
  background-color: transparent;
   border: 1px solid white;
   border-left: none;
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
   color: #fff;
   letter-spacing: 2.1px;
   font-family:GOTHIC ;
}
input:focus {
    outline-width: 0;
}
#input-field2{
    width: 100%;
    height: 85%;
    position: relative;
    top: 5px;
    padding-left: 20px;
    background-color: transparent;
    border: 1px solid white;
    border-right: none;
    border-left: none;
    color: #fff;
   letter-spacing: 2.1px;
   font-family:GOTHIC ;
}

#show-eye{
    font-size: 17px;
    color: white;
    cursor: pointer;
}
/* Recover modal pop-up */

.recover-moadal, .recover-overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    z-index: 1;
    
}

.recover-overlay{
    background-color: rgba(49,49,49,0.8);
}

.recover-content{
    width: 35%;
    height: 260px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    padding-top: 35px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 2;
}

.recover_data{
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
   background-color: #fff;

}
.inner-div{
    width: 100%;
    height: 90%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
 
}
.recover-heading{
    width: 100%;
    height: 65px;
    border-top-style: none;
}
.recoverSpan{
    font-size: 1.5rem;
    font-weight: 700;
}
.align-cent{
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start;
    font-size: 1.5rem;
}

#heading-cross{
    position: absolute; 
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    right: -17px;
    top: -65px;
    width: 38px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ff7200;
}

.recover-input{
    width: 100%;
    height: auto;
    position: relative;
    border-top-style: none;
    font-family:GOTHIC ;  
}

.recover-button{
    width: 100%;
    height: 80px;
    /* background-color: bisque; */
    font-family:GOTHIC ;
}
.recover-span-field{
    width: 90%;
    height: 30px;
    font-size: 0.85rem;
    color: #727376;
    font-weight: 700;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    font-family:GOTHIC ;    
}
.recover-input-field{
    width: 90%;
    height: 39px;
    border-radius: 0px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    font-family:GOTHIC ; 
}

.recover-input-field input{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-left: 7px;
    font-family:GOTHIC ;
   letter-spacing: 1px;
   font-family:GOTHIC ;
}
.recover-input-field ::placeholder{
    color: #000;
    font-size: 0.65rem;
}

.btn-element{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 32px;
    display: flex;
    flex-direction: column;
}

.btn-element button{
    width: 20%;
    height: 30px;
    border-radius:4px ;
    border: none;
    background-color: #ff7200;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-family:GOTHIC ;
  
}

/* otp modal css */
.recover-content-xl{
    width: 32%;
    height: 270px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    padding-top: 35px;
    padding-bottom: 10px;
    text-align: center;
    z-index: 2;
}
.med-hgt{
    height: 50px;
}
.otp-input{
    width: 65%;
    height: 50px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-around;
}

.input-1, .input-2, .input-3, .input-4{
    width: 17%;
    height: 100%;
    font-family:GOTHIC ;
}

.otp-field{
    width: 100%;
    height: 100%;
    border: 1px solid silver;
    box-shadow: 1px 1px 10px  rgb(0 0 0 / 7%) inset ;
    text-align: center;
    font-size: 33px;

}

.resend{
    font-size: 12px;
    position: relative;
    color: #888;
    font-weight: 700;
    font-family:GOTHIC ;
   
}
#resend-style{
    background-color: #fff;
    width: auto;
    font-size: 15px;
    color: #ff7200;
    font-weight: 800;
    position: relative;
    cursor: pointer; 
    padding-left: 5px;
    text-underline-offset: 4px;
    text-decoration: underline;
}
.otpEye{
    position: relative;
    right: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    color: #000;
}
.verifyDiv{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 25px;
    justify-content: space-around;
}
.verifyDiv button{
    width: 20%;
    height: 28px;
    background-color: #ff7200;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 0.8rem;
    cursor: pointer;
}

/* password modal css */


.password-moadal, .password-overlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: block !important;
    z-index: 1;
}

.password-overlay{
    background-color: rgba(49,49,49,0.8);
}

.password-content{
    width: 32%;
    height: 290px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #ff7200;
    text-align: center;
    z-index: 2;
    padding-top: 35px;
    padding-bottom: 10px;
}

.password_data{
    width: 100%;
    height: 96%;
    position: relative;
    top: 7px;
    background-color: #fff;
}
.password-div{
    width: 100%;
    height: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}
.recover-pass-input{
    width: 100%;
    height: 150px;
    /* border: 1px solid silver; */
    border-top-style: none;
    font-family:GOTHIC ;
}
.pass-inner-div{
    width: 74%;
    height: 120px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.pass-top-div{
    width: 95%;
    height: 40px;
    border: 1px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    border-radius: 4px;
}
.new-pass{
    width: 90%;
    height: 100%;
}
.new-pass input{
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 10px;
    letter-spacing: 1px;
    font-family:GOTHIC ;
    font-size: 0.7rem;
}
.requirements{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#info{
    color: #888;
    cursor: pointer;   
    font-size: 0.9rem; 
}
#green-info{
    color: green;
    cursor: pointer;
    font-size: 0.9rem;
}
.pass-bottom-div{
    width: 95%;
    height: 40px;
    border: 1px solid silver;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    font-size: 0.75rem;
    border-radius: 4px;
}

.confirm-pass{
    width: 90%;
    height: 100%;
    background-color: #ff7200; 
}
.confirm-pass input{
    width: 100%;
    height: 100%;
    border: none;
    font-size: 0.7rem;
    padding-left: 10px;
    letter-spacing: 1px;
    font-family:GOTHIC ;
}
.right-tick{
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-pass ::placeholder,.confirm-pass ::placeholder{
    color: #888;
    font-size: 0.7rem;
    padding-left: 2px;
}
.changePassDiv{
    width: 100%;
    height: 40px;
    display: flex;
    position: relative;
    top: 5px;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
}
.changePassDiv button{
    width: auto;
    height: 28px;
    background-color: #ff7200;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

/* Password ploicies modal */


.policy-moadal{
    width: 100vw;
    height: 10vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;    
    z-index: 1;
}

.recover-overlay{
    background-color: rgba(49,49,49,0.8);
}

.policies-content{
    width: 25%;
    height: auto;
    position: absolute;
    top: 365px;
    left: 80%;
    transform: translate(-50%,-50%);
    line-height: 1.1;
    background-color: #fff;
    border-radius: 14px;   
    text-align: center;
    z-index: 2;
}
.policies-div{
   width: 100%;
   height: 100%;
   position: relative;
   top: 0px;
   padding: 5px;
   border-radius: 5px;  
   margin-left: auto;
   margin-right: auto;
   background-color: #ff7200;   
}
.policies_list{
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    border-radius: 0px;  
    margin-left: auto;
    margin-right: auto;
   background-color: aliceblue;
   display: flex;
   padding-top: 13px;
   padding-bottom: 13px;
}
.policies_list ul{
    width: 100%;
    height: 100%;
    display: inline-block;
    margin:17px 0px;  
    text-align:left;
    font-family:GOTHIC ;
}
.list-1{
    font-size: 0.7rem;
    margin-bottom: 10px;
    color: red;
}
.green-list{
    font-size: 0.7rem;
    margin-bottom: 10px;
    color: green;
    font-weight: 600;
  
}
#policy-cross{
    position: absolute; 
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    right: -17px;
    top: -25px;
    width: 38px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #ff7200;
  
}
#verify{
    color: green;
    border: 1px solid green;
    border-radius: 50%;
}
#hide{display: none !important;}































