a {
  text-decoration: none;
}

a:hover {
  color: #ff7200;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.tt2 {
  font-size: 1rem;
  font-weight: 600;
}

.btn-orange {
  background-color: #fff;
  color: #ff7200;
}

.btn-outline-orange {
  background-color: #fff;
  color: #ff7200;
  border: 1px solid #ff7200;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-orange:hover {
  background-color: #ff7200;
  color: #fff;
}

.flex-algn-cent {
  display: flex;
  align-items: center;
}

.flex-jc-end {
  display: flex;
  justify-content: flex-end;
}

.flex-jc-center {
  display: flex;
  justify-content: center;
}

.flex-inline {
  display: inline-flex !important;
}

.collapse-view {
  height: 35px;
  overflow: hidden;
  position: relative;
}

.collapse-view > .collapsable_icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 35px;
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.collapse-view > .collapsable_icon:after {
  content: "\002B";
  color: white;
  font-weight: bold;
}

.collapse-view.open {
  overflow: unset;
}

.reduceMargin {
  margin-bottom: 1rem !important;
}

.collapse-view.open > .collapsable_icon:after {
  content: "\2212";
}

/* #region Contract Detail */
.contract-tbl {
  width: 100%;
}

.contract-tbl > table,
.contract-tbl1 > table {
  width: 100%;
  border: 1px solid #e5e5e5;
}

.contract-tbl > table,
.contract-tbl > table tr,
.contract-tbl > table td,
.contract-tbl > table th,
.contract-tbl1 > table,
.contract-tbl1 > table tr,
.contract-tbl1 > table td,
.contract-tbl1 > table th {
  border-collapse: collapse;
}

.contract-tbl > table th {
  padding: 10px 15px;
  background-color: #f2f2f2;
  font-weight: unset;
  font-size: 1rem;
  text-align: right;
  width: 15%;
  border-right: 1px solid #e5e5e5;
}

.contract-tbl1 > table th {
  padding: 10px 15px;
  background-color: #f2f2f2;
  font-weight: unset;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #e5e5e5;
  color: #000;
}

.contract-tbl1 > table td {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #e5e5e5;
  color: #000;
}

.contract-tbl > table td {
  padding: 10px;
  width: 85%;
  color: #ff7200;
  font-weight: 600;
  font-size: 1rem;
}

.view_dc_btn {
  position: absolute;
  right: 10px;
  font-size: 0.8rem;
  font-weight: 100;
  text-decoration: underline;
  color: #2d8fb8;
  cursor: pointer;
}

.icncc {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}

/* #endregion Contract Detail */

/*ddzone styling*/
#ddzone_drop-region {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 10px;
  text-align: center;
}

#ddzone_drop-region > .ddzone_drop-con {
  border: 2px dashed #727376;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s;
}

#ddzone_drop-region > .ddzone_drop-con:hover,
#ddzone_drop-region > .ddzone_drop-con.ddzone_hover {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
}

#ddzone_drop-region > .ddzone_drop-con > img {
  width: 100px;
}

#ddzone_drop-region > .ddzone_drop-con > p {
  margin: 5px;
  font-weight: 600;
  color: #727376;
}

#ddzone_drop-region > .ddzone_drop-con > div {
  font-weight: 600;
  color: #727376;
}

#ddzone_image-preview {
  margin-top: 20px;
}

#ddzone_image-preview .ddzone_image-view {
  display: inline-block;
  position: relative;
  margin-right: 13px;
  margin-bottom: 13px;
}

#ddzone_image-preview .ddzone_image-view img {
  max-width: 100%;
}

#ddzone_image-preview .ddzone_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
}

/*ddzone styling close*/

label.labelcc {
  font-weight: 600;
  width: 100%;
  margin-bottom: 0 !important;
  text-align: start;
  color: #000;
}

label.labelcc-new {
  font-weight: 600;
  width: 100%;
  margin-bottom: 0 !important;
  position: relative;
  left: 15px;
  color: #000;
}

.table-responsive {
  width: 100%;
  margin: 10px;
}

table.bordered_table {
  width: 100%;
}

table.bordered_table,
table.bordered_table th,
table.bordered_table td {
  border: 1px solid #727376;
  border-collapse: collapse;
  padding: 2px;
}

table.bordered_table th {
  padding: 5px;
  text-align: start;
}

table.bordered_table th > span.thd {
  font-size: 0.75rem;
  color: #888;
}

table.bordered_table th > h2.tht {
  font-size: 0.9rem;
  color: #3a3b3c;
  margin-bottom: 0;
}

.doc_container > .doc_container_head {
  height: 35px;
  background: #ff7200;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 1rem;
}

.data-cont > .data-cont-item {
  position: relative;
  width: 100%;
  padding: 0px;
  border-radius: 3px;
  margin: 15px 0;
  /* border-bottom: 5px solid rgb(0 0 0 / 10%); */
  box-shadow: 2px 2px 12px 0px rgb(0 0 0 / 12%);
  margin-bottom: 20px;
}
.data-cont > .blackListBrdr {
  box-shadow: 0px 0px 4px 1px red !important;
  border: 1px solid red !important;
}
.blackListBrdr {
  box-shadow: 0px 0px 4px 1px red !important;
  border: 1px solid red !important;
}
.back-color {
  background-color: #edebeb !important;
}

.mg-btm {
  margin-top: 27px !important;
}

.mg-btm-15 {
  margin-bottom: 14px !important;
}

.data-cont > .data-cont-item > .sno_txt {
  position: absolute;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  top: -10px;
  left: -10px;
  border: 2px solid #727376;
  z-index: 1;
  font-size: 10px;
  font-weight: 600;
}

.data-cont > .data-cont-item > .input_div {
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  top: -10px;
  right: -10px;
  border: 2px solid #727376;
  z-index: 1;
  font-size: 10px;
  font-weight: 600;
}

.data-cont > .data-cont-item > .input_div > .input_checkbox {
  position: absolute;
  cursor: pointer;
  width: 1px;
  height: 20px;
}

.data-cont > .data-cont-item > .input_div > .input_checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -7px;
  right: -19px;
  border: 4px solid #ff7200;
  border-radius: 20px;
  background-color: #ff7200;
  transition: all 0.2s linear;
}

.data-cont > .data-cont-item > .input_div > .input_checkbox:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -7px;
  right: -18px;
  border-radius: 20px;
  border: 4px solid #727376;
  background-color: #fff;
}

.data-cont > .data-cont-item > .input_div > .input_checkbox:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: solid #ffcb9a;
  border-width: 0 0px 0px 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 30px;
  transition: all 0.2s linear;
}

.data-cont > .data-cont-item > .input_div > .input_checkbox:checked:after {
  content: "";
  display: block;
  width: 7px;
  height: 14px;
  border: solid #fff;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 2px;
}

.data-cont > .data-cont-item > .data_item_content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding: 1px;
}

.data-cont > .data-cont-item > .data_item_content > div {
  width: calc(20% - 2px);
  padding: 5px;
  margin: 0 1px;
  flex-wrap: wrap;
  background-color: #fff;
}

.ex-div {
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(3% - 2px);
  height: 130px;
}

.data-cont > .data-cont-item > .data_item_content > .data_item_contnet_1 {
  width: 16.5%;
  background: #fff;
  padding: 5px;
  margin: 0 1px;
}

.data-cont > .data-cont-item > .data_item_content.set_height > div {
  height: 50px;
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-around !important;
  border-right: 1px solid #f3f3f3;
  flex-wrap: unset;
}

.data-cont > .data-cont-item > .data_item_content.set_height_xxl > div {
  height: 100%;
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-around !important;
  flex-wrap: unset;
}

.data-cont > .data-cont-item > .data_item_content.setColor > div {
  background-color: #f6f6f6 !important;
  border-right: 1px solid #e8e7e7;
}

.set-bg {
  background-color: #f6f6f6 !important;
}

.data-cont > .data-cont-item > .data_item_content.setColor > div:last-child {
  /* background-color: #f6f6f6 !important; */
  border-right: none;
}

.data-cont > .data-cont-item > .data_item_content.set_height_xxxl > div {
  height: 100%;
  position: relative !important;
  display: flex !important;
  flex-direction: column;
  /* justify-content:  space-around !important; */
  background-color: transparent !important;
}

.setHgt {
  height: 75px !important;
}

.designChange {
  width: auto !important;
  height: auto !important;
  position: absolute !important;
  bottom: -13px;
}

.data-cont > .data-cont-item > .data_item_content > div.wd_5 {
  width: calc(2.5% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_10 {
  width: calc(10% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_15 {
  width: calc(15% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_20 {
  width: calc(20% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_25 {
  width: calc(25% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_30 {
  width: calc(30% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_35 {
  width: calc(35% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_40 {
  width: calc(40% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_50 {
  width: calc(50% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_75 {
  width: calc(75% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_100 {
  width: calc(100% - 2px);
}

.centre-me {
  text-align: center;
}

.more-big {
  font-size: 1.1rem !important;
  color: red;
}

.pd-l {
  padding-left: 20px !important;
}

.pd-r {
  padding-right: 20px !important;
}

.btn_element {
  background-color: #727376;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.black_color {
  color: #000 !important;
  font-size: 0.7rem;
}

.orange_color {
  color: #ff7200 !important;
}

.grey_color {
  color: #727376 !important;
  font-size: 0.75rem;
}

.green_color {
  color: green !important;
}

.red_color {
  color: red !important;
}

.white_color {
  color: #fff !important;
}

.menu-nav > li > a > i.sicon {
  color: #727376;
  font-size: 1.3rem !important;
  margin: 0 17px !important;
}

.menu-nav > li > a > i.ssicon {
  width: 13%;
  color: #727376;
  font-size: 1.3rem !important;
  margin: 0 10px !important;
}

.font-bold {
  font-weight: 600 !important;
}

.font-sm {
  font-size: 0.7rem !important;
}

.font-xsm {
  font-size: 0.6rem !important;
}

.font-md {
  font-size: 0.8rem !important;
}

.font-xmd {
  font-size: 0.9rem !important;
}

.font-lg {
  font-size: 1rem !important;
}

.font-xl {
  font-size: 1.2rem !important;
}

.font-xxl {
  font-size: 1.5rem !important;
}

.input-group-append {
  border: 1px solid #aaa;
  margin-left: 2px;
  border-radius: 5px;
}

.ccIcon {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
}

.cals:active {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
  color: green;
}

.scIcon {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
  color: red;
}

.pinkk {
  color: pink !important;
}

.cals {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
  color: black;
}

/*#region Trip Analysis CSS*/
.bg_black {
  background: #4a4747;
  color: #fff;
}

.table-responsive {
  background: #fff;
  margin-bottom: 2rem;
  border-radius: calc(0.15rem - 1px);
}

.table-responsive > table.cctbl {
  width: 100%;
}

table.cctbl,
.cctbl td,
.cctbl tr,
.cctbl th {
  border: 1px solid #888;
  text-align: center;
  border-collapse: collapse;
}

.cctbl th {
  padding: 5px 0px;
  font-size: 16px;
}

table.cctbl td {
  vertical-align: top;
  width: 33.33%;
}

.dd_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin: -2px;
  border-bottom: 1px solid #888;
  font-weight: 600;
  font-size: 1.3rem;
}

.dd_sec span {
  font-weight: 200;
  font-size: 11px;
  display: block;
  color: #fff !important;
}

.dd_sec > div {
  width: 100%;
  padding: 4px;
}

.dd_sec > div:first-child {
  border-right: 1px solid #888;
}

.dd_sec > div:last-child {
  border-left: 1px solid #888;
  margin-left: -1px;
}

.dd_sec.wt50 > div {
  width: 50% !important;
  padding: 0px;
}

.dd_sec div.btblock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dd_sec div.btblock > span {
  display: block;
  width: 50%;
}

.dd_sec > div.wt50 > div > div {
  width: 100%;
}

.dd_sec div.btblock > span.bold {
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer !important;
  color: #fff;
  margin-right: 0;
}

.dd_sec:not(.wt50) div.btblock > span:not(.bold) {
  font-size: 0.9rem;
}

.dd_sec > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dd_sec > div > span {
  width: 50%;
}

.dd_sec > div > span.bold {
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: 0;
}

.dd_sec > div .popup_ss {
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  justify-content: center;
}

.dd_sec > div .popup_ss > .hh_sec {
  transform: translate(0%, 100%) !important;
  margin-bottom: -40px;
  position: absolute;
  height: fit-content;
  bottom: calc(100% + 10px);
  min-width: auto;
  max-width: 250px;
  font-size: 10px;
  margin-top: -40px;
  padding: 6px;
  color: #666666;
  background-color: #eeeeee;
  border-radius: 8px;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgb(0 0 0 / 50%);
  display: none;
  transition: all 0.8s ease-in-out;
  animation-duration: 0.6s;
  animation-name: fadeIn;
  font-weight: 600;
}

.dd_sec > div .popup_ss:hover > .hh_sec {
  display: block;
}

.dd_sec > div .popup_ss:hover > .hh_sec > i {
  position: absolute;
  bottom: -11px !important;
  left: calc(50% - 12px) !important;
  top: -11px;
  transform: rotate(0deg) !important;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.dd_sec > div .popup_ss:hover > .hh_sec > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.dd_sec > div .popup_ss:hover > .hh_sec > div {
  border-bottom: 1px solid #888;
  color: #ff7200;
  font-size: 11px;
}

.dd_sec > div .popup_ss:hover > .hh_sec > p {
  font-size: 10px;
  line-height: normal;
  font-weight: 200;
  text-align: start;
  margin: 2px 0;
}

.dd_sec > div .popup_ss:hover > .hh_sec > p > b {
  font-weight: 600;
}

.dd_sec > div > .popup_ss span.bold {
  font-size: 12px;
}

.dd_sec > div > .popup_ss span.bold > *,
.dd_sec > div > .popup_ss span.bold {
  font-weight: 600 !important;
  font-size: 12px;
  cursor: pointer;
}

.dd_sec > div > div {
  padding: 2px;
  border-bottom: 1px solid #888;
  cursor: pointer;
}

.dd_sec > div > div:last-child {
  border-bottom: none;
}

.dd_sec.green {
  background: #228b22;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec.green > div,
.dd_sec.green > div > div {
  border-color: #fff;
}

.dd_sec.yellow {
  background: #ffd300;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.yellow {
  background: #ffd300;
}

.dd_sec.yellow > div,
.dd_sec.yellow > div > div {
  border-color: #fff;
}

.dd_sec.purple {
  background: #800080;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.purple {
  background: #800080;
}

.dd_sec.purple > div,
.dd_sec.purple > div > div {
  border-color: #fff;
}

.dd_sec.grey {
  background: #888888;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.grey {
  background: #888888;
}

.dd_sec > div > div.green {
  background: #228b22;
}

.dd_sec.grey > div,
.dd_sec.grey > div > div {
  border-color: #fff;
}

.dd_sec.orange {
  background: #ff7200;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec.orange > div,
.dd_sec.orange > div > div {
  border-color: #fff;
}

.no_cont {
  font-weight: 600;
  color: #808080;
  font-size: 16px;
}

/*#endregion Trip Analysis CSS*/

/*Ananlysis CSS*/

.ddtxt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ddtxt > i {
  transform: rotateY(180deg);
  font-size: 1.4rem;
  margin-right: 5px;
}

.analysis_flex1 {
  width: 100%;
  background: white;
  border-radius: 5px;
  height: 13rem;
}

.analysis_item1 {
  width: 100%;
  height: auto;
  padding: 15px 10px;
  position: relative;
}

.analysis_item1 > h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

.analysis_item2 {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 5px;
  flex-wrap: wrap;
}

.analysis_item2 > div {
  background: #e5e5e5;
  width: calc(33.33% - 10px);
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  margin: 0 5px 5px 5px;
}

.adc22 {
  /* height: 50px; */
  width: 100%;
  position: relative;
}

.adc22 .joining-line {
  position: absolute;
  height: 20px;
  border-right-color: #888;
  border-right-width: 2px;
  border-right-style: dotted;
  top: 12px;
  left: 7px;
}

.adc22 > h5::before {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #888;
  position: absolute;
  content: " ";
  left: -18px;
  top: 0px;
}

.adc22 h5 {
  margin-left: 20px;
  font-size: 0.8rem;
  position: relative;
  min-height: 30px;
  margin-bottom: 0;
}

.adc22 h5 > span {
  font-size: 0.9rem;
  display: block;
}

.adc22 h5 > p {
  width: 80%;
  font-size: 0.8rem;
  line-height: unset;
  margin-bottom: 0;
  color: #4a4646;
  font-family: GOTHIC;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}

.pp1 > div {
  padding: 10px;
  border: 1px solid #ff7200;
}

.pp1 > div.no_brd_rht {
  border-right: none;
}

.ls10 {
  letter-spacing: 10px;
}

.ntc {
  bottom: 15px;
  left: 10%;
  position: absolute;
  height: 20px;
  width: 20px;
  border-left: 1px solid #ff7200;
  border-bottom: 1px solid #ff7200;
  background: #fff;
  transform: rotate(-45deg);
}

.analysis_dotted {
  display: flex;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
}

.analysis_dotted :nth-of-type(1) {
  background-color: #fcc02d;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(2) {
  background-color: #ff7200;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(3) {
  background-color: #9026a7;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(4) {
  background-color: #228622;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_icon {
  text-align: center;
  color: #228622;
  font-weight: bold;
  font-size: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis_icon i.glyph-icon {
  font-weight: 600;
  font-size: 1.2rem;
  margin-right: 5px;
}

.analysis_item {
  width: 100%;
  height: 25px;
  background: #ff7200;
  position: relative;
  display: flex;
  border-radius: 5px;
  margin-top: 40px;
}

.analysis_item > div {
  cursor: pointer;
  position: relative;
}

.analysis_item > div:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.analysis_item > div:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.analysis_item > div > div.popov {
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  justify-content: center;
}

.analysis_item > div > div.popov > div.phub {
  z-index: 1;
  min-width: 150px;
  text-align: center;
  background-color: #ebe4e4;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.analysis_item > div:hover > div.popov > div.phub {
  display: block;
}

.analysis_item > div > div.popov.lft_algn > div.phub {
  left: 0;
}

.analysis_item > div > div.popov.lft_algn > div.phub {
  left: 0;
}

.analysis_item > div > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ff7200;
  font-weight: bold;
}

.analysis_item > div > div.popov > div.phub .ppart span {
  color: #ff7200;
  background-color: #ebe4e4 !important;
  font-weight: bold;
  font-size: 12px !important;
}

.analysis_item > div > div.popov > div.phub .content {
  padding: 5px;
  font-size: 11px;
  word-wrap: break-word;
}

.analysis_item > div > div.popov > div.phub .content > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis_item > div > div.popov > div.phub .content > div > div.dot-icon {
  height: 2px;
  width: 10px;
  position: relative;
  background: #3e3e3e;
  margin: 5px 10px;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::before,
.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::after {
  content: " ";
  height: 8px;
  width: 8px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  border: 1px solid #3e3e3e;
  outline: none;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::before {
  left: -8px;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::after {
  right: -8px;
}

.analysis_item > div > div.popov > div.phub .lpart {
  background: #ff7200;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.analysis_item > div > div.popov > div.phub > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #ff7200;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  z-index: -1;
  bottom: 0;
}

.analysis_item > div > div.popov.lft_algn > div.phub > i::after {
  left: 10%;
}

.analysis_item > div.yellow,
.analysis_item > div.yellow > div.popov > div.phub .lpart {
  background: #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub > i::after {
  background-color: #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub .ppart span {
  color: #ffd300;
}

.analysis_item > div.green,
.analysis_item > div.green > div.popov > div.phub .lpart {
  background: #228b22;
}

.analysis_item > div.green > div.popov > div.phub > i::after {
  background-color: #228b22;
}

.analysis_item > div.green > div.popov > div.phub .ppart {
  border-bottom: 1px solid #228b22;
}

.analysis_item > div.green > div.popov > div.phub .ppart span {
  color: #228b22;
}

.analysis_item > div.purple,
.analysis_item > div.purple > div.popov > div.phub .lpart {
  background: #800080;
}

.analysis_item > div.purple > div.popov > div.phub > i::after {
  background-color: #800080;
}

.analysis_item > div.purple > div.popov > div.phub .ppart {
  border-bottom: 1px solid #800080;
}

.analysis_item > div.purple > div.popov > div.phub .ppart span {
  color: #800080;
}

.analysis_item > div.orange,
.analysis_item > div.orange > div.popov > div.phub .lpart {
  background: #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub > i::after {
  background-color: #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub .ppart span {
  color: #ff7200;
}

.analysis_item > div.orange + div.orange::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #727376;
}

/*Analysis CSS Clse*/

/* #region popover CSS*/
.popdv {
  position: relative;
  display: flex;
}

.popdv .btmplc {
  min-width: 300px;
  top: 30px;
  left: 18%;
  transform: translate(-90%, 0);
  padding: 6px;
  color: #666666;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 2px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  transition: all 0.8s ease-in-out;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

.popdv .btmplc.top {
  transform: translate(-87%, -105%) !important;
  top: 0 !important;
  left: 0 !important;
}

.popdv .btmplc.top > i {
  bottom: -11px !important;
  left: 92% !important;
  transform: rotate(180deg) !important;
}

.popdv:hover .btmplc {
  display: block;
}

/* btmplc */
.popdv .btmplc > i {
  position: absolute;
  bottom: 100%;
  left: 93%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.popdv .btmplc > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/* #endregion popover CSS*/

/* #region Tracking UI*/
.contentmo {
  font-size: 13px;
  vertical-align: bottom;
  color: #ff7200;
  color: #8f8f8f;
  border: 1px solid #ff7200;
  border-radius: 20px 20px 0 0;
  padding-bottom: 5px;
}

.tt-title {
  text-align: center;
  border-bottom: 1px solid #ff7200;
  padding: 4px;
  font-size: 15px;
  line-height: unset;
}

.tt-title > label {
  color: #ff7200;
  margin-bottom: 0;
}

table.tt-cust {
  margin: 0px !important;
  padding: 0px !important;
  border-collapse: collapse;
  width: 100%;
}

table.tt-cust tr:nth-last-child(2) td .circle:before {
  border: 1px solid green;
}

table.tt-cust,
table.tt-cust tr,
table.tt-cust td {
  border: none;
}

table.tt-cust td {
  height: 80px;
  text-align: left;
  vertical-align: top;
  padding: 5px 0 !important;
  text-transform: none;
  position: relative;
  font-size: 11px;
}

table.tt-cust span {
  display: block;
  font-size: 10px;
  text-align: right;
}

.scrollmo {
  max-height: 350px;
  overflow: auto;
}

.scrollmo::-webkit-scrollbar {
  width: 5px;
  padding: 20px;
}

.scrollmo::-webkit-scrollbar-track {
  width: 5px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollmo::-webkit-scrollbar-thumb {
  background-color: #ff7200;
  width: 5px;
  box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.1);
}

#pl {
  padding-left: 15px !important;
}

table.tt-cust strong {
  color: rgb(37, 37, 37);
  font-weight: 600;
}

.greenmm td {
  color: green !important;
}

.greenmm strong {
  color: green !important;
}

.bt td {
  padding-top: 20px !important;
}

.circle {
  position: relative;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 5px;
  margin-top: 0px;
  background-color: white;
  border: 3px solid #3e3e3e;
  z-index: 2;
  margin-left: 25px;
}

.circle:first-child {
  margin-top: 0;
}

div.midd::before {
  position: absolute;
  border: 1px solid #3e3e3e;
  width: 0;
  height: calc(100% - 25px);
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: 25px;
}

.greenmm .circle {
  border: 3px solid green;
}

.greenmm div.midd::before {
  display: none;
}

/* #endregion Close Tracking UI*/

/* #region Address Book UI*/
.book_content > .book_content_item {
  background: #fff;
  border-left: 3px solid #ff7200;
  padding: 5px 30px;
  margin-top: 15px;
  position: relative;
}
.addShadow {
  box-shadow: 1px 1px 6px rgb(0 0 0 / 22%);
}
.book_content > .book_content_item table.book_tbl {
  width: 100%;
}

.book_content > .book_content_item table.book_tbl td {
  display: inline-block;
  justify-content: space-between;
  vertical-align: top;
  padding: 10px 15px;
  width: 20%;
  position: relative;
}

.book_content > .book_content_item table.book_tbl td.wd25 {
  width: 20%;
}

.book_content > .book_content_item table.book_tbl td.actbt {
  width: 10%;
  height: 50%;
  padding: 0;
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.8rem;
}

.addressDiv {
  width: 10%;
  position: absolute;
  right: 0px;
  top: 0px;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt {
  display: block;
  margin-right: 0;
  color: #727376;
  position: relative;
  font-weight: 600;
  font-size: 0.7rem;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > i {
  position: absolute;
  left: -20px;
  color: #ff7200;
  font-size: 1.3rem;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > img {
  position: absolute;
  left: -35px;
  top: -2px;
  height: 22px;
  width: 22px;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > i.ml30,
.book_content > .book_content_item table.book_tbl span.tbl_tt > img.ml30 {
  left: -30px;
}

.book_content > .book_content_item table.book_tbl span.tbl_txt {
  display: block;
  margin-right: 0;
  color: #a3a3a3;
  font-family: GOTHIC;
  font-size: 0.8rem;
}

.book_content > .book_content_item table.book_tbl span.tbl_txt_sb {
  color: #a3a3a3;
  margin-right: 0;
  font-weight: 100;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0px;
  width: 100%;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.button.wd10 {
  width: 10%;
}

.hgt10 {
  height: 22px;
  margin-left: 4px;
}

.button.wd15 {
  width: 15%;
}

.button.wd20 {
  width: 20% !important;
}

.index {
  width: auto !important;
  z-index: 10;
}

.button.wd40 {
  width: 80%;
}

.button.wd50 {
  width: 250px;
  height: 28px;
}

.button.bg-orange-out {
  border: 1px solid #ff7200;
  color: #ff7200;
}

.button.bg-success-out {
  position: relative;
  border: 1px solid #228b22;
  color: #228b22;
}

.button.bg-danger-out {
  position: relative;
  border: 1px solid #d60606;
  color: #d60606;
}

.button.bg-grey-out {
  position: relative;
  border: 1px solid #727376;
  color: #727376;
}

.button.bg-orange {
  position: relative;
  background-color: #ff7200;
  color: #fff;
  border: 1px solid #ff7200;
}

.button.bg-success {
  position: relative;
  background-color: #228b22;
  color: #fff;
  border: 1px solid #228b22;
}

.button.bg-danger {
  position: relative;
  background-color: #d60606;
  color: #fff;
  border: 1px solid #d60606;
}

.button.bg-grey {
  position: relative;
  background-color: #727376;
  color: #fff;
  border: 1px solid #727376;
}

.button.bg-orange-out:hover {
  color: #fff;
  background-color: #ff7200;
}

.button.wd20:hover {
  background-color: #727376;
  color: #fff;
  border: 1px solid #727376;
}

.button.wd15:hover {
  background-color: #ff7200;
  color: #fff;
  border: 1px solid #ff7200;
}

.button.bg-grey-out:hover {
  color: #fff;
  background-color: #727376;
}

.button.bg-success-out:hover {
  border-color: #fff;
  color: #fff;
  background-color: #228b22;
}

.button.bg-danger-out:hover {
  color: #fff;
  background-color: #d60606;
}

.button.bti > .btn_icon {
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.bti.bg-orange > .btn_icon,
.button.bti.bg-success > .btn_icon,
.button.bti.bg-danger > .btn_icon,
.button.bti.bg-grey > .btn_icon {
  border-right: 1px solid #fff;
}

.button.bti.bg-orange-out > .btn_icon {
  border-right: 1px solid #ff7200;
}

.button.bti.bg-success-out > .btn_icon {
  border-right: 1px solid #228b22;
}

.button.bti.bg-danger-out > .btn_icon {
  border-right: 1px solid #d60606;
}

.button.bti.bg-grey-out > .btn_icon {
  border-right: 1px solid #727376;
}

.button.bti.bg-grey-out:hover > .btn_icon,
.button.bti.bg-orange-out:hover > .btn_icon,
.button.bti.bg-success-out:hover > .btn_icon,
.button.bti.bg-danger-out:hover > .btn_icon {
  border-color: #fff;
}

.button.bti.bg-grey-out:hover > .button_icon {
  border-color: #fff;
}

p.txtr {
  margin-bottom: 0;
  font-size: 1rem;
}

p.txtr > span {
  color: red;
}

.button > .btn_txt {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GOTHIC;
}

.button.bti > .btn_txt {
  width: 70%;
}

.clr {
  font-size: 1.2rem;
  color: #ff7200;
  cursor: pointer;
}

/* #endregion Address Book UI*/

/* #region Custom Input*/
input.cust-input,
select.cust-input {
  width: 100%;
  border: 1px solid silver;
  padding: 4px 8px;
  font-size: 1rem;
  color: #888;
  border-radius: 4px;
}

input.cust-input:focus,
select.cust-input:focus {
  background-color: #fff;
  outline: 0;
  border-color: #ff7200;
  box-shadow: 0 0 0 0.2rem rgb(255 114 0 / 25%);
}

input.cust-input::placeholder {
  color: #727376;
  font-size: 0.7rem;
}

/* #endregion Custom Input*/

/* #region Goods Receipt Detail */
.form-container {
  background-color: #727376;
  padding: 5px;
  width: 100%;
}

.custom-collapse {
  height: 30px;
  overflow: hidden;
  position: relative;
}

.custom-collapse.autoheight {
  height: auto;
}

.collapse-view.open {
  overflow: unset !important;
}

.custom-collapse:not(.autoheight):not(.open) > .customHead {
  border-bottom: 1px solid #fff;
}

.custom-collapse:not(.autoheight):not(.open) > .customBdy {
  display: none;
}

.custom-collapse > .customHead {
  background: #727376;
  color: #fff;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 1.1rem;
  font-family: GOTHIC;
  padding: 0px 5px;
  justify-content: center;
  position: relative;
}

.custom-collapse > .customBdy {
  background-color: #fff;
  /* min-height: 1px; */
}

.custom-collapse > .customHead > .collapse_icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 30px;
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-collapse > .customHead > .collapse_icon:after {
  content: "\002B";
  color: white;
  font-weight: bold;
}

.custom-collapse.open > .customHead > .collapse_icon:after {
  content: "\2212";
}

.tbl_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
}

.tbl_div > .logo_sec_1 {
  width: 30%;
  height: inherit;
  text-align: center;
}

.tbl_div > .logo_sec_1 {
  width: 30%;
  height: inherit;
  text-align: center;
}

.tbl_div > .logo_sec_1 > img {
  max-width: 100%;
  max-height: 100%;
}

.tbl_div > .content_sec_1 {
  width: 70%;
  height: inherit;
  text-align: center;
}

.tbl_div > .content_sec_1 > p {
  margin-bottom: 0;
}

.brd_lft {
  border-left: 1px solid #727376;
}

.brd_rht {
  border-right: 1px solid #727376;
}

.brd_tp {
  border-top: 1px solid #727376;
}

.brd_btm {
  border-bottom: 1px solid #727376;
}

.brd_none {
  border: none;
}

.flex-me {
  display: flex;
  justify-content: space-between;
}

.wdt10 {
  width: 10%;
}

.wdt20 {
  width: 20%;
}

.wdt5 {
  width: 5%;
}

.wdt7 {
  width: 7%;
}

.wdt25 {
  width: 25%;
}

.wdt15 {
  width: 15%;
}

.wdt30 {
  width: 30%;
}

.wdt40 {
  width: 40%;
}

.wdt45 {
  width: 45%;
}

.wdt50 {
  width: 50%;
}

.wdt55 {
  width: 55%;
}

.wdt60 {
  width: 60%;
}

.wdt70 {
  width: 70%;
}

.wdt80 {
  width: 80%;
}

.wdt90 {
  width: 90%;
}

.wdt100 {
  width: 100%;
}

.dv_flex {
  display: flex;
  position: relative;
}

.pd5 > div {
  padding: 5px !important;
  position: relative;
}

.pdm5 {
  padding: 5px !important;
}

input.nfc::placeholder {
  color: #959595;
  font-size: 0.75rem;
}

input.nfc:focus,
input.nfc:focus-visible,
select.nfc:focus,
select.nfc:focus-visible {
  outline: none !important;
}

.add_btn {
  background: #ff7200;
  color: #fff;
  padding: 2px 5px;
  cursor: pointer;
}

.check_cust {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #ff7200;
  background: #fff;
  cursor: pointer;
}

.check_cust.active::before {
  background: #ff7200;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.check_cust.active::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 8px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.cust_dd {
  display: inline-flex;
  width: 100px;
  top: 5px;
  position: absolute;
  margin-left: 5px;
}

.cust_dd.cc {
  top: -3px;
}

.cust_dd.cc .check_cust {
  border-radius: 0;
}

.cust_dd.cc .check_cust.active::before {
  border-radius: 0;
}

.cust_dd > label {
  margin-bottom: 0;
  margin-top: 1px;
  margin-left: 5px;
}

.required {
  color: red;
}

.crsr-ptr {
  cursor: pointer !important;
}

.doc_attachment {
  position: absolute;
  background: #fff;
  width: auto;
  height: 20px;
  top: calc(50% - 10px);
  z-index: 999;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #727376;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0 5px;
}

.doc_attachment i {
  font-size: 1rem;
  font-weight: 600;
  color: #888;
}

/* #endregion Goods Receipt Detail */

/* #region Analytics CSS */
.chartContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  height: 400px;
}

.chartContainer.ppchrt {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.chartContainer.ppchrt > h2 {
  position: absolute;
  top: -5px;
  left: 5px;
  font-weight: 600;
  background: #ff7200;
  color: #fff !important;
  padding: 5px;
}

.chartContainer.ppchrt > div.indicatorDv {
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.chartContainer.ppchrt > div.indicatorDv > div {
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartContainer.ppchrt > div.indicatorDv > div > div {
  height: 30px;
  width: 30px;
  background-color: var(--bar-color);
}

.chartContainer.ppchrt > div.indicatorDv > div > label {
  width: calc(100% - 40px);
  margin-bottom: 0;
}

.chartContainer.ppchrt div.countdv {
  position: absolute;
  text-align: center;
  width: 100px;
  background: #727376;
  padding: 3px;
  color: #fff;
  border-radius: 5px;
  right: 30px;
  top: 30px;
}

.chartContainer > h1 {
  position: absolute;
  top: 0;
  left: 30px;
  font-size: 1.2rem;
}

.chartContainer > canvas {
  margin-top: 20px;
}

.chartCon {
  /* background: #fff; */
  height: 300px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Line Chart CSS */
.css-chart {
  border-bottom: 2px solid #e5e5e5;
  border-left: 2px solid #e5e5e5;
  display: inline-block;
  height: var(--widget-size);
  padding: 0;
  position: relative;
  width: var(--widget-size);
}

.css-chart > .ylabel,
.grid > .ylabel {
  position: absolute;
  transform: rotate(-90deg);
  bottom: 50%;
  width: 100%;
  left: -60%;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
}

.grid > .ylabel {
  left: -54%;
  transform: rotate(90deg);
}

.css-chart > .xlabel,
.grid > .xlabel {
  position: absolute;
  bottom: -18px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 10px;
}

.grid > .xlabel {
  top: -25px;
}

.css-chart::before {
  width: 0;
  height: 0;
  border-bottom: 8px solid #e5e5e5;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
}

.css-chart::after {
  width: 0;
  height: 0;
  border-left: 8px solid #e5e5e5;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  right: -7px;
  bottom: -6px;
}

.line-chart {
  list-style: none;
  margin: 0;
  padding: 0;
}

.data-point {
  background-color: #707070;
  border-radius: 50%;
  height: 12px;
  position: absolute;
  width: 12px;
  z-index: 1;
  cursor: pointer;
}

.data-point > .cConte {
  position: absolute;
  left: -30px;
  top: 20px;
  text-align: center;
  width: 80px;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  font-weight: 600;
  background-color: #c7c6c6;
  display: none;
}

.data-point:hover > .cConte {
  display: block;
}

.data-point > .cConte::after {
  width: 0;
  height: 0;
  border-bottom: 10px solid #c7c6c6;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  position: absolute;
  left: 30px;
  top: -8px;
}

.line-segment {
  background-color: #ff7200;
  height: 1px;
  position: absolute;
  transform-origin: left bottom;
}

.labelsegment > ul {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.labelsegment > ul > li {
  display: inline-block;
  list-style-type: none;
  width: 48px;
  text-align: left;
  position: relative;
}

.labelsegment > ul > li::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
  position: absolute;
  top: -5px;
  left: 12px;
}

.ulabelsegment > ul {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.ulabelsegment > ul > li {
  display: inline-block;
  list-style-type: none;
  width: 48px;
  text-align: left;
  position: relative;
}

.ulabelsegment > ul > li::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
  position: absolute;
  top: -5px;
  left: 12px;
}

.contdv {
  position: absolute;
  right: 32px;
}

.floating_line {
  position: absolute;
  height: 0;
  width: 100%;
  border-bottom: 1px solid #888;
}

.chart-wrap {
  height: 400px;
  width: 300px;
}

.chart-wrap .title {
  font-weight: bold;
  font-size: 1.62em;
  padding: 0.5em 0 1.8em 0;
  text-align: center;
  white-space: nowrap;
}

.chart-wrap.vertical .grid {
  transform: rotate(-90deg);
}

.chart-wrap.vertical .grid .bar::after {
  transform: translateY(-50%) rotate(90deg);
  display: block;
}

.chart-wrap.vertical .grid::before,
.chart-wrap.vertical .grid::after {
  transform: translateX(-0.2em) rotate(90deg);
}

.chart-wrap .grid {
  position: relative;
  padding: 5px 0 5px 0;
  height: 100%;
  width: 100%;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.chart-wrap .grid::before {
  width: 0;
  height: 0;
  border-bottom: 8px solid #e6e6e6;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  position: absolute;
  right: -8px;
  top: -5px;
}

.chart-wrap .grid::after {
  width: 0;
  height: 0;
  border-left: 8px solid #e5e5e5;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  left: -2px;
  bottom: -6px;
}

.chart-wrap .bar {
  /* width: var(--bar-width); */
  height: 10px;
  margin: 20px 0;
  background-color: var(--bar-color);
  border-radius: 0 3px 3px 0;
  position: relative;
}

.chart-wrap .bar:hover {
  opacity: 0.7;
}

.chart-wrap .bar::after {
  content: attr(data-name);
  margin-left: 100%;
  padding: 10px;
  display: inline-block;
  white-space: nowrap;
}

.chart-wrap .bar::before {
  content: attr(data-lbl);
  white-space: nowrap;
  position: absolute;
  left: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  top: 0;
}

.chart-wrap.vertical .grid .bar::before {
  transform: rotate(90deg);
}

.chart-wrap .bar.lbl::before {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  transform: rotate(90deg);
  left: -35px;
  width: 50px;
  font-weight: 600;
}

.chart-wrap .bar.lbl.fcc::before {
  font-size: 10px;
}

/* #endregion Analytics CSS*/

/* #region Donut chart CSS*/
.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.donut-chart .slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
}

.donut-chart .chart-center span {
  display: block;
  text-align: center;
}

.donut-chart.chart2 {
  width: 120px;
  height: 120px;
  background: #e1e1e1;
}

.donut-chart.chart2 .slice.one {
  clip: rect(0 120px 60px 0);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.donut-chart.chart2 .slice.two {
  clip: rect(0 60px 120px 0);
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.donut-chart.chart2 .chart-center {
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  background: #fff;
}

.donut-chart.chart2 .chart-center span {
  font-size: 30px;
  line-height: 100px;
  color: #48b2c1;
}

.donut-chart.chart2 .chart-center span:after {
  content: "91%";
}

.lbal {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.lbal > .lbl_btn {
  width: fit-content;
  padding: 2px 10px;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
}

.lbl1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbl1 > div.tt {
  width: 60px;
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.lbl1 > div.tt::before {
  content: " ";
  height: 16px;
  width: 16px;
  position: absolute;
  left: -20px;
}

.lbl1 > div.tt.cc1::before {
  background: #ffd300;
}

.lbl1 > div.tt.cc2::before {
  background: #ff7200;
}

.lbl1 > div.tt.cc3::before {
  background: #800080;
}

.lbl1 > div.tt.cc4::before {
  background: #228b22;
}

.dntc::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}

.chart-orange .donut-chart.chart2 .slice.one,
.chart-orange .donut-chart.chart2 .slice.two,
.chart-orange .dntc::after,
.chart-orange .lbal > .lbl_btn {
  background-color: #ff7200;
}

.chart-orange .donut-chart.chart2 .chart-center span {
  color: #ff7200;
}

.chart-grey .donut-chart.chart2 .slice.one,
.chart-grey .donut-chart.chart2 .slice.two,
.chart-grey .dntc::after,
.chart-grey .lbal > .lbl_btn {
  background-color: #727376;
}

.chart-grey .donut-chart.chart2 .chart-center span {
  color: #727376;
}

.chart-blue .donut-chart.chart2 .slice.one,
.chart-blue .donut-chart.chart2 .slice.two,
.chart-blue .dntc::after,
.chart-blue .lbal > .lbl_btn {
  background-color: #225079;
}

.chart-blue .donut-chart.chart2 .chart-center span {
  color: #225079;
}

.chart-lg .donut-chart.chart2 .slice.one,
.chart-lg .donut-chart.chart2 .slice.two,
.chart-lg .dntc::after,
.chart-lg .lbal > .lbl_btn {
  background-color: #b9d874;
}

.chart-lg .donut-chart.chart2 .chart-center span {
  color: #b9d874;
}

.chart-lb .donut-chart.chart2 .slice.one,
.chart-lb .donut-chart.chart2 .slice.two,
.chart-lb .dntc::after,
.chart-lb .lbal > .lbl_btn {
  background-color: #46c1c1;
}

.chart-lb .donut-chart.chart2 .chart-center span {
  color: #46c1c1;
}

/* #endregion Donut Chart CSS*/

/* #region Pie Chart*/
@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 22px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.animate-chart {
  animation: p 1s 0.5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
  }
}

.chartlb {
  position: relative;
  width: 100%;
}

.chartlb > .chartlb-item {
  position: relative;
  margin: 5px;
}

.chartlb > .chartlb-item > .chartlb-ccbox {
  --bg: #ff7200;
  background-color: var(--bg);
  height: 20px;
  width: 20px;
  position: relative;
}

.chartlb > .chartlb-item > .chartlb-txt {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  width: calc(100% - 20px);
  padding: 0 10px;
}

/* #endregion Pie Chart*/

/* #region Vehicle Detail*/
.vd_cont {
  display: flex;
  width: 100%;
  position: relative;
}

.post-15 {
  position: relative;
  top: 15px;
}

.overflowingText {
  position: absolute;
  top: -8px;
  left: -10px;
  color: #fff;
  font-size: 1rem;
  z-index: 1;
  font-weight: 700;
  padding: 3px 10px;
  background-color: #ff7200;
}

.alterSides {
  position: absolute;
  top: -7px;
  left: -7px;
  color: #fff;
  font-size: 1rem;
  z-index: 1;
  font-weight: 700;
  padding: 3px 10px;
  background-color: #727376;
}

.alterSidesInner {
  position: relative;
  top: 31px;
  left: 0px;
  color: #fff;
  font-size: 0.8rem;
  z-index: 1;
  font-weight: 700;
  padding: 3px 10px;
  background-color: #ff7200;
}

.vd_cont *:not(.fa) {
  font-family: GOTHIC;
}

.vd_cont > .vd_cont_1 {
  width: 30%;
}

.vd_cont > .vd_cont_tbl {
  width: 100%;
  padding: 10px;
  padding-top: 19px;
  position: relative;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec {
  padding: 10px;
  text-align: center;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_icon {
  padding: 5px 10px;
  font-size: 2rem;
  color: #ff7200;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_icon {
  padding: 5px 10px;
  font-size: 2rem;
  color: #ff7200;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_tt {
  font-size: 1rem;
  color: #959595;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_txt {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_txt.underline {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  color: #ff7200;
  font-weight: 700;
  border: 1px solid rgb(0 0 0 / 8%);
}

.vd_cont > .vd_cont_2,
.vd_cont > .vd_cont_3 {
  width: 35%;
  padding: 10px;
  border: 2px solid #e3e3e3;
  position: relative;
}

.vd_cont > .vd_cont_2,
.vd_cont > .vd_cont_tbl.brtd {
  border-right: none;
}

.vd_cont > .vd_cont_2::after,
.vd_cont > .vd_cont_tbl.brtd::after {
  content: "";
  position: absolute;
  right: -1px;
  width: 0;
  top: 10px;
  height: calc(100% - 20px);
  border: 1px dashed #e3e3e3;
}

.vd_cont > .vd_cont_3,
.vd_cont > .no_btr {
  border-left: none;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec {
  width: 100%;
  display: flex;
  padding: 5px;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec + .vd_cont_2_sec,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec + .vd_cont_tbl_sec {
  border-top: 2px solid #e3e3e3;
  margin-top: 5px;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec > .vd_cont_2_sec_1,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec > .vd_cont_tbl_sec_1 {
  font-size: 1rem;
  color: #959595;
  width: 50%;
}

.vd_cont_tbl_sec_1_new {
  font-size: 0.8rem;
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
  color: #959595;
  width: 40%;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec > .vd_cont_2_sec_2,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec > .vd_cont_tbl_sec_2 {
  font-size: 1rem;
  color: #ff7200;
  width: 60%;
  text-align: end;
}

.vd_cont_2_sec_2.underline {
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  color: #ff7200;
  font-weight: 700;
  width: 70% !important;
  border: 1px solid rgb(0 0 0 / 8%);
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec {
  position: relative;
  border: 1px solid #e3e3e3;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_header {
  background: #ff7200;
  color: #fff;
  padding: 8px;
  font-size: 1rem;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy {
  padding: 8px;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy > .btn_cont {
  width: 50%;
  margin: 10px 25% 10px 25%;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm {
  width: calc(25% - 10px);
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border: 1px solid #e3e3e3;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy > .file_preview {
  padding: 10px;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > p {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm
  > img {
  max-width: 100%;
  max-height: 100%;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm
  > .closeb {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #e3e3e3;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullscreen {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #888;
  position: absolute;
  z-index: 9999;
  bottom: 2px;
  right: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.fullscreen > i {
  font-weight: 600;
  color: #888;
}

/* #endregion Vehicle Detail*/
.form-control {
  padding: 5px !important;
  border-radius: 5px !important;
  font-size: 0.7rem !important;
  border: 1px solid #aaa;
}

.form-control::placeholder {
  font-size: 0.75rem;
}

.placeColor::placeholder {
  font-size: 0.75rem;
  color: #ff7200;
  font-weight: 700;
}

.set-w {
  width: 60%;
}

.data-cont > .data-cont-item > .data_item_content > div.btn_conte {
  width: 100%;
  background: #fff;
  margin: 2px 1px;
  text-align: center;
  height: auto;
}

.data-cont > .data-cont-item > .data_item_content > div i.copyIcon {
  cursor: pointer;
  font-weight: 600;
  color: #888;
}

.data-cont > .data-cont-item > .data_item_content > div > span {
  display: block;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(1) {
  font-size: 0.55rem;
  color: #888;
  font-weight: 600;
  margin-bottom: 5px;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(2) {
  font-size: 0.65rem;
  font-weight: 600;
  color: #000;
  margin-top: 0px;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(3) {
  font-size: 0.65rem;
  font-weight: 600;
  color: #000;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(3) {
  font-size: 0.65rem;
}

.data-cont > .data-cont-item > .data_item_content > div > span.flex_box_1 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .data-cont-item > .data_item_content > div > span.flex_box_2 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .data-cont-item > .data_item_content.clps {
  display: none;
  /* animation-duration: 0.3s;
    animation-name: slideDown; */
}

.data-cont > .data-cont-item.open > .data_item_content.clps {
  display: flex;
}

.data-cont > .data-cont-item > div.collps_icon {
  position: absolute;
  display: flex;
  height: 20px;
  width: 40px;
  background: #727376;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  right: 4px;
  bottom: 3px;
  cursor: pointer;
  border-top-left-radius: 2px;
}

.data-cont > .data-cont-item > div.collps_icon > i {
  font-size: 1.5rem;
  color: #fff;
  margin-top: -4px;
}

.data-cont > .data-cont-item.open > div.collps_icon > i {
  margin-top: 0;
  margin-bottom: -4px;
  opacity: 0;
  animation-duration: 0.3s;
  animation-name: rotateAnimation;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateAnimation {
  0% {
    opacity: 0;
    transform: rotate(0);
  }

  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.headerpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: #fff;
  z-index: 2000;
}

.headerpanel .logopanel {
  position: absolute;
  left: 0px;
  width: 180px;
  height: inherit;
  background-color: #fff;
  display: grid;
  place-content: center;
}

.headerpanel .logopanel > h2 {
  margin-top: 10px;
}

.headerpanel .logopanel > h2 img {
  height: 40px;
}

.headerbar {
  margin-left: 180px;
  position: relative;
  padding-left: 64px;
  left: 0 !important;
}

.menutoggle {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
  font-size: 21px;
  cursor: pointer;
  color: #778395;
  transition: all 0.2s;
}

.menutoggle i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -10px;
}

.header-right {
  float: right;
  padding-right: 20px;
  height: 64px;
}

.headermenu {
  list-style: none;
  margin: 0;
  padding: 0;
  height: inherit;
}

.headermenu > li {
  display: inline-block;
  position: relative;
  height: inherit;
}

.headermenu > li > .btn-group {
  height: 64px;
  display: grid;
  place-content: center;
  min-width: 120px;
}

.headermenu > li > .btn-group > .btn-logged {
  background: transparent;
  border: none;
  color: #727376;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headermenu > li > .btn-group > .btn-logged div.udeta {
  padding: 0 5px;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span {
  display: block;
  text-align: center;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n1 {
  font-size: 1rem;
  color: #ff7200;
  margin-bottom: -5px;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n2 {
  font-size: 0.8rem;
  color: #000;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n3 {
  font-size: 0.6rem;
  color: #727376;
}

.headermenu > li > .btn-group > .btn-logged:focus,
.headermenu > li > .btn-group > .btn-logged:active {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.headermenu > li > .btn-group > .btn-logged img {
  vertical-align: middle;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
}

.footer::before {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  background: #143a4d;
  top: -10px;
}

.footer > p::before {
  content: "";
  height: 10px;
}

.footer > p {
  color: #fff;
  margin-bottom: 0rem;
}

.footer {
  background: #061f2b;
  position: relative;
  margin-top: 10px;
  height: 40px;
  display: grid;
  place-content: center;
}

.caret {
  cursor: pointer;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  /* border-top: 4px dashed; */
  border-top: 10px solid #ff7200;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.main-content {
  /* height: 100vh; */
  font-family: GOTHIC;
  font-size: 12px;
  line-height: 1.42857143;
  color: #696c74;
  background-color: #dde0e4;
}

.sidebar {
  background-color: #fff;
  width: 180px;
  position: fixed;
  top: 64px;
  bottom: 0px;
  left: -180px;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s;
}

.sidebar.open {
  left: 0;
}

.nav-quirk > li.nav-parent > a:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 400;
  color: #bdc3d1;
  position: absolute;
  top: 10px;
  right: 15px;
}

.leftpanel-profile {
  padding: 15px;
  background-color: #3b4354;
  margin-top: 0;
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}

.leftpanel-profile .media-object {
  width: 48px;
  height: 48px;
  padding: 3px;
  border: 2px solid #657390;
}

.leftpanel-profile .media-left {
  padding-right: 15px;
}

.media-object {
  display: block;
}

.media-body {
  width: 9992px;
}

.img-circle {
  border-radius: 50%;
}

img {
  vertical-align: middle;
}

.leftpanel-profile .media-body {
  color: rgba(255, 255, 255, 0.5);
}

.leftpanel-profile .media-heading {
  margin: 7px 0 2px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
}

.leftpanel-profile .media-heading > .caret {
  margin-top: 8px;
}

.btn-group.open > .user-dropmenu,
.media.open > .user-dropmenu {
  display: block;
}

.user-dropmenu {
  z-index: 99;
  display: none;
  width: 100%;
  top: 50px;
  right: 15px;
  position: absolute;
  border-color: rgba(38, 43, 54, 0.1);
  border-top: 0;
  margin-top: 0;
  margin-right: -8px;
  padding: 0px;
  background-color: #eaeaea;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  transition: all 0.2s ease-out 0s;
}

.user-dropmenu::before {
  border-bottom: 8px solid #eaeaea;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  display: inline-block;
  right: 5px;
  position: absolute;
  top: -7px;
}

.user-dropmenu > ul {
  list-style: none;
  padding: 5px;
  margin: 4px;
  background-color: #fff;
}

.user-dropmenu > ul > li {
  font-size: 15px;
  font-weight: 600;
  color: #f57421;
  cursor: pointer;
  padding: 5px;
  transition: all 0.2s;
}

.user-dropmenu > ul > li:hover {
  background-color: #f57421;
  color: #fff;
}

.user-dropmenu > ul > li > i {
  margin-right: 5px;
}

.main-content > .content-body {
  padding-top: 64px;
  margin-left: 0px;
  transition: all 0.4s;
}

.main-content > .content-body > .contentpanel {
  margin: 8px 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background: #f6f6f6;
  padding: 10px;
  height: 90vh;
  transition: all 0.4s;
  overflow-x: auto;
}

.sidebar.open + .content-body {
  margin-left: 180px;
}

.tab-content {
  height: 100%;
  overflow-x: auto;
  background-color: #fff;
}

.tab-content > .tab-pane {
  background-color: transparent;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  display: block;
}

.sidebar-title {
  text-transform: uppercase;
  font-size: 11px;
  color: #9fa8bc;
  line-height: normal;
  letter-spacing: 0.5px;
  padding-left: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.menu-nav {
  margin: 0 0 10px;
  background-color: #fff;
  list-style: none;
  padding: 0;
  position: relative;
}

.menu-nav > li {
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  text-transform: uppercase;
  font-family: GOTHIC;
  border-top-color: transparent;
  position: relative;
}

.menu-nav > li.active {
  border-top-color: transparent;
  background-color: #ff7200;
}

.menu-nav > li + li {
  margin-top: 2px;
}

.menu-nav > li > a {
  color: #505b72;
  padding: 8px 5px;
  border-radius: 0;
  font-weight: 700;
  transition: all 0.2s ease-out 0s;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
}

.menu-nav > li.active > a > i {
  color: #fff;
}

.menu-nav > li > a > i {
  width: 16px;
  font-size: 14px;
  text-align: center;
  margin-right: 7px;
  color: #259dab;
  vertical-align: top;
  margin-top: 1.5555px;
  transition: all 0.2s ease-out 0s;
}

.menu-nav > li > a .badge {
  background-color: transparent;
  color: #9fa8bc;
  font-size: 12px;
  text-transform: none;
  padding: 0;
  font-weight: normal;
  margin-top: 4px;
  margin-right: 2px;
}

.menu-nav > li > a > img.sicon {
  height: 25px;
  width: 25%;
  padding: 0 5px;
}

.menu-nav > li > a > img.newSicon {
  height: 32px;
  width: 25%;
  padding: 0 5px;
}

.menu-nav > li > a > span.txt {
  font-weight: 600;
  font-size: 9.5px;
  display: block;
  width: 75%;
}

.menu-nav > li.active > a > span.txt {
  color: #fff;
}

.menu-nav > li.nav-parent > a:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 400;
  color: #bdc3d1;
  position: absolute;
  top: 10px;
  right: 15px;
}

.menu-nav > li.active > a:after {
  color: #fff;
}

.menu-nav .children {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid #eaecf0;
  background-color: #f6f7f8;
  transition: all 0.2s ease-out 0s;
}

.menu-nav > li.active .children {
  display: block;
  border-top: 0;
  border-bottom: 1px solid #eaecf0;
}

.no-brdr {
  border-bottom: 0.01rem solid silver !important;
}

.menu-nav > li.active .children:before {
  border-top: 7px solid #ff7200;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 0;
}

.menu-nav > li.active .no-triangle:before {
  border-top: 10px solid #ff7200;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;

  top: 0;
}

.menu-nav .children > li {
  margin-left: 21px;
  border-left: 1px solid #dbdfe6;
  font-family: GOTHIC;
  text-transform: none;
  letter-spacing: normal;
  display: block;
  font-size: 12px;
}

.menu-nav .children > li:first-child {
  padding-top: 7px;
}

.menu-nav .children > li > a {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 5px 0 5px 18px;
  color: #657390;
  position: relative;
  height: 28px;
  transition: all 0.2s ease-out 0s;
  font-size: 10px;
}

.menu-nav .children > li.active > a {
  color: #ff7200;
}

.menu-nav .children > li > a:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: -3px;
  top: 50%;
  background-color: #9fa8bc;
  margin-top: -3px;
  border-radius: 50px;
  transition: all 0.2s ease-out 0s;
}

.menu-nav .children > li.active > a:before {
  background-color: #ff7200;
}

.menu-nav .children > li > a:after {
  content: "";
  width: 30px;
  height: 30px;
  border: 1px solid #a0a8b9;
  position: absolute;
  left: -15px;
  top: 50%;
  margin-top: -15px;
  border-radius: 50px;
  opacity: 0;
  transition: all 0.2s ease-out 0s;
}

.menu-nav .children > li.active > a:after {
  opacity: 1;
  border-color: #ff7200;
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.menu-nav .children > li > a:hover:before {
  background-color: #ff7200;
}

.menu-nav .children > li > a:hover:after {
  opacity: 1;
  border-color: #ff7200;
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.menu-nav .children > li > a:hover {
  text-decoration: none;
  color: #ff7200;
}

.cust-breadcrumb {
  padding: 8px 26px;
  list-style: none;
  background-color: #fff;
  border-radius: 2px;
  margin: 5px 15px;
}

.cust-breadcrumb > li {
  font-size: 12px;
  display: inline-block;
}

.cust-breadcrumb > li > a {
  color: #545b68;
}

.cust-breadcrumb > li + li:before {
  content: "\f105";
  font-family: "FontAwesome";
  color: #818da7;
  margin-right: 5px;
  margin-left: 5px;
}

.cust-breadcrumb > li.active {
  color: #464f63;
  font-weight: 500;
}

.cust-breadcrumb > li.active > a {
  pointer-events: none;
}

.contentpanel h1.title {
  font-size: 1.2rem;
  margin: 0;
}

.contentpanel h1.title ~ hr,
hr.bgg {
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
}

.cust-container {
  width: inherit;
  position: relative;
  padding: 5px 10px;
}

.cust-row {
  display: flex;
  width: 100%;
  position: relative;
  /* flex-wrap: wrap; */
}

.cust-row > .cust-col-10 {
  width: 100%;
  position: relative;
  padding: 5px 10px;
}

.ttname {
  font-size: 0.8rem;
  color: #000;
  font-weight: 600;
  display: block;
  width: 100%;
  position: absolute;
  top: -28px;
  left: 0px;
  /* border: 4px solid #727376;
    border-bottom: none; */
  padding-left: 12px;
  padding-right: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ttname .transportrName {
  font-size: 1.1rem;
  color: #ff7200;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: underline;
  text-underline-position: under;
}

.transportrNew {
  font-size: 1.1rem;
  color: #ff7200;
  font-weight: 600;
}

.cust-row > .cust-col-5 {
  width: 50%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-2 {
  width: 20%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-4 {
  width: 40%;
  position: relative;
  padding: 5px 10px;
}

.cust_back_div {
  width: 40%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-4-new {
  width: 35%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-9 {
  width: 90%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-8 {
  width: 80%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-7 {
  width: 70%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-6 {
  width: 60%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-3 {
  width: 30%;
  position: relative;
  padding: 5px 10px;
  display: flex;
}

.cust-col-3-big {
  width: 40% !important;
  position: relative;
  padding: 0px 0px;
  display: flex;
}

.custGross {
  width: 37%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custGrossSymbol {
  width: 8%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cust-row > .cust-col-1 {
  width: 10%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-1_2 {
  width: 15%;
  position: relative;
  padding: 5px 10px;
}

.filed-cont {
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
}

.filed-cont > .icon-cont {
  width: 20%;
  height: 100%;
  top: 0;
  bottom: 0;
  display: grid;
  justify-content: center;
  place-items: center;
}

.filed-cont > .icon-cont > i {
  font-size: 3rem;
}

.filed-cont > .data-cont {
  width: 80%;
  height: 100%;
  display: grid;
  align-items: center;
  padding-left: 10px;
}

.filed-cont.doc-cont > .data-cont {
  width: 80%;
  display: block;
  margin-left: 10px;
}

.filed-cont.doc-cont > .icon-cont {
  width: 10%;
  display: block;
}

.filed-cont > .data-cont > label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #736e6e;
  margin-bottom: 0rem;
}

.filed-cont > .data-cont > input,
.table-row > h3 > input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #736e6e;
  border-radius: 5px;
  padding: 2px 5px;
  color: #736e6e;
}

.table-row > h3 > input {
  width: 95%;
  height: 30px;
}

.filed-cont > .data-cont > input:focus,
.table-row > h3 > input:focus {
  outline: none;
  border-color: #f57421;
  color: #f57421;
}

.filed-cont > .data-cont > div,
.table-row > h3 > div {
  font-size: 15px;
  color: #696c74;
}

.filed-cont > .data-cont > div > span,
.table-row > h3 > div > span {
  color: #fff;
  background: #8c8e92;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.fa.fa_new {
  height: 45px;
  width: 45px;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #696c74;
  color: #fff;
  border-radius: 50%;
  font-size: 2rem !important;
  font-family: FontAwesome;
}

.fa-char-i::before {
  content: "\0049";
}

.fa-char-g::before {
  content: "\0047";
}

.fa-char-c::before {
  content: "\0043";
}

.fa-char-p::before {
  content: "\0050";
}

.profile-title {
  font-size: 1rem;
  margin: 0;
  padding-left: 15px;
  text-transform: uppercase;
  color: #7d7d7c;
}

.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-holder > img {
  border: 2px solid #eaeaea;
  padding: 10px;
  height: 300px;
  width: 300px;
}

.image-holder > label {
  position: absolute;
}

label.sr-file {
  color: #fff;
  background: #8c8e92;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.image-holder > label.sr-file {
  position: absolute;
  height: 40px;
  width: 40px;
  bottom: 35px;
  right: 68px;
}

.image-holder > label.sr-file > i {
  font-size: 1.2rem;
}

.custmr-5 {
  margin-bottom: 15px;
}

i.verified {
  color: #249c0e;
}

label.sr-file > input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.save_btn {
  float: right;
  background: #f57421;
  color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  margin-top: -5px;
  border-radius: 5px;
  cursor: pointer;
}

.cust-card {
  border: none;
  background: #fff;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0px 0px 5px #f57421;
  width: 100%;
  height: 250px;
}

.cust-card > .cust-card-body {
  text-align: center;
}

.cust-card > .cust-card-body > img {
  height: 100px;
  margin-top: 12%;
  user-select: none;
}

.cust-card > .cust-card-body > h3 {
  margin: 1rem 0;
  user-select: none;
  font-size: 1.5rem;
}

.cust-card > .cust-card-body > button {
  background: #f57421;
  border: #f57421;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.cust-card > .cust-card-body > button:focus,
.cust-card > .cust-card-body > button:hover {
  outline: none;
  box-shadow: 2px 2px 10px #ff7200;
}

.table-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #888;
  height: 42px;
}

.table-row > h4,
.table-row > h3 {
  font-size: 15px;
  width: 50%;
  position: relative;
  margin-bottom: 0;
}

.table-row > h4 {
  text-align: left;
}

.table-row > h3 > .danger {
  color: #ea6060;
}

.table-row > h3 > .verified {
  color: #47883b;
}

.table-row > h4::after {
  content: ":";
  position: absolute;
  right: 0;
}

.table-row > h3 > label.sr-file {
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.pd10 {
  padding: 10px;
}

.ctct {
  margin-top: 20px;
  float: left;
}

.view_btn {
  background: #259cab;
  border: #259cab;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.view_btn:focus {
  outline: none;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8;
  box-shadow: none !important;
}

.share-div {
  width: 100% !important;
}

.share-div > label {
  text-align: left;
  width: 95%;
  font-size: 15px;
  font-family: GOTHIC;
}

.custom-modal-body > .row-div > div > div.custom-group {
  display: flex;
  justify-content: center;
}

.custom-modal-body > .row-div > div > div.custom-group > .control-input {
  height: 35px;
  width: 87%;
  border-radius: 5px;
  border: 1px solid #f6ac49;
  padding: 0 5px;
  outline: none;
  caret-color: #f6ac49;
  color: #f6ac49;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-modal-body > .row-div > div > div.custom-group > .append-icon {
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8a13f;
  color: #fff;
  cursor: pointer;
}

.doc_cont {
  text-align: initial;
  width: 95%;
  margin-left: 2.5%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 10px;
}

.doc_cont > div {
  width: 100px;
  background: #f57421;
  color: #fff;
  text-align: center;
  padding: 4px 0px;
  margin: 0px 10px 5px 10px;
}

.doc_cont > div > i {
  cursor: pointer;
  margin: 0 10px;
}

.msgPara {
  font-family: GOTHIC;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

table > tbody > tr > * {
  font-family: GOTHIC;
  font-size: 14px;
}

table > tbody > tr span {
  margin-right: 10px;
}

.btn-sm {
  padding: 0.1rem 0.5rem !important;
}

.sMsg {
  margin-left: 10px;
  font-size: 11px;
  color: #249c0e;
}

#vaccination_document {
  color: grey;
  margin-left: 8px;
  cursor: pointer;
}

.dynamicDropdown {
  width: 17%;
  height: 34px;
}

.dynamicDropdown select {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #495057;
  padding-left: 4px;
  border: 1px solid rgb(206, 205, 205);
}

.dynamicDropdown select:focus {
  outline: none;
}

.dynamicDropdown select > option {
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.size {
  font-size: 0.8rem !important;
  height: 40px;
}

.inc_wd_30 {
  width: 26% !important;
}

/****Pod confirmation css- PRIYANSHU****/
.podM {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.podouter1 {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.podH-1 {
  width: 100%;
  height: 117px;
}

.podr-1 {
  width: 100%;
  height: 33px;
  background-color: #6c757d;
  border-color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr-1 span {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
}

.podr-2 {
  width: 100%;
  height: 33px;
  background-color: #fff;
  display: flex;
}

.podr2-c1 {
  width: 35%;
  height: 100%;
  border: 1px solid #736e6e;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr2-c2 {
  width: 15%;
  height: 100%;
  border: 1px solid #736e6e;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr2-c3 {
  width: 50%;
  height: 100%;
  border: 1px solid #736e6e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podT-h1 {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.podr-3 {
  width: 100%;
  height: 51px;
  background-color: white;
  display: flex;
}

.podr3-c1 {
  width: 35%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr3-c1 span,
.podr3-c2 span,
.podr3-c3 span {
  font-size: 0.9rem;
}

.podr3-c2 {
  width: 15%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr3-c3 {
  width: 50%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podr3-mid {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}

.togglebtnclass {
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.redbtnclass {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

#fa-math-icon {
  color: #fff;
  position: relative;
  right: 0.7px;
  top: 0.4px;
}

/*  */
.podH-2 {
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: column;
}

.podH-2-div1 {
  width: 100%;
  height: 33px;
  background-color: #6c757d;
  border-color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podH-2-div1 span {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
}

.podH-2-div2 {
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.podH-2-R-1 {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
}

.podH-2-R-1 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.podH-2-R-2 {
  width: 15%;
  height: 100%;
}

.podH-2-R-2 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.podH-2-v1 {
  width: 100%;
  height: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
}

.podH-2-v2 {
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
  border-top: none;
}

.podH-2-R-3 {
  width: 50%;
  height: 100%;
}

.podR-3-v1 {
  width: 100%;
  height: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
}

.podR-3-v2 {
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-top: none;
}

.podH-2-R-3 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

/*  */
.podDiv-2 {
  width: 100%;
  height: 112px;
  border: 1px solid #736e6e;
  position: relative;
  top: 25px;
}

.toggle-podDiv-2 {
  width: 100%;
  height: 60px;
  border: 1px solid #736e6e;
  position: relative;
  top: 25px;
}

.podD-2-R-1 {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  top: 10px;
  justify-content: space-between;
}

.podD-2-I-1 {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.podD-2-I-1 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.podD-2-I-2 {
  width: 32%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 30px;
}

.choiceyes {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.choiceyes label {
  color: black;
  font-size: 0.9rem;
  position: relative;
  top: 6px;
}

#yesCheckbox {
  width: 30%;
  height: 50%;
  background-color: #f57421;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  /* top: 5px; */
}

input[type="checkbox"]:after {
  width: 20px;
  height: 20px;
  content: " ";
  display: inline-block;
  visibility: visible;
  background-color: #fff;
  border: 1px solid #ff7200;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  font-size: 1wpx;
  color: white;
  background-color: #f57421;
}

.choiceno {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.choiceno label {
  color: black;
  font-size: 0.9rem;
  position: relative;
  top: 6px;
}

.podD-2-R-2 {
  width: 90%;
  height: 40px;
  position: relative;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: space-between;
  /* background-color: aquamarine; */
}

.podD-2-R-2-select {
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 12px;
  width: 35%;
  height: 30px;
}

.other_input {
  width: 30%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  position: relative;
  right: 0px;
}

.podD-2-R-2 ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}

.podD-2-R-2 select:focus {
  outline: none !important;
}

/*  */
.podDiv-3 {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 55px;
  border: 1px solid #736e6e;
}

.name-div {
  width: 93%;
  height: 24px;
  display: flex;
  position: relative;
  top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.name-div1 {
  width: 60%;
  height: 24px;
}

.name-div1 span {
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  color: black;
  justify-content: flex-start;
}

.desig-div1 {
  width: 50%;
  height: 24px;
}

.desig-div1 span {
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  color: black;
  justify-content: flex-start;
}

.input-div {
  width: 93%;
  height: 33px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 12px;
}

.name-input {
  width: 60%;
  height: 100%;
}

.name-input input {
  width: 65%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  border: 1px solid silver;
}

.name-input ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}

.desig-input {
  width: 50%;
  height: 100%;
}

.desig-input input {
  width: 80%;
  height: 92%;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  border: 1px solid silver;
}

.desig-input ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}

/*  */
.podDiv-4outer {
  width: 100%;
  height: 420px;
  position: relative;
  top: 92px;
  border: 1px solid #736e6e;
  margin-bottom: 120px;
}

.podDiv-4 {
  width: 94%;
  height: 40px;
  display: flex;
  position: relative;
  top: 25px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}

.podsigndiv {
  width: 20%;
  height: 100%;
}

.podsigndiv span {
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  color: black;
  font-weight: 700;
}

.pod2btns {
  width: 38%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#selectImage {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  padding-top: 7.5px;
  width: 47%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ff7200;
}

.selectimg {
  width: 45%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ff7200;
}

.claerbtn {
  width: 45%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: red;
}

.signaturediv {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  position: relative;
  top: 45px;
}

.signcanvas {
  width: 70%;
  height: 210px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 2px dashed #888;
  z-index: 2;
}

.sigCanvas {
  width: 400px;
  height: 180px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 2px dashed black;
}

.signatureimage {
  display: none;
}

.togglesignatureimage {
  width: 100%;
  height: 190px;
  position: relative;
  bottom: 190px;
  z-index: 3;
}

.togglesignatureimage img {
  width: 60%;
  height: 100%;
}

#signcanvasbtn {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  position: absolute;
  right: -10px;
  top: -20px;
  z-index: 2;
}

#fa-removeImg {
  position: relative;
  left: -2px;
  color: #fff;
}

#hide-btn {
  display: none;
}

.pod-btn_cont {
  position: absolute;
  bottom: 30px;
  width: 30%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* *****Tagged Image UI CSS***** */
.custom-modal .Ti-modal-body {
  background: #fff;
  width: calc(100% - 10px);
  height: 550px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  overflow-y: scroll !important;
}

.Ti-modal-body .cust-row {
  display: flex;
  width: 100%;
  position: relative;
  /* flex-wrap: wrap; */
}

.TI-outer {
  width: 100%;
  height: 520px;
}

.map-TI-outer {
  width: 100%;
  height: 520px;
}

.TI-inner1 {
  width: 100%;
  height: 40px;
}

.TI-inner1 span {
  color: green;
  font-size: 0.83rem;
  font-weight: 600;
}

.TI-inner1 .fa-check {
  border-radius: 50%;
  position: relative;
  right: 15px;
}

.TI-inner2 {
  width: 100%;
  height: 440px;
  display: flex;
}

.left-arrow-div {
  width: 10%;
  height: 100%;
}

.left-arrow-div #leftArrow {
  width: 35px;
  height: 35px;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.taggedimage-div {
  width: 80%;
  height: 100%;
}

.taggedimage-div img {
  width: 100%;
  height: 100%;
}

.right-arrow-div {
  width: 10%;
  height: 100%;
}

.right-arrow-div #rightArrow {
  width: 35px;
  height: 35px;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.TI-inner3 {
  width: 100%;
  height: 20px;
  position: relative;
  top: -15px;
}

.TImap-inner3 {
  width: 100%;
  height: 20px;
  position: relative;
  top: 15px;
}

.bulletDiv {
  width: 15%;
  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* background-color: yellow; */
  justify-content: space-around;
}

/* .pagination{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2.2px solid #888;
    cursor: pointer;
}
.activepagination{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2.2px solid #888;
    background-color: #888;
    cursor: pointer;
} */
/*  */
.TI-inner4 {
  width: 100%;
  height: 35px;
  position: relative;
  top: -36px;
  opacity: 0.7;
}

.hide {
  display: none;
}

.latLngdiv {
  width: 80%;
  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.latLng {
  width: 33%;
  height: 100%;
  background-color: rgb(126, 125, 125);
}

.latLng,
.details span {
  color: white;
}

.map {
  width: 34%;
  height: 100%;
}

.map button {
  width: 100%;
  height: 100%;
  background-color: #ff7200;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
}

.details {
  width: 33%;
  height: 100%;
  background-color: rgb(126, 125, 125);
}

.mapOuter {
  width: 100%;
  height: 100vh;
  cursor: pointer !important;
}

.addLocation {
  position: absolute;
  width: 14%;
  height: 32px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 4px;
  border: none;
  top: 55px;
  left: 10px;
  cursor: pointer;
}

#mapDimensions {
  width: 100%;
  height: 100%;
}

/* *****view document UI***** */
.ViewDoc-modal-body {
  background: #fff;
  width: 100%;
  height: 250px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  overflow-y: auto;
}

.ToggleViewDoc-modal-body {
  background: #fff;
  height: 460px;
  /* padding: 20px 10px 10px 10px; */
  text-align: center;
  overflow-y: scroll !important;
}

.uploadbtndiv {
  width: 75%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1.5px dashed black;
  border: 2px dashed #727376;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s;
}

.uploadbtndiv button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
}

.uploadbtndiv button:hover {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
}

#docUploadicon {
  width: 80px;
  position: relative;
  top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.uploadbtndiv span {
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  color: #727376;
  position: relative;
  top: 18px;
}

.viewdoc-uploadbtn {
  width: 58%;
  height: 94%;
  position: absolute;
  opacity: 0;
}

.size-reduce {
  width: 80%;
  font-size: 0.8rem;
}

/*  */
.toggleuploadDiv {
  width: 100%;
  height: auto;
  position: relative;
  top: 50px;
  /* background-color:red; */
}

.uploadedDocDiv {
  width: 100%;
  height: auto;
  position: relative;
  top: 40px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 0px;
}

.uploadedDocRow {
  width: 90%;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border: 2px solid #888;
  border-bottom: none;
  position: relative;
}

.uploadedDocRow:nth-last-child(1) {
  border-bottom: 2.2px solid #888;
}

.sno {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid silver;
}

.sno span {
  color: #727376;
  font-size: 0.8rem;
  font-weight: 600;
}

.uploadedDoc-h1 {
  width: 60%;
  height: 100%;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  border: 1px solid #888;
  border-left: none;
  border-top: none;
  border-bottom: none;
}

.uploadedDoc-h1 span {
  font-size: 1rem;
  color: black;
  font-weight: 600;
  position: relative;
}

.uploadedDoc-h2 {
  width: 40%;
  height: 100%;
}

.uploadedDocIcons {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#viewdoc-view {
  font-size: 18px;
  cursor: pointer;
  color: green;
}

#viewdoc-download {
  font-size: 18px;
  cursor: pointer;
  color: red;
}

.upload-doc-button {
  width: 100%;
  height: 40px;
  margin-top: 70px;
}

.upload-doc-button .btn_cont {
  display: flex;
  justify-content: center;
}

.upload-doc-button .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  width: 33%;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/*  */

.docPreview,
.docOverlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block !important;
  z-index: 1000;
}

.docOverlay {
  background-color: rgba(49, 49, 49, 0.8);
}

.doc-close {
  width: 40px;
  height: 40px;
  background-color: #fff;
  position: absolute;
  top: -30px;
  border-radius: 50%;
  right: -20px;
  cursor: pointer;
}

.doc-content {
  width: 44%;
  height: 510px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.1;
  background-color: #ff7200;
  border-radius: 10px;
  text-align: center;
  display: block;
  padding: 16px 10px 10px 10px;
  z-index: 1111;
}

.viewDoc-heading {
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  position: relative;
  top: -5px;
  font-family: GOTHIC;
}

.doc-inner1 {
  width: 100%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 20px 5px 5px 5px;
}

.doc-img-div {
  width: 90%;
  height: 350px;
  margin-right: auto;
  margin-left: auto;
}

.doc-img-div img {
  width: 100%;
  height: 100%;
  border: 0.4px solid silver;
}

.pdfimg {
  transform: rotateX(180deg);
}

.imgview {
  transform: rotateX(0deg);
  background-color: #000;
}

.doc-download-div {
  width: 90%;
  height: 30px;
  position: relative;
  top: 45px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.doc-download-div a {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #ff7200;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

#docView-cross {
  color: #ff7200;
  font-size: 26px;
  position: relative;
  top: 50%;
  font-weight: 300;
  transform: translateY(-50%);
}

/* *****ADD ADDRESS CSS***** */
.addAddressBox {
  background-color: #ff7200;
  padding: 2px;
  width: 97%;
  height: 410px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 42%;
  transform: translateY(-50%);
}

.addaddressinner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  position: relative;
}

.addaddressform {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: space-around;
}

.addaddressbottom {
  width: 90%;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  display: flex;
}

.addaddressbottom button {
  width: 20%;
  height: 35px;
  border: none;
  color: #fff;
  font-size: 1rem;
  background-color: #ff7200;
  border-radius: 4px;
  cursor: pointer;
}

.asterisk {
  color: red;
  padding-left: 4px;
}

.addadresstop {
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.addressL {
  width: 50%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #ff7200;
}

.nobrdr {
  border: none;
}

.addressInpFields {
  width: 80%;
  height: 39px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.addressSpans {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.addressSpans {
  font-size: 0.8rem;
  font-weight: 600;
  color: #000;
}

.addressDataField {
  width: 70%;
  height: 100%;
  display: flex;
}

.addInpElem {
  width: 90%;
  height: 100%;
}

.addInpElemExtra {
  width: 100%;
  height: 100%;
}

.inputElemAdd {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid silver;
  padding-left: 5px;
  font-size: 0.85rem;
}

.inputElemAdd::placeholder {
  padding-left: 5px;
  font-size: 0.72rem;
  color: #727376;
}

.addUploadItem {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid silver;
  border-left: none;
  border-radius: 4px;
  position: relative;
  justify-content: center;
}

.addUploadItemHub {
  width: 10%;
  height: 80%;
  display: flex;
  align-items: center;
  border-left: none;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 0px;
  justify-content: center;
}

.hubUploadIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
  align-items: center;
  border-left: none;
  border-radius: 4px;
  justify-content: center;
}
.hubUploadIconEye {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  border-left: none;
  border-radius: 4px;
  justify-content: center;
}

.flexNone {
  justify-content: unset !important;
}

.removeBrdr {
  border: none;
}

.uploadIconn {
  font-size: 1rem;
  color: #000;
  cursor: pointer;
}

.addUploadfile {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.myVisibleEye {
  position: absolute;
  right: -25px;
  color: #ff7200;
  font-size: 0.9rem;
  cursor: pointer;
}

.myVisibleEyeNew {
  position: absolute;
  right: -15px;
  color: #ff7200;
  font-size: 0.9rem;
  cursor: pointer;
}

.myVisibleEyeBlack {
  position: relative;
  right: -2px;
  color: #000;
  font-size: 0.9rem;
  cursor: pointer;
}

.panGstDoc {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
}

/* *********Invoice css********* */

.InvoiceOuter {
  width: 100%;
  height: auto;
  padding: 0px;
  margin-bottom: 10px;
}

.InvoiceInner {
  width: 100%;
  height: auto;
  background-color: #fff;
}

.InvoiceHeader {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
}

.invoiceheader1 {
  width: 35%;
  height: 100%;
  display: flex;
}

.invoicePI {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.invoicePI span {
  font-size: 0.9rem;
  color: #727376;
  font-weight: 600;
  padding-left: 5px;
}

.invoicecheckbox {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoiceYes {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.invoiceL {
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}

.invoiceNo {
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}

.invoiceR {
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}

.invoiceLabel {
  color: #727376;
  font-size: 0.9rem;
}

.invoiceinput {
  position: relative;
  top: 0px !important;
}

.invoiceheader2 {
  width: 47%;
  height: 100%;
  display: flex;
  align-items: center;
}

.invoiceNumberDiv {
  width: 70%;
  height: 65%;
  margin-left: auto;
  margin-right: auto;
}

.invoiceNumberDiv input {
  width: 65%;
  height: 37px;
  padding-left: 5px;
  border-radius: 4px;
  border: 1px solid silver;
}

.invoiceNumberDiv input::placeholder {
  color: #888;
  font-size: 0.7rem;
  padding-left: 5px;
}

.invoiceheader3 {
  width: 5%;
  height: 100%;
}

.incoiveDropdownDiv {
  width: 100%;
  height: 85px;
  display: flex;
  padding-top: 6px;
  justify-content: space-between;
}

.invoicedrop-1 {
  width: 40%;
  height: 100%;
}

.invoicedropI-1 {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  padding-left: 7px;
}

.invoicedropI-1 span {
  font-size: 0.9rem;
  font-weight: 600;
}

.invoicedropI-2 {
  width: 100%;
  height: 60%;
}

.invoiceBillInput {
  width: 90%;
  height: 35px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid silver;
}

.invoiceBillInput::placeholder {
  color: #888;
  font-size: 0.78rem;
  padding-left: 5px;
}

.invoicedrop-2 {
  width: 40%;
  height: 100%;
}

.invoiceForm {
  width: 100%;
  height: 650px;
  position: relative;
  background-color: silver;
  position: relative;
  top: 25px;
  padding: 1px;
  border-radius: 4px;
}

.invoiceforminner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.invoiceform1 {
  width: 100%;
  height: 45%;
}

.invoiceformR-1 {
  width: 100%;
  height: 30%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.InvoiceC-1 {
  width: 5%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceC-inner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.invoicespan1 {
  font-size: 0.75rem;
  font-weight: 600;
  color: black;
  padding-left: 5px;
  padding-bottom: 6px;
}

.invoicespan2 {
  font-size: 0.75rem;
  color: #727376;
  padding-left: 5px;
  font-weight: 600;
}

.InvoiceC-2 {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.InvoiceC-3 {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.InvoiceC-4 {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.InvoiceC-5 {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid silver;
}

.invoiceformR-2 {
  width: 100%;
  height: 65%;
  display: flex;
  background-color: #fff;
  flex-wrap: wrap;
}

.invoiceR-2-c-1 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
  flex-wrap: wrap;
}

.invoiceR-2-c-1Inner {
  width: 90%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.invoiceR-2-c-2 {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-3 {
  width: 18%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-4 {
  width: 7%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-5 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-6 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-7 {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceformInner1 {
  width: 100%;
  height: 26%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
}

.invoice-7Parent {
  width: 100%;
  height: 90%;
  display: flex;
}

.invoiceformInner2 {
  width: 70%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invoiceformInner3 {
  width: 30%;
  height: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invoicebtndiv {
  width: 100%;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.IE1-Modified {
  font-size: 12px;
  font-weight: 600;
  color: black;
  padding-top: 7px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.invoicespan1-new {
  font-size: 0.75rem;
  font-weight: 600;
  color: black;
  padding-left: 5px;
  padding-bottom: 10px;
}

.invoicespan2-new {
  font-size: 0.75rem;
  color: #727374;
  padding-left: 5px;
  font-weight: 600;
}

.invoiceaddbtn {
  width: 60%;
  height: 24px;
  border: 1px solid #ff7200;
  background-color: #fff;
  color: #ff7200;
  border-radius: 5px;
  cursor: pointer;
}

.invoiceaddbtn:hover {
  width: 60%;
  height: 24px;
  border: 1px solid #ff7200;
  background-color: #ff7200;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.invoiceR-2-c-8 {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-9 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoiceR-2-c-10 {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.totalChargediv1 {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalChargediv1 span {
  color: black;
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.totalChargediv2 {
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.totalchargesinner1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid silver;
  border-top: 1px solid silver;
}

.totalchargesinner2 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid silver;
}

.tableHead {
  color: black;
  font-size: 12px;
  font-weight: 600;
  color: black;
  padding-bottom: 10px;
}

.tablerow {
  color: black;
  font-size: 12px;
  font-weight: 600;
  color: #727376;
}

.invoiceR-2-c-11 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid silver;
}

#invoiceTrash {
  font-size: 22px;
  padding-top: 22px;
  cursor: pointer;
  color: red;
}

#invoiceTrash:hover {
  font-size: 24px;
  padding-top: 22px;
  cursor: pointer;
}

.invoicepanel {
  width: 100%;
  height: 220px;
  position: relative;
  top: 10px;
  display: flex;
}

.invoicpalnel-L {
  width: 60%;
  height: 100%;
}

.invoicpalnel-R {
  width: 40%;
  height: 85%;
  border: 1px solid silver;
  border-bottom: none;
  border-right: none;
}

.invoice-r1 {
  width: 100%;
  height: 25%;
  display: flex;
}

.invoice-r1-L {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoice-r1-R {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid silver;
}

.invoicepanelSpan1 {
  color: #727376;
  font-size: 0.8rem;
  font-weight: 700;
}

.invoicepanelSpan2 {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}

.invoice-r2 {
  width: 100%;
  height: 25%;
  display: flex;
}

.invoice-r3 {
  width: 100%;
  height: 28%;
  display: flex;
  justify-content: space-around;
}

.invoicegstparent {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}

.invoice-gst-1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.invoice-gst-1 span {
  color: #727376;
  font-size: 0.8rem;
  font-weight: 600;
}

.invoice-gst-1 select {
  width: 40%;
  height: 70%;
  border-radius: 5px;
  border: 1px solid silver;
  box-shadow: 0px 0px 2px #888 inset;
  font-size: 1rem;
  color: #888;
  padding-left: 5px;
}

.invoice-gst-2 {
  width: 50%;
  height: 100%;
  display: flex;
  border-left: 1px solid silver;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.invoice-gst-2 span {
  color: #727376;
  font-size: 12px;
  font-weight: 600;
}

.invoice-r4 {
  width: 100%;
  height: 23%;
  display: flex;
  justify-content: space-around;
}

.invoice-r5 {
  width: 100%;
  height: 25%;
  display: flex;
}

.invoicepanelTotal {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
  background-color: #ff7200;
}

.invoicepanelTotal span {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
}

.invoicetotalno {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid silver;
  background-color: #ff7200;
}

.invoicetotalno span {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
}

.Pibtndiv {
  width: 100%;
  height: 60px;
  position: relative;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pibtn {
  width: 20%;
  height: 32px;
  background-color: #fff;
  border: 1px solid #ff7200;
  color: #ff7200;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.pibtn:hover {
  width: 20%;
  height: 32px;
  background-color: #ff7200;
  border: 1px solid #ff7200;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/* select invoice css */

.data-cont > .newColor {
  position: relative;
  width: 100%;
  background: #ff7200;
  padding: 2px;
  border-radius: 5px;
  margin: 15px 0;
}

.data-cont > .newColor > .new_sno_txt {
  position: absolute;
  background: #ff7200;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  top: -10px;
  left: -10px;
  border: 2px solid #ff7200;
  z-index: 1;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}

.data-cont > .newColor > .input_div {
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  top: -10px;
  right: -10px;
  border: 2px solid #727376;
  z-index: 1;
  font-size: 10px;
  font-weight: 600;
}

.data-cont > .newColor > .input_div > .input_checkbox {
  position: absolute;
  cursor: pointer;
  width: 1px;
  height: 20px;
}

.data-cont > .newColor > .input_div > .input_checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -7px;
  right: -19px;
  border: 4px solid #ff7200;
  border-radius: 20px;
  background-color: #ff7200;
  transition: all 0.2s linear;
}

.data-cont > .newColor > .input_div > .input_checkbox:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -7px;
  right: -18px;
  border-radius: 20px;
  border: 4px solid #727376;
  background-color: #fff;
}

.data-cont > .newColor > .input_div > .input_checkbox:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: solid #ffcb9a;
  border-width: 0 0px 0px 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 30px;
  transition: all 0.2s linear;
}

.data-cont > .newColor > .input_div > .input_checkbox:checked:after {
  content: "";
  display: block;
  width: 7px;
  height: 14px;
  border: solid #fff;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 2px;
}

.data-cont > .newColor > .data_item_content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding: 1px;
}

.data-cont > .newColor > .data_item_content > div {
  width: calc(20% - 2px);
  background: #fff;
  padding: 5px;
  margin: 0 1px;
}

.data-cont > .newColor > .data_item_content > .data_item_contnet_1 {
  width: 16.5%;
  background: #fff;
  padding: 5px;
  margin: 0 1px;
}

.data-cont > .newColor > .data_item_content.set_height > div {
  height: 60px;
}

.data-cont > .newColor > .data_item_content > div.wd_10 {
  width: calc(10% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_15 {
  width: calc(15% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_20 {
  width: calc(20% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_25 {
  width: calc(25% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_30 {
  width: calc(30% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_40 {
  width: calc(40% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_75 {
  width: calc(75% - 2px);
}

.data-cont > .newColor > .data_item_content > div.wd_100 {
  width: calc(100% - 2px);
}

.data-cont > .newColor > .data_item_content > div.btn_conte {
  width: 100%;
  background: #fff;
  margin: 2px 1px;
  text-align: center;
  height: auto;
}

.data-cont > .newColor > .data_item_content > div i.copyIcon {
  cursor: pointer;
  font-weight: 600;
  color: #888;
}

.data-cont > .newColor > .data_item_content > div > span {
  display: block;
}

.data-cont > .newColor > .data_item_content > div > span:nth-child(1) {
  font-size: 0.6rem;
  color: #888;
  font-weight: 600;
}

.data-cont > .newColor > .data_item_content > div > span:nth-child(2) {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000;
}

.data-cont > .newColor > .data_item_content > div > span:nth-child(3) {
  font-size: 0.65rem;
}

.data-cont > .newColor > .data_item_content > div > span.flex_box_1 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .newColor > .data_item_content > div > span.flex_box_2 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .data_item_content.clps {
  display: none;
  /* animation-duration: 0.3s;
    animation-name: slideDown; */
}

.data-cont > .newColor.open > .data_item_content.clps {
  display: flex;
}

.data-cont > .newColor > .data_item_content.clps {
  display: none;
  /* animation-duration: 0.3s;
    animation-name: slideDown; */
}

.data-cont > .newColor > div.new_collps_icon {
  position: absolute;
  display: flex;
  height: 20px;
  width: 40px;
  background: #ff7200;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  right: 4px;
  bottom: 3px;
  cursor: pointer;
  border-top-left-radius: 2px;
}

.data-cont > .newColor > div.new_collps_icon > i {
  font-size: 1.5rem;
  color: #fff;
  margin-top: -4px;
}

.data-cont > .newColor.open > div.new_collps_icon > i {
  margin-top: 0;
  margin-bottom: -4px;
  opacity: 0;
  animation-duration: 0.3s;
  animation-name: rotateAnimation;
  animation-fill-mode: forwards;
}

/* *****************settings css**************** */

.settings-outer .hide-view {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 10px;
  justify-content: space-between;
}

.hide-view {
  width: 100%;
  height: 45px;
  overflow: hidden;
  margin-bottom: 25px;
}

.hide-view.open {
  overflow: unset;
}

.varying-height {
  width: 100%;
}

.settings-inner {
  width: 100%;
  height: 45px;
}

.settings-list {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid silver;
}

.settings-list1 {
  width: 18%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(0 0 0 / 7%);
}

.settings-list2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list4 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-3 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-44 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-7 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-9 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-11 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-list-four {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.settings-arrow-icon-4 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.settings-arrow-icon-44 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.settings-arrow-icon-9 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.settings-arrow-icon-3 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.settings-arrow-icon-11 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.toggle-settings-list2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.prefix-settings-list2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.prefix-arrow-icon {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.stamp-settings-list2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.set-list2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(0 0 0 / 5%);
}

.color {
  background-color: #ff7200 !important;
}

.settings-list3 {
  width: 18%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(0 0 0 / 5%);
}

.settings-list4 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
}

.settings-list5 {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  background-color: rgb(0 0 0 / 5%);
}

.setting5-I1 {
  width: 37%;
  height: 100%;
  position: relative;
  display: flex;
}

.setting5-I1-new {
  width: 37%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.settings5-V1 {
  width: 100%;
  height: 50%;
  display: flex;
}

.settings5-V2 {
  width: 100%;
  height: 50%;
  display: flex;
}

.setting5-I2 {
  width: 45%;
  height: 100%;
  display: flex;
}

.setting5-I2-new {
  width: 45%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.settings-inner-1 {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}

.settings-span1 {
  color: black;
  font-weight: 600;
  font-size: 0.8rem;
}

.settings-span-prefix {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-2 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-3 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-4 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-44 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-7 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-9 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-span-11 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.changeBack {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.changeArrow {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.changeText {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.changeBack1 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.changeArrow1 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.changeText1 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.changeBack2 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.changeArrow2 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.changeText2 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.changeBack3 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.changeArrow3 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.changeText3 {
  color: #727376;
  font-weight: 600;
  font-size: 0.9rem;
}

.settings-arrow-icon {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.settings-arrow-icon-7 {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.stamp-arrow-icon {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.toggle-settings-arrow-icon {
  color: #727376;
  font-weight: 600;
  font-size: 24px;
}

.rotate {
  transform: rotate(90deg);
  color: white;
}

.settings-inner-2 {
  width: 25%;
  height: 55%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff7200;
  padding: 1px;
}

.settings-inner-2-new {
  width: 25%;
  height: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff7200;
  padding: 1px;
}

.settings-yes {
  width: 50%;
  height: 100%;
  background-color: #fff;
  border: none;
}

.settings-no {
  width: 50%;
  height: 100%;
  background-color: #ff7200;
  border: none;
}

.cust-container-1 {
  width: inherit;
  position: relative;
  padding-top: 10px;
  padding-right: 20px;
}

.bankDivOuter {
  display: none;
}

.bankDivOuter-is-visible {
  width: 100%;
  height: 240px;
  border: 1px solid silver;
  border-top: none;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: rgb(0 0 0 / 2%);
}

.bankDetail1 {
  width: 100%;
  height: 48px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
}

.bankDetailInner {
  width: 25%;
  height: 80%;
  position: relative;
  top: 50%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  transform: translateY(-50%);
}

.bankDetailInputField {
  width: 100%;
  height: 95%;
  border: 1px solid silver;
  border-radius: 4px;
  color: #888;
  font-size: 0.8rem;
  padding-left: 5px;
}

.bankDetailInputField::placeholder {
  color: #000;
  font-size: 0.7rem;
  padding-left: 5px;
}

.bankDetalsbtnDiv1 {
  width: 25%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bankDetailbtn {
  width: 35%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/*  */
.ViewBankDetails {
  display: none;
}

.ViewBankDetails-is-visible {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid silver;
  border-top: none;
  position: relative;
  top: 0px;
  background-color: rgb(0 0 0 / 2%);
}

.viewBankDetails1 {
  width: 98%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.vieBankDetailInner1 {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.viewDetailsend {
  width: 25%;
  height: 80%;
  display: flex;
  margin-right: 15px;
}

.settings-inner-2.wd-10 {
  width: 29%;
  height: 78%;
}

.vieBankDetailInner2 {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
}

.set_wd_15 {
  width: 17%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.set_wd_5 {
  width: 2%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.bold {
  font-size: 0.75rem;
  font-weight: 600;
}

.light {
  font-size: 0.7rem;
  font-weight: 400;
  padding-left: 4px;
}

#basicdetailtrash {
  font-size: 16px;
  padding-right: 10px;
  cursor: pointer;
}

.viewBankDetails2 {
  width: 98%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* ********pdf settings css******** */
.settings-outer .hide-view {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 10px;
  justify-content: space-between;
}

.pdfDivOuter .hide-view1 {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 10px;
  justify-content: space-between;
}

.hide-view1 {
  width: 100%;
  height: 45px;
  overflow: hidden;
  margin-bottom: 20px;
}

.pdfDivInner {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 5%);
  border: 1px solid silver;
}

.pdfI-1 {
  height: 100%;
  width: 25%;
  height: 100%;
  background-color: rgb(0 0 0 / 3%);
  justify-content: flex-start;
  display: flex;
}

.pdf-list1 {
  width: 81%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pdf-list2 {
  width: 19%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.pdfI-2 {
  width: 33%;
  height: 100%;
  display: flex;
  margin-right: 12px;
}

.new-settings-inner-1 {
  width: 74%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}

.settings-span1 {
  color: black;
  font-weight: 600;
  font-size: 0.8rem;
}

.new-settings-inner-2 {
  width: 20%;
  height: 50%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff7200;
  padding: 1px;
}

.pdfColorDiv {
  width: 100%;
  height: 400px;
  border: 1px solid silver;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(0 0 0 / 2%);
}

.pdfColorC1 {
  width: 90%;
  height: 55px;
  display: flex;
  margin-left: 53px;
}

.pdfColorDiv1 {
  width: 18%;
  height: 80%;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  border: 1px solid silver;
  background-color: #fff;
  margin-right: 25px;
  margin-left: 0px;
}

.pdfColorDiv1 > i {
  position: absolute;
  right: -6px;
  top: 20px;
  transform: rotate(313deg) !important;
  width: 24px;
  height: 12px;
  overflow: hidden !important;
}

.pdfColorDiv1 > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: -13px;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: silver;
}

.settings-heading {
  font-weight: 12px;
  font-weight: 600;
}

.pdfColorDiv2 {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.colorDiv1 {
  width: 22%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  justify-content: space-around;
}

.colorPink {
  width: 35%;
  height: 60%;
  border-radius: 50%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background-color: pink;
  cursor: pointer;
  z-index: 1;
}

.colorblue {
  width: 35%;
  height: 60%;
  border-radius: 50%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background-color: lightblue;
  cursor: pointer;
  z-index: 1;
}

.colorgreen {
  width: 35%;
  height: 60%;
  border-radius: 50%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background-color: lightgreen;
  cursor: pointer;
  z-index: 1;
}

.coloryellow {
  width: 35%;
  height: 60%;
  border-radius: 50%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  background-color: yellow;
  cursor: pointer;
  z-index: 1;
}

.colorTick {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -5px;
  top: 14px;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  background-color: white;
  border: 1px solid silver;
}

.pdfColorBtn {
  width: 100%;
  height: 40px;
}

.pdfColorsBtnDiv {
  width: 25%;
  height: 100%;
  justify-content: space-around;
  display: flex;
  margin-left: 53px;
}

.pdfColorsBtnDiv button {
  width: 35%;
  height: 30px;
  background-color: #ff7200;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

/* prefix */
.prefixDiv {
  width: 100%;
  height: auto;
  position: relative;
  top: 20px;
  padding: 10px;
}

.prefixDiv .hide-view {
  width: 100%;
  /* height: auto; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 10px;
  justify-content: space-between;
}

.prefixDivInner {
  width: 100%;
  height: 45px;
  display: flex;
  background-color: rgb(0 0 0 / 5%);
  border: 1px solid silver;
}

.prefix-1 {
  width: 20%;
  height: 100%;
  display: flex;
}

.prefix-2 {
  width: 20%;
  height: 100%;
  display: flex;
}

.new-pdf-list2 {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.prefixDetailDiv {
  width: 100%;
  height: 200px;
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
}

/*  */
.HomeScreenDiv {
  width: 100%;
  height: 380px;
  border: 1px solid silver;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(0 0 0 / 2%);
}

.HomeScreenInner {
  width: 92%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.homescreenHeading {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homescreenHeading span {
  font-size: 0.8rem;
  font-weight: 600;
}

.HomeScreenInner .homeS-1 {
  width: 19%;
  height: 100%;
  border-radius: 22px;
  background-color: #fff;
  border: 1px solid silver;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
}

.active_homeS-1 {
  width: 19%;
  height: 100%;
  cursor: pointer;
  border-radius: 22px;
  background-color: #ff7200;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.homescreenInnerimg {
  width: 18%;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homescreenInnerimg img {
  width: 28px;
}

.homescreenInnerHead {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
}

.home-title {
  color: black;
  font-size: 0.8rem;
}

.active-home-title {
  color: #fff;
  font-size: 0.8rem;
}

.homescreenbtndiv {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.homescreenbtndiv button {
  width: 33%;
  height: 30px;
  border-radius: 4px;
  background-color: #ff7200;
  border: none;
  color: #fff;
}

.caret_right {
  position: absolute;
  top: 12px;
  right: -9px;
  font-size: 23px;
  color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.stampDiv {
  width: 100%;
  height: auto;
  border: 1px solid silver;
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  background-color: rgb(0 0 0 / 2%);
}

.stampInputDiv {
  width: 100%;
  height: 145px;
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stampInputdiv1 {
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stampInputdiv1 span {
  color: #000;
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
}

.stampInputdiv1 select {
  width: 45%;
  height: 62%;
  border: 1px solid silver;
  border-radius: 6px;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
}

.stampInputdiv2 {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
}

.stamInner2-i1 {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stamInner2-i1 span {
  color: #000;
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
}

.stamInner2-i2 span {
  color: #000;
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
}

.stamInner2-i1 input {
  width: 95%;
  height: 62%;
  border: 1px solid silver;
  border-radius: 6px;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
}

.stamInner2-i1 input::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 5px;
}

.stamInner2-i2 {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stamInner2-i2 input {
  width: 95%;
  height: 62%;
  border: 1px solid silver;
  border-radius: 6px;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
}

.stamInner2-i2 input::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 5px;
}

.stampSignatureDiv {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 22px;
}

.stamSignatureDiv1 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.stamSignatureDiv1 span {
  color: #000;
  font-size: 1.1rem;
  padding-left: 12px;
  font-weight: 600;
}

.stamSignatureDiv1 button {
  width: 8%;
  height: 28px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: red;
  cursor: pointer;
  margin-right: 16px;
}

.stamSignatureDiv1 button:hover {
  width: 8%;
  height: 28px;
  border-radius: 5px;
  border: 1px solid red;
  color: red;
  background-color: #fff;
  cursor: pointer;
  margin-right: 16px;
}

.stamSignatureDiv2 {
  width: 100%;
  height: 200px;
}

.signCanvas {
  width: 50%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.MysignCanvas {
  width: 400px;
  height: 180px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 6px;
}

.stamSignatureDiv3 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.uploadStampImg {
  width: 15%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  margin-left: 15px;
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.stampInputFile {
  height: 100%;
  width: 100;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.stamToggleDiv {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}

.cropBtn {
  width: 25%;
  height: 30px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  margin-left: 15px;
}

.resizeBtn {
  width: 30%;
  height: 30px;
  background-color: #727374;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  margin-left: 15px;
}

.stampImageDiv {
  width: 97%;
  height: 240px;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 10px;
}

.stamImageCont {
  width: 100%;
  height: 100%;
}

.stamUploaded {
  width: 40%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.stamUploaded img {
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.newstamUploaded {
  width: 35%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.belowImg {
  width: 100% !important;
  height: 100% !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.overflowImg {
  width: 20% !important;
  height: 20% !important;
  position: absolute !important;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 20px;
}

.stampPreviewDiv {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  top: 12px;
}

.previewStamp {
  width: 18%;
  height: 30px;
  background-color: #727374;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8rem;
  margin-left: 15px;
}

.previewstampimg {
  width: 97%;
  height: 240px;
  border: 1px solid black;
  /* padding-top: 0px; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 17px;
}

.stampSubmitBtn-6 {
  width: 35%;
  /* height: 80%; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 28px;
  margin-bottom: 35px;
}

.previewstamimginner {
  width: 100%;
  height: 100%;
}

.stamUploaded {
  width: 40%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.stampSubmit {
  width: 100%;
  height: 100px;
  padding-top: 20px;
}

.stampSubmitBtn {
  width: 35%;
  /* height: 80%; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 48px;
}

.stampSubmitBtn_new {
  width: 35%;
  /* height: 80%; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 32px;
}

.submitMe {
  width: 25%;
  height: 35px;
  background-color: #ff7200;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8rem;
}

.cancelMe {
  width: 25%;
  height: 35px;
  background-color: #ff7200;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.8rem;
}

.stampUpload {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 35px rgb(0 0 0 / 5%);
  padding: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  background-color: 0 0 35px rgb(0 0 0 / 5%);
  border: 2px dashed black;
  z-index: 2;
}

.stampUpload button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
}

.align_div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: -1;
}

.ViewStampDiv {
  width: 100%;
  height: 400px;
  border: 1px solid silver;
  background-color: rgb(0 0 0 / 2%);
}

.viewStampInner-6 {
  width: 100%;
  height: 370px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.viewStampForm-6 {
  width: 50%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
  top: 20px;
}

.viewStamInnerform-6 {
  width: 70%;
  height: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.viewStampInner {
  width: 100%;
  height: 400px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.viewStampForm {
  width: 50%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 5px;
}

.viewStamInnerform {
  width: 70%;
  height: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vewStampformL-1 {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border-bottom: 1px dashed black;
}

.vewStampformL-1:last-child {
  border: none;
}

.viewStampFormL {
  width: 45%;

  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.viewStampformHead {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

.viewStampFormR {
  width: 55%;
  display: flex;
  align-items: center;
}

.viewformhead2 {
  color: #000;
  font-size: 1rem;
  font-weight: 200;
}

.viewOnlyStamp {
  width: 30%;
  height: 100%;
}

.viewStampImg {
  width: 100%;
  height: 70%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.viewBoth {
  width: 60%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.viewOnlyimg {
  width: 80%;
  height: 100%;
}

.viewOnlyStamp {
  width: 60%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.viewOnlyStamp img {
  width: 100%;
  height: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.ViewStamCancel {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 24px;
}

.ViewStamCancel button {
  width: 6%;
  height: 30px;
  background-color: red;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}

/* addPdfDetailsDiv */
.addPdfDetailsDiv {
  /* width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 15px; */
  border: 1px solid silver;
  height: 400px;
  background-color: rgb(0 0 0 / 2%);
  position: relative;
  /* overflow-y: scroll !important; */
}

.addPdfDetailsInput1 {
  width: 95%;
  /* height: 54px; */
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  position: relative;
  top: 20px;
}

.addPdfDetailc-1 {
  width: 48%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.pDI_detail {
  width: 97%;
  /* height: 100%; */
  display: flex;
  margin-bottom: 5px;
}

.addPdfspan1 {
  color: #000;
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
}

.addpdfinput2 {
  width: 87%;
  height: 40px;
  border: 1px solid silver;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
}

.addpdfinput2_wd_100 {
  width: 89%;
  height: 40px;
  border: 1px solid silver;
  border-radius: 6px;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
  /* background-color: red; */
}

.addpdfinput2::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 10px;
}

.addPdfDetailc-2 {
  width: 48%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

/* addpdftable */
.addpdftable {
  width: 100%;
  /* height:150px; */
  position: relative;
  top: 35px;
}

.addPdfTableInner {
  width: 98%;
  height: 120px;
  border: 3px solid #727376;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}

.addPdfTableHead {
  width: 100%;
  height: 30%;
  background-color: #727374;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addPdfTableHead span {
  color: #fff;
  font-size: 0.8rem;
}

.addPdfTableFooter {
  width: 95%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.addpdftable1 {
  width: 23%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pdfTableSpan {
  color: #000;
  font-size: 0.85rem;
}

.pdfTableSelect {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  color: #888;
  padding-left: 5px;
  font-size: 0.8rem;
  border: 1px solid silver;
}

.pdfTableInput {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid silver;
}

.pdfTableInput::placeholder {
  color: #888;
  padding-left: 5px;
  font-size: 0.8rem;
}

.viewPdfDetailsDiv {
  width: 100%;
  /* height: auto;
    padding: 15px; */
  padding-top: 0px;
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
}

/* basicDetailDiv */
.basicDetailDiv {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  border: 1px solid silver;

  background-color: rgb(0 0 0 / 2%);
}

.basicDetailList {
  width: 100%;
  /* height: 60px ; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 20px;
}

.basicDetailspan {
  color: #000;
  padding-left: 10px;
  font-size: 0.85rem;
  font-weight: 600;
}

.inputAndAdd {
  width: 100%;
  height: 35px;
  display: flex;
  cursor: pointer;
  margin-bottom: 0px;
  margin-bottom: 5px;
}

.basicDetailspan1 {
  width: 92%;
  height: 100%;
  border: 1px solid silver;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
  padding-bottom: 5px;
  border-radius: 6px;
}

.basicDetailspan2 {
  width: 92%;
  height: 100%;
  border: 1px solid silver;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: none;
  color: #888;
  font-size: 0.8rem;
  margin-left: 10px;
  padding-bottom: 5px;
}

.basicDetailspan2::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 10px;
}

.add_icon {
  width: 30px;
  height: 100%;
  border: 1px solid silver;
  /* border-left: none; */
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.minus_icon {
  width: 30px;
  height: 95%;
  border: 1px solid silver;
  /* border-left: none; */
  border-radius: 6px;
  background-color: white;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

/* viewBasicDetails */
.viewBasicDetails {
  width: 100%;
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
}

.viewStampInner-5 {
  width: 100%;
  height: 260px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.viewBsicDetailsForm {
  width: 65%;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 5px;
}

.addTermsDiv {
  width: 100%;
  height: 185px;
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
  display: flex;
  padding: 15px;
}

.addTermsLeft {
  width: 20%;
  height: 120px;
}

.addTermsTop {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid silver;
  border-right: none;
  cursor: pointer;
}

.toggleBorder {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid silver;
  border-right: none;
  cursor: pointer;
  border: 1px solid #ff7200;
  border-right: none;
  background-color: #ff7200;
}

.addTermsBottom {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid silver;
  border-top: none;
  border-right: none;
  cursor: pointer;
}

.addTermsSpan {
  color: black;
  font-size: rem;
}

.addTermsSpan-new {
  color: #fff;
  font-size: rem;
}

.addTermsInner {
  width: 80%;
  border: 1px solid #ff7200;
  padding-bottom: 0px;
  overflow-y: scroll;
}

.addTermsRight {
  width: 100%;
  height: auto;
}

.addTermsPoint {
  width: 90%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.addTermsPT {
  width: 100%;
  height: 50%;
  display: flex;
}

.addTermsnum-1 {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addTermsNo {
  color: black;
  font-size: 0.9rem;
}

.addTermsnum-2 {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px dashed black;
}

.addTermsp {
  color: black;
  font-size: 0.9rem;
  color: #888;
}

.addTermsPB {
  width: 100%;
  height: 50%;
  display: flex;
}

.ViewTerms {
  width: 100%;
  height: auto;
}

.ViewTerms-new {
  width: 100%;
  /* height: 185px; */
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
  display: flex;
  padding: 20px;
}

.ViewTermsInner {
  width: 90%;
  height: 75%;
  position: relative;
  top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.ViewTermsInner2 {
  width: 90%;
  height: 75%;
  position: relative;
  top: -10px;
  margin-left: auto;
  margin-right: auto;
}

.viewTermsInner-1 {
  width: 100%;
  height: 50%;
  display: flex;
  margin-bottom: 10px;
}

.termsSno {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.termsDiv {
  width: 80%;
}

.terms_cdtnInput {
  width: 100%;
  height: 45px;
  position: relative;
  top: 50%;
  padding-left: 5px;
  transform: translateY(-50%);
  border-radius: 5px;
  border: 1px solid silver;
  box-shadow: 0px 0px 5px silver inset;
}

.termsBtn {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.terms_cancel {
  width: 30%;
  height: 30px;
  border: 1px solid red;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.fa_cross {
  font-size: 20px;
  color: red;
}

.terms_add {
  width: 30%;
  height: 30px;
  border: 1px solid green;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.fa_add {
  font-size: 20px;
  color: green;
}

.viewTermsBtn {
  width: 40%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;
  position: relative;
  bottom: 0px;
}

.viewTermsBtn-1 {
  width: 40%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 0px;
  position: relative;
  bottom: 10px;
}

.viewTermsCtnBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: centre;
  justify-content: space-around;
}

.viewTermsCtns {
  width: 100%;
  height: 185px;
  background-color: rgb(0 0 0 / 2%);
  border: 1px solid silver;
  display: flex;
  padding: 20px;
}

.master {
  font-size: 22px;
}

/* Update Truck Number modal */

.updateTruckModal,
.truckOverlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block !important;
  z-index: 1000;
}

.truckOverlay {
  background-color: rgba(49, 49, 49, 0.8);
}

.truck-content {
  width: 26%;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.1;
  background-color: #ff7200;
  border-radius: 10px;
  text-align: center;
  display: block;
  z-index: 1111;
  padding: 5px;
  padding-top: 40px;
}

.tr-main {
  color: #fff;
  position: absolute;
  left: 0px;
  top: 20px;
  left: 50%;
  font-size: 1rem;
  transform: translate(-50%, -50%);
}

.truck_content_inner {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 12px;
}

.truck_content_detail {
  width: 100%;
  height: 50px;
  position: relative;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.tr-he {
  font-size: 0.9rem;
  color: black;
  font-weight: 700;
}

.tr-in {
  width: 50%;
  height: 28px;
  border-radius: 5px;
  border: 1px solid silver;
  font-size: 0.8rem;
  padding-left: 5px;
  margin-right: 5px;
}

.tr-in::placeholder {
  color: #888;
}

.truckUpdateBtn {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 24%;
  height: 28px;
  border: none;
  border-radius: 6px;
  background-color: #727376;
  color: #fff;
  cursor: pointer;
}

.space {
  position: relative;
  top: 15px;
  justify-content: center;
}

.subtractIcon {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.subtractIcon1 {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 38%;
  transform: translateY(-50%);
}

.equals {
  font-size: 16px;
  font-weight: 700;
}

.equals0 {
  font-size: 14px;
  position: relative;
  left: 16%;
  top: 51%;
  transform: translateY(-50%);
}

.custom-btn {
  height: 40px;
  margin-left: 0px !important;
}

/* allocate modal */
.allocated_vehicle_innner {
  width: 100%;
  height: auto;
  background-color: rgb(0 0 0 / 6%);
  border-radius: 4px;
  padding: 4px;
  padding-bottom: 20px;
}

.allocated-heading {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
}

.allocated-heading span {
  color: black;
  font-weight: 600;
  font-size: 13px;
}

.allocatecolor {
  color: #ff7200 !important;
}

.allocate-header {
  width: 99%;
  height: 50px;
  display: flex;
  border: 1px solid #000;
  border-right: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.all-col {
  width: 17%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-right: 1px solid #000;
}

.all-col span {
  color: black;
  font-weight: 600;
  font-size: 0.6rem;
}

.allocate-body {
  width: 99%;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border: 1px solid #000;
  border-top: none;
  flex-direction: column;
  position: relative;
  top: 0px;
}

.allocate-body-cover {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 0px;
}

.all-row {
  width: 16.67%;
  height: auto;
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-right: 1px solid #000;
}

.all-row:last-child {
  border-right: none;
}

.all-row span {
  font-size: 0.7rem;
  color: #888;
}
.flexColumnAll {
  display: flex !important;
  flex-direction: column !important;
}
.infoIcon {
  font-size: 1rem;
  color: #888;
  margin: 4px;
}

.adjust {
  /* width: 17%; */
  font-size: 0.6rem;
  background-color: green;
  color: white;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  bottom: 1px;
}

.adjust-cross {
  font-size: 18px;
  color: red;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
}

.size_new {
  width: 23px;
  margin: 0 8px;
  font-size: 1.2rem;
  cursor: pointer;
}

.update {
  margin-right: 6px;
}

.flex-me {
  display: inline-flex;
  color: #228b22;
  cursor: pointer;
  margin: 0px 0px;
  position: relative;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.btn-span {
  width: 100%;
  border-right: 1px solid green;
}

.btn-img {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inline-flex {
  width: auto;
  height: 33px;
  /* display: inline-flex; */
  padding: 0px !important;
}

.btn_text {
  height: inherit;
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: GOTHIC;
  margin-left: 0px !important;
  border-right: 1px solid #ff7200;
  padding-left: 6px !important;
  padding-right: 6px !important;
  font-size: 0.9rem;
}

.btn_text:hover {
  border-right: 1px solid #fff;
}

.removeBrdr {
  border-right: none !important;
}

.btn_pic {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn_pic:hover {
  border-left: 1px solid #fff;
}

.btn-shadow {
  height: 25px;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.prevent-overflow {
  position: relative;
  top: 15px;
}

.allocate-inner {
  width: 100%;
  display: flex !important;
  position: relative !important;
  flex-direction: column !important;
  margin-bottom: 40px !important;
}

.allocate-new {
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: flex-end;
}

.allcate_20 {
  width: 41.7%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-right: 0px;
}

.spreadElem {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spreadElem1 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spreadElem2 {
  width: 20%;
  height: 100%;
  display: flex;
  position: relative;
  right: 25px;
  justify-content: center;
  align-items: center;
}

.set-new {
  width: 33%;
}

.form-control-new {
  width: 75%;
  height: 27px;
  padding: 5px !important;
  border-radius: 5px !important;
  font-size: 0.7rem !important;
  border: none;
  border: 1px solid silver;
  color: #888;
}

.form-control-new::placeholder {
  font-size: 0.75rem;
  color: #ff7200;
}

.form-control[type="number"]:disabled {
  background-color: #fff;
}

.set-w {
  width: 60%;
}

.no-b {
  border: none !important;
  background-color: transparent;
}

.weight_span {
  font-size: 0.75rem;
  position: relative;
  top: 5px;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 18px;
  color: #727376;
  font-weight: 700;
}

.post {
  position: relative;
  left: 10px;
}

/* allocate */
.allocateDiv1 {
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
}

.all-div-1 {
  width: 100%;
  height: 70px;
  position: relative;
  top: 40%;
  display: flex;
  transform: translateY(-50%);
}

.all-div-1l {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.all-div-1R {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.allocateDiv2 {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
}

.allocate2_head {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}

.allocate_heading {
  font-size: 0.9rem;
  padding-left: 5px;
  font-weight: 600;
  color: BLACK;
}

.allocate2_body {
  width: 100%;
  height: 80%;
  display: flex;
}

.allocate2_BL {
  width: 50%;
  height: 100%;
}

.allocate2_BLinner {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.allocate-inner-head {
  display: flex;
  padding-left: 5px;
  padding-top: 10px;
  font-size: 0.8rem;
  color: #727376;
  font-weight: 600;
}

.allocate_input {
  width: 80%;
  height: 27px;
  border-radius: 5px;
  border: 1px solid silver;
  margin-left: 5px;
}

.allocate_input::placeholder {
  color: #888;
  padding-left: 5px;
  font-size: 0.75rem;
}

.allocate2_BR {
  width: 50%;
  height: 100%;
}

.allocate_br1 {
  width: 100%;
  height: 60px;
  display: flex;

  justify-content: space-between;
}

.all_load_head {
  width: 70%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.all_actions {
  width: 15%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  padding-left: 10px;
}

.allocate_br2 {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.allocate_input_1 {
  width: 30%;
  height: 27px;
  border-radius: 5px;
  border: 1px solid silver;
  margin-left: 5px;
}

.more_width {
  visibility: hidden;
  width: 39%;
}

.allocate_input_1::placeholder {
  color: #888;
  padding-left: 5px;
  font-size: 0.75rem;
}

.all-sub {
  font-size: 18px;
  padding-left: 4px;
}

.all-total {
  font-size: 17px;
  padding-left: 4px;
}

.info-icon {
  font-size: 18px;
  cursor: pointer;
}

.all_plus {
  font-size: 18px;
  color: green;
  cursor: pointer;
}

.all_minus {
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.allocateDiv3 {
  width: 100%;
  height: 90px;
}

.allocateDiv4 {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 25px;
}

.allocate_btn {
  width: 17%;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #727376;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
}

.btn-dim {
  height: 40px;
  font-size: 0.8rem;
}

.map_btn_div {
  position: absolute;
  top: 72px;
  z-index: 10;
  right: 55px;
  width: 40px;
  height: 27px;
  background-color: #ff7200;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
}

.map_btn {
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.map_icon {
  font-size: 24px;
  color: #fff;
}

.children1 {
  list-style: none;
  /* display: none !important; */
  margin: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid #eaecf0;
  background-color: #f6f7f8;
  transition: all 0.2s ease-out 0s;
}

.pseudoElement:before {
  border-top: 0px solid #ff7200 !important;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
}

#myInput {
  border: none;
  display: inline-flex;
  font-weight: 600;
}

.inspectionRadioDiv {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 0px;
}

.inspectionTop {
  width: 80%;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.inspectSpan {
  font-size: 0.8rem;
  color: black;
  font-weight: 900;
}

.inspectionBottom {
  width: 80%;
  height: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.align-start {
  left: 5%;
  position: relative;
}

/* *** Add Contract UI *** */
.cust-col-6 .titleBox {
  width: 150px;
  height: 28px;
  padding: 5px;
  background-color: #727376;
  position: absolute;
  top: 45px;
  right: 15px;
  border-radius: 4px;
  z-index: 9999999999;
  display: none;
}

.titleBox::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: -7px;
  right: 3%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #727376;
  overflow: hidden;
}

.cust-col-6 > .button:hover > .titleBox {
  width: 150px;
  height: 28px;
  padding: 5px;
  background-color: #727376;
  color: #666666;
  position: absolute;
  top: 45px;
  border-radius: 4px;
  z-index: 9999999999;
  display: block !important;
}

.titleBox span {
  color: #fff;
  font-size: 0.7rem;
}

.addContractDiv1 {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 14px;
}

.ContractDetails1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 4px;
  border-left: 6px solid #7d7d7c;
}

.addContract-I {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modifiedElem {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: rgb(0 0 0 / 4.5%) !important;
}

.multIconsDiv {
  width: auto;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: -13px;
  cursor: pointer !important;
  right: -3px;
  padding: 4px;
  cursor: pointer;
  z-index: 10;
}

.absoluterMinus {
  cursor: pointer !important;
  right: 21px;
  color: #fff;
  padding: 4px;
  border-radius: 50%;
  background-color: red;
  margin-left: 4px;
}

.absoluterPlus {
  cursor: pointer !important;
  right: 21px;
  color: #fff;
  padding: 4px;
  border-radius: 50%;
  background-color: green;
}

.contract-in {
  width: 30% !important;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.algn-cent {
  display: flex;
  justify-content: center;
}

.stretch_Elem {
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.extraWidth {
  width: 35% !important;
}

.contract-span {
  width: auto;
}

.border-right {
  border-right: none !important;
  border-radius: 2px !important;
}

.ct-span {
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
}

.contract-input {
  width: 62% !important;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
}

.alignCentre {
  width: 62% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-input {
  width: 100%;
  height: 37px;
  border-radius: 4px;
  /* border: 1px solid silver; */
  padding-left: 5px;
  opacity: 10;
  border: 1px solid hsl(0, 0%, 80%);
}

.ct-input::placeholder {
  color: #000;
  font-size: 0.7rem;
}

.alter-dim {
  border-top: 10px solid #fff;
  margin-right: 10px;
}

.button_text {
  width: 80%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GOTHIC;
}

.button_icon {
  border-left: 1px solid #6c757d;
  width: 20%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 7px;
}

.flex-mid.open {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex-mid.hide {
  display: none;
  /* visibility: hidden; */
}

.flex-end {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.addContractDiv2.open {
  display: block;
  width: 100%;
  animation-duration: 0.7s;
  animation-name: expandAnimation;
  animation-fill-mode: forwards;
  border: 1px solid silver;
  background-color: #fff;
}

@keyframes expandAnimation {
  0% {
    opacity: 1;
    position: relative;
    top: 140px;
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-100%);
  }

  100% {
    width: 100%;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(0);
  }
}

.addContractDiv2.hidden {
  visibility: hidden;
  display: none;
}
.addContractDiv2.open.hide-me {
  display: none;
}
.addContractDiv2.hide-me {
  animation-duration: 0.6s;
  animation-name: contractAnimation;
  animation-fill-mode: forwards;
}

@keyframes contractAnimation {
  0% {
    opacity: 1;
    position: relative;
    top: 0px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  100% {
    width: 0%;
    opacity: 0;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }
}

.inputMain {
  width: 100%;
  position: relative;
  background-color: rgb(0 0 0 / 6%);
}

.addContractBtns {
  width: 99%;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(0 0 0 / 11%);
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
}

.flex-end-div {
  width: 27%;
  height: 70%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.flex-1 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.AddContBtn {
  width: auto;
  height: 28px;
  border: none;
  border-radius: 4px;
  background-color: green;
  color: #fff;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.removeContBtn {
  width: auto;
  height: 28px;
  border: none;
  border-radius: 4px;
  background-color: red;
  color: #fff;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.flex-2 {
  width: 50%;
  height: 100%;
  display: flex !important;
  position: relative;
  align-items: center;
  justify-content: space-around !important;
}

.flex-child-1 span {
  color: black;
  font-size: 0.85rem;
  font-weight: 700;
}

.previewBtn {
  width: 9%;
  height: 32px;
  background-color: #727376;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-right: 15px;
}

.preview-eye {
  color: cream;
}

.saveContractBtn {
  width: 9%;
  height: 32px;
  background-color: #ff7200;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
}

.addContract2-1 {
  width: 100%;
  height: 35px;
  color: #fff;
  background-color: #ff7200;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: space-between;
}

.addContract2-1 span {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
}

.addContract2-2 {
  width: 100%;
  height: auto;
  padding-bottom: 0px;
  background-color: #fff;
}

.newContElem {
  width: 100%;
  height: 60px;
  position: relative;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mgRightt {
  margin-right: 6px;
}

.addContract-table {
  width: 100%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: #f5f5f5;
}

.alignDiv {
  width: 45%;
  height: 70px;
  display: flex;
  justify-content: end;
}

.addContracts-c1 {
  width: 10%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.addContracts-c1 span {
  color: #000;
  font-size: 0.85rem;
  font-weight: 700;
}

.addContracts-c2 {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  padding-left: 0px;
}

.addCont-r1 {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
}

.addCont-newr1 {
  width: 57% !important;
  height: 37px;
  margin-left: auto;
  margin-right: auto;
}

.addCont-r2 {
  width: 62%;
  height: 37px;
  margin-left: auto;
  margin-right: auto;
}

.addCont-r2 select {
  width: 40%;
  height: 100%;
  cursor: pointer;
  border: 1px solid silver;
  border-radius: 4px;
  outline: none;
}

.sec-value {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.sec-value span {
  font-size: 0.85rem;
  font-weight: 700;
  color: #ff7200;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-transform: uppercase;
}

.contractTable-span {
  color: #000;
  font-size: 0.74rem;
  font-weight: 700;
}

.pd-15 {
  padding-left: 15px;
}

.contractTable-input {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
}

.cT-input {
  width: 70px;
  height: 37px;
  border: 1px solid silver;
  border-radius: 4px;
}

.cT-input::placeholder {
  color: #888;
  font-size: 0.7rem;
}

.width_alt {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 95% !important;
}

.contractTable-input::placeholder {
  color: #888;
  font-size: 0.7rem;
}

.addContracts-c3 {
  width: 82%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 0px;
}

.addContracts-c4 {
  width: 17%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.asterick {
  color: red;
}

.addContracts-c5 {
  width: 10%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* background-color: red; */
}

.addContracts-c5 span {
  color: black;
}

.addIcon {
  color: green;
  cursor: pointer;
}

.addIconButton {
  width: 70%;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: green;
  color: white;
  font-size: 0.7rem;
}

.dustbinIconButton {
  width: auto;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: red;
  color: white;
  font-size: 0.7rem;
}

.btn-centre {
  color: #ff7200;
  position: relative;
  font-size: 21px !important;
}

.dust-centre {
  position: relative;
  font-size: 21px !important;
}

.subIcon {
  color: red;
  cursor: pointer;
}

.subIconColor {
  color: #fff;
  cursor: pointer;
  margin-right: 20px;
}

.newaddIcon {
  color: green;
  cursor: pointer;
}

.newaddIcon.hide {
  display: none;
}

.newaddIcon.open {
  display: block;
}

.newaddIcon1 {
  color: green;
  cursor: pointer;
}

.newaddIcon1.hide {
  display: none;
}

.newaddIcon1.open {
  display: block;
}

.newminusIcon1 {
  color: red;
  cursor: pointer;
  position: relative;
  left: 4px;
}

.mg-10 {
  margin-left: 7px;
  margin-right: 7px;
}

.dustbinIcon {
  font-size: 19px;
  cursor: pointer;
}

.dustbinIcon:hover {
  color: red;
}

.plusIcon {
  cursor: pointer;
}

.dustRed {
  font-size: 0.8rem !important;
  cursor: pointer;
  color: red;
}

.eyeIcon {
  color: black;
  cursor: pointer;
}

.eyeIconColored {
  color: #ff7200;
  cursor: pointer;
}

.absolutePost {
  position: absolute;
  bottom: 20px;
  z-index: 10;
  right: 200px;
}

.absolutePost1 {
  position: absolute;
  bottom: 11px;
  z-index: 10;
  right: 200px;
  width: 11%;
  height: 26px;
}

.editIcon {
  font-size: 0.8rem;
  color: #ff7200;
  cursor: pointer;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 26px;
  margin-left: auto;
  margin-right: auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fff;
  border: 1px solid #ff7200;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: #ff7200;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.condition-input {
  width: 98%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: white;
  color: black;
  outline: none;
}

.redHeght {
  height: 90%;
}

.dynamicField {
  min-width: 100px;
  height: 100%;
  border: none;
  border-bottom: 1px solid #ff7200;
  font-size: 0.8rem;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding: 5px;
  display: inline-block;
  overflow: hidden;
  appearance: textfield;
  white-space: nowrap;
  flex-wrap: wrap;
}

.dynamicField:read-write:focus {
  outline: none;
}

.inputVal {
  width: 100%;
  height: 37px;
  border: 1px solid silver;
  padding-left: 3px;
  border-radius: 4px;
  border-right: none;
}

.customDropDown {
  width: 100%;
  height: 100%;
  border: 1px solid silver;
  border-radius: 4px;
}

.btn_flex_div {
  width: 20%;
  height: 55px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.wd_30_btn {
  width: 50%;
  height: 30px;
  background-color: #fff;
  border-radius: 4px;
  border: none;
  margin-left: 0px;
  background-color: #ff7200;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
}

.wd_40_btn {
  width: 50%;
  height: 30px;
  border: none;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 10px;
  background-color: #ff7200;
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
}

.cdn-2 {
  width: 100%;
  height: auto;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.check-outer {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: white;
  border-left: 6px solid #ff7200;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 13%);
  margin-bottom: 10px;
}

.checkClause {
  width: 98%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  top: 0px;
  padding-top: 10px;
}

.check-1 {
  width: 4%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.check-1 span {
  color: black;
}

.check-2 {
  width: 8%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.check-2-new {
  width: 5.5%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.check-2Span {
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
}

.checkSpanChange {
  font-size: 0.75rem;
  color: #727376;
  font-weight: 900;
}

.check-3 {
  width: 82%;
  height: 100%;
  display: flex;
  position: relative;
}

.check-3Input {
  width: 100%;
  height: 100%;
  position: relative;
  top: 1px;
  border: 1px solid silver;
  background-color: rgb(0 0 0 / 1.9%);
  padding-left: 5px;
  font-size: 0.8rem;
  color: #888;
  border-radius: 4px;
}

.check-3Input-colored {
  width: 100%;
  height: 100%;
  position: relative;
  top: 1px;
  border: none;
  background-color: #fff;
  padding-left: 0px;
  font-size: 0.9rem;
  color: #727376;
  border-radius: 4px;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}

.CheckClausesInput {
  width: 300px;
  flex-wrap: wrap;
  height: 80%;
  position: relative;
  top: 50%;
  border: none;
  padding-left: 5px;
  border-bottom: 0.03rem solid black;
  transform: translateY(-50%);
  border-bottom: 2px solid #ff7200;
}

.CheckClausesInputNew {
  width: 220px;
  flex-wrap: wrap;
  height: 80%;
  position: relative;
  top: 50%;
  border: none;
  padding-left: 5px;
  border-bottom: 0.03rem solid black;
  transform: translateY(-50%);
  border-bottom: 0.03rem solid #000;
  color: #ff7200;
  font-weight: 700;
  background-color: #fff;
}

.check-4 {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkOptions {
  width: 94%;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 1px;
  /* background-color: red; */
}

.checkOptions {
  width: 100%;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 1px;
}

.moreWidth {
  width: 98%;
}

.roundDiv {
  width: 6px;
  height: 6px;
  background-color: #727376;
}

.checksubOptions {
  width: 92%;
  height: 80%;
  position: relative;
  top: 55%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.drip {
  width: auto;
  height: 35px;
}

.myCustomDrop {
  width: 120px;
  height: 100%;
  border: 1px solid silver;
  border-radius: 4px;
}

.dropSize {
  width: 100%;
  height: 38px;
}

.myCustomDrop:focus {
  outline: none;
}

.checkSub1 {
  width: 4%;
  height: 80%;
  top: 50%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(-50%);
}

.customcheck {
  width: 38%;
  height: 36%;
  cursor: pointer;
  border-top: 2.1px solid gray;
  border-right: 2.1px solid gray;
  border-left: 2.4px solid lightgray;
  border-bottom: 2.4px solid lightgray;
}

.checkSub2 {
  width: auto;
  height: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.checkSub2 input::placeholder {
  padding-left: 5px;
  color: black;
  font-size: 0.7rem;
}

.withBorder {
  width: 300px !important;
  height: 90% !important;
  border-radius: 4px;
  padding-left: 5px;
  border: 2px solid #ff7200 !important;
}

.checkSub3 {
  width: 4%;
  height: 80%;
  position: relative;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

/* **** View Contract **** */

.contract-div-1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ContractSection {
  width: 100%;
  height: auto;
  margin-bottom: 1px;
  padding-left: 10px;
  background-color: #f6f6f6;
  position: relative;
}

.btmBorder {
  background-color: #fff;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 7%);
  border-left: 4px solid #727376;
}

.contract-details {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid silver;
}
.newcontract-details {
  width: 100%;
  height: 270px;
  justify-content: space-between;
  border-bottom: 1px solid silver;
}

.contract-inner {
  width: 35% !important;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-right: none;
}

.contract-inner1 {
  width: 33.334% !important;
  height: 100%;
}

.wdth_60 {
  width: 66.6% !important;
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  border-right: none;
}

.contract-details:last-child {
  border-bottom: 1px solid #fff !important;
}

.contract-data1 {
  width: 33% !important;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.reduceWidth {
  width: 30% !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.contractDetailSpan1 {
  font-size: 0.75rem;
  color: #888 !important;
  font-weight: 700;
}

.contractDetailSpan2.cbdhide {
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
  border: none;
  background-color: transparent;
}
.contractDetailSpan2.bdhide {
  width: 60%;
  height: 30px;
  border-radius: 4px;
  padding-left: 3px;
  background-color: rgb(0 0 0 / 3%);
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
  border: 1px solid silver !important;
}
.contract-data2 {
  width: 61%;
  height: 100%;
  display: flex;
  align-items: center;
}

.moreInput {
  width: 83%;
  height: 100%;
  display: flex;
  align-items: center;
}

.mg-5 {
  padding-left: 10px;
}

.contract-details-new {
  width: 100% !important;
  height: 45px;
  display: flex;
}

.contract-inner-1 {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.contract-inner-2 {
  width: 68%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.contract-data1-new {
  width: 17.5%;
  height: 100%;
  display: flex;
  align-items: center;
}

.pd15 {
  position: relative;
  left: -7px;
}

.newColor {
  height: 130px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}

.newColorContract {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-left: 4px solid #727376;
  margin-top: 0px;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 7%);
}

.newHeight {
  height: 65px;
}

.contract-data2-new {
  width: 86%;
  height: 100%;
  display: flex;
  align-items: center;
}

.contract-div-2 {
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding-bottom: 20px;
}

.contractClauseHead {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contractClauseHead span {
  color: black;
  font-size: 1.2rem;
  font-weight: 900;
  padding-left: 5px;
}

.nonActiveBtnClass {
  width: 100%;
  height: 100%;
  border: 1px solid silver;
  cursor: pointer;
  background-color: #fff;
  color: #888;
  font-size: 0.85rem;
  font-weight: 700;
  position: relative;
}

.contractClausedata {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 3px;
  background-color: rgb(0 0 0 / 3%);
}

.clausesDiv {
  width: 98%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.clausesList {
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
}

.clauseRow1 {
  width: 96%;
  height: 50%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(0 0 0 / 4%);
  border-radius: 3px;
}

.colorOrangeText {
  color: #ff7200;
  font-size: 0.8rem;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}

.clauseinner-r1 {
  width: 4%;
  height: 100%;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.clauseNum {
  color: #000;
  font-size: 0.8rem;
  font-size: 700;
}

.clauseNumAstric {
  width: 20px;
  height: 20px;
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.clauseinner-r2 {
  width: auto;
  height: 100%;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
}

.clauseinner-r3 {
  width: 4%;
  height: 80%;
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  right: -50px;
}

.clauseText {
  font-size: 0.8rem;
}

.clauseRow2 {
  width: 100%;
  height: 50%;
}

.clauseEntry {
  width: 35%;
  height: 70%;
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-top: 14px;
  margin-bottom: 0px;
  margin-right: auto;
}

.clauseEntry1 {
  width: 23%;
  height: 25px;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid silver;
  border-radius: 3px;
}

.no-border {
  border: none;
}

.clauseEntry1 span {
  font-size: 0.9rem;
  color: #000;
}

.clauseEntry2 {
  width: 23%;
  height: 25px !important;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid silver;
  border-radius: 3px;
  background-color: rgb(0 0 0 / 5%);
  color: #000;
}

.customCheckbox[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 1px;
  height: 20px;
}

.customCheckbox[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid #ff7200;
  border-radius: 20px;
  background-color: #ff7200;
  transition: all 0.2s linear;
}

.customCheckbox[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid #ff7200;
  left: -6px;
  top: 4px;
  border-radius: 3px;
  background-color: #fff;
}

.customCheckbox[type="checkbox"]:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: solid #ffcb9a;
  border-width: 0 0px 0px 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 50px;
  transition: all 0.2s linear;
}

.customCheckbox[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 8px;
  height: 14px;
  border: solid #fff;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 7.5px;
  left: 1px;
}

.passColor {
  font-size: 0.9rem;
  color: green !important;
  font-weight: 700;
}

.failColor {
  font-size: 0.9rem;
  color: red !important;
  font-weight: 700;
}

.no-bdr {
  border: none;
  background-color: transparent;
}

.btn-size {
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 35%;
}

.clausesTable {
  width: 45%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 14%);
}

.clausesRow1 {
  width: 100%;
  height: 35px;
  display: flex;
}

.clausesH {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(0 0 0 / 14%);
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.clausesH:last-child {
  border-right: none;
}

.xl-50 {
  width: 50% !important;
}

.tblHeading {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

.clausesRowlist {
  width: 100%;
  height: 35px;
  display: flex;
}

.clausesListData {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(0 0 0 / 14%);
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.clausesRowlist:last-child {
  border-right: none;
  border-bottom: none;
}

.clauseValue {
  font-size: 0.8rem;
  color: #888;
}

/* TRANSPORTE MASTER DETAILED UI */
.TM-details {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  border: 1px solid rgb(0 0 0 / 9%);
  border-radius: 5px;
}

.TM-details-L {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
}

.TM-detail-tab {
  width: 97%;
  height: 45px;
  position: relative;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid rgb(0 0 0 / 9%);
}

.TM-detail-tab:last-child {
  border-bottom: none;
}

.Tm-detail-C1 {
  width: 25%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
}

.tr-span1 {
  font-size: 0.8rem;
  color: #000;
  font-weight: 700;
}

.Tm-detail-C2 {
  width: 75%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
}

.tr-span2 {
  font-size: 0.85rem;
  color: #ff7200;
}

.uploadIn {
  font-size: 16px;
  color: #727376;
  margin-left: 20px;
  cursor: pointer;
}

.TM-details-R {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border-left: 2px dashed rgb(0 0 0 / 12%);
}

/* ADDRESS DETAIL DETAILED UI */
.AddressDetailMain {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #dcdcdc;
  border-bottom: none;
  border-radius: 3px;
  position: relative;
}

.AddressDetailMainSpans {
  position: absolute;
  top: -10px;
  left: 10px;
  color: #fff;
  background-color: #727376;
  font-weight: 700 !important;
  font-size: 1rem;
  padding-left: 5px;
  padding-right: 5px;
}

.noBrdrTop {
  border-top: none;
  border-bottom: 1px solid #dcdcdc;
}

.addressDetails-L {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
}

.add-Details-tab {
  width: 97%;
  height: 55px;
  position: relative;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid #dcdcdc;
  background-color: #f0f0f0;
  padding-left: 10px;
}

.add-Details-tab:last-child {
  border-bottom: none;
}

.add-detail-c1 {
  width: 25%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: flex-start;
}

.add-detail-span1 {
  font-size: 0.8rem;
  color: #000;
  font-weight: 600;
}

.add-detail-c2 {
  width: 75%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
}

.add-detail-span2 {
  width: 300px !important;
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  color: #ff7200;
  font-weight: 700;
}

.add-detail-span2.underline {
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  color: #ff7200;
  font-weight: 700;
  width: 70% !important;
  border: 1px solid rgb(0 0 0 / 8%);
}

.addressDetails-R {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  border-left: 2px dashed rgb(0 0 0 / 12%);
}

/* HUBS DETAILS */
.hubsDetailedDiv {
  width: 100%;
  height: 400px;
}

/* Table redisgn */
.btn_row_1 {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 8px 6px -6px rgb(0 0 0 / 15%);
}

.btn_row_2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.myBtnSize {
  width: auto;
  height: 35px !important;
}

.no-mg-pd {
  margin: 0px !important;
  padding: 0px !important;
}

.bg-change > div {
  background-color: #f5f5f5 !important;
}

.size_hgt {
  font-size: 0.8rem;
  height: 28px;
}

.sr-no {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ff7200;
}

.sr-no span {
  font-size: 0.72rem;
  font-weight: 700;
  color: #fff;
}

.trans {
  background-color: transparent !important;
}

.expand-tbl {
  display: none;
  font-size: 1rem;
  color: #ff7200;
  cursor: pointer;
}

.expand-tbl.open {
  font-size: 1rem;
  color: #ff7200;
  cursor: pointer;
  display: block;
}

.contract-tbl {
  font-size: 1rem;
  color: #ff7200;
  cursor: pointer;
}

.contract-tbl.hide {
  display: none;
}

.align-cent {
  height: 60px;
  position: relative;
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.5rem;
}

.set-hght {
  height: 41px;
}

.set-new-hght {
  height: 65px;
}

.inc-hgt {
  height: 36px;
}

.data-cont-item .align-div {
  position: relative;
  display: flex;
  justify-content: space-between !important;
}

.data-cont-item .pad-0 {
  padding: 0px !important;
}

.data-cont-item .pad-top-0 {
  padding-top: 0px !important;
}

.clip-path {
  clip-path: polygon(0 0, 94% 0%, 100% 100%, 0% 100%);
}

.display-col {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tbl-hd-1 {
  font-size: 0.8rem !important;
  padding-right: 10px;
}

.tbl-hd-2 {
  font-size: 0.9rem !important;
  color: #ff7200 !important;
  padding-right: 10px;
}

.tbl-hd-3 {
  font-size: 1rem !important;
}

.bg-trans {
  width: 340px !important;
  display: flex;
  /* justify-content: space-around; */
  background-color: transparent !important;
  flex-wrap: wrap;
  clip-path: polygon(3% 0%, 100% 0, 100% 100%, 0% 100%);
}

.dateTime {
  width: 22%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-left: 5px;
  overflow: visible;
}

.date {
  width: 38%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.time {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.dateTime span {
  color: #888;
}

.date span {
  color: #727376 !important;
  font-size: 0.8rem;
  font-weight: 700;
}

.time span {
  color: #727376 !important;
  font-size: 0.8rem;
  font-weight: 700;
}

.xl {
  font-size: 0.95rem;
}

.ct-input input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

.calenderInput {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 0.8rem;
  cursor: pointer;
  color: #000;
  z-index: 1;
}

/* driver detail redesigned */

.DriverDetail-1 {
  width: 100%;
  height: auto;
  position: relative;
  border: 1px solid rgb(0 0 0 / 17%);
  padding-bottom: 7px;
}

.driverHead {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(116, 115, 115);
  background-color: #ff7200;
  margin-bottom: 7px;
}

.driverHead span {
  padding-left: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.driverContent {
  width: 100%;
  height: auto;
  display: flex;
}

.driver-l {
  width: 70%;
  border-right: 1px solid rgb(0 0 0 / 15%);
}

.driverRow {
  width: 98%;
  height: 35px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}

.driverRow:last-child {
  border-bottom: none;
}

.driverc-1 {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.driverSpan1 {
  font-size: 1rem;
  color: #888;
  position: relative;
}

.driverc-2 {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  border-left: 1px dashed silver;
}

.driverSpan22 {
  font-size: 0.8rem;
  color: #ff7200;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.driverSpan2 {
  width: 70%;
  font-size: 0.9rem;
  color: #ff7200;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
  position: relative;
  border: none;
}

.driverSpan2.underline {
  background-color: #fff;
  border: 1px solid silver;
  margin-left: 10px;
  border-radius: 4px;
}

.spanUnderline {
  color: #727376;
  cursor: pointer;
  text-decoration: underline;
}

.spanUnderline:hover {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.fileUpload {
  color: #727376;
  cursor: pointer;
  position: relative;
  left: 20px;
}

.driver-r {
  width: 30%;
  height: 250px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.driver-img-div {
  width: 60%;
  height: 100%;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 1px dashed rgb(0 0 0 / 17%);
}

.userImg {
  width: 60%;
  height: 65%;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.moreSize {
  width: 60%;
  height: 25% !important;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.uploadFile {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  opacity: 0;
  z-index: 10;
}

.uploadIcon {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 18px;
  cursor: pointer;
  color: #000;
  opacity: 10;
  background-color: #fff;
  z-index: 0;
}

.DriverDetail-2 {
  width: 100%;
  height: auto;
  position: relative;
  border: 1px solid rgb(0 0 0 / 17%);
  padding-bottom: 7px;
  margin-top: 20px;
}

.driver-r1 {
  width: 30%;
  height: 200px;
}

.driverDataElem {
  width: 90%;
  height: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  border: 1px dashed rgb(0 0 0 / 17%);
}

.liceneseImg {
  width: 70%;
  height: 90%;
  display: flex;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.DriverDetail-3 {
  width: 100%;
  height: auto;
  position: relative;
  border: 1px solid rgb(0 0 0 / 17%);
  padding-bottom: 7px;
  margin-top: 20px;
}

.multiDocDiv {
  width: 100%;
  height: auto;
}

.multiDocOtions {
  width: 100%;
  height: 50px;
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
}

.doc-type {
  width: 35%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.selectDiv {
  width: 55%;
  height: 100%;
}

.docSpans {
  font-size: 0.8rem;
  font-weight: 700;
}

.docUpload {
  width: 37%;
  height: auto;
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}

.uploadimgBtn {
  width: 17%;
  height: 34px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-weight: 700;
}

.hideInputFile {
  position: absolute;
  top: 0px;
  opacity: 0;
  cursor: pointer;
}

.myDocUpload {
  font-size: 19px;
  margin-left: 35px;
  cursor: pointer;
}

.loadingSpinner {
  color: #ff7200;
  margin-left: 12px;
}

.doc-btn {
  width: 25%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
}

.addDocBtn {
  width: 40%;
  height: 26px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-right: 15px;
}

.uploadDiv {
  width: 95%;
  height: auto;
  display: flex;
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.pictureDisplay {
  width: 110px;
  height: 130px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin-right: 30px;
  margin-bottom: 35px;
}

.picturePreview {
  width: 90%;
  height: 82%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.picturePreview:hover {
  width: 91%;
  height: 83%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.picturePreview img {
  width: 90%;
  height: 80%;
}

.closeTab {
  position: absolute;
  top: -12px;
  font-size: 15px;
  right: -7px;
  cursor: pointer;
}

.pictureName {
  width: 100%;
  height: auto;
  color: #000;
  position: absolute;
  bottom: -36px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.fileSize {
  font-size: 0.7rem;
  color: grey !important;
}

.med-size {
  height: 15px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid #888;
  position: absolute;
  z-index: 9999;
  bottom: 2px;
  right: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.downloadDocBtn {
  height: 15px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid #888;
  position: absolute;
  z-index: 9999;
  bottom: 2px;
  left: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.myImage {
  width: 100%;
  height: 310px;
  display: flex;
  flex-direction: column;
}

.myImage img {
  width: auto;
  height: 90%;
}

.docName {
  margin-top: 2px;
  color: #000;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 700;
  font-size: 0.8rem;
}

.myImageDownload {
  width: 100%;
  height: auto;
}

.myImageDownload button {
  width: 99%;
  height: 34px;
  background-color: #ff7200;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.imgProps {
  width: auto;
  height: 90%;
  position: relative;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.DriverDetail-4 {
  width: 65%;
  height: 60px;
  position: relative;
  padding-bottom: 7px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.btn-size {
  width: 34%;
  height: 30px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.gateIn {
  padding-top: 1px;
  padding-right: 5px;
}

/* Workforce Modal */
.toggle_width {
  width: 30%;
}

.workForceDiv {
  width: 100%;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.wfOuter {
  width: 100%;
  height: 90px;
  display: flex;
}

.workForceRow {
  width: 50%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.workForceRow1 {
  width: 50%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wFInnerrow1 {
  width: 50%;
  height: 90px;
}

.wFInnerrow1 span {
  font-size: 0.9rem;
  color: #000;
  font-weight: 700 !important;
  cursor: pointer;
}

.wFInnrow2 {
  width: 50%;
  height: 90px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.driverIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.wFInnerrow3 {
  width: 50%;
  height: 90px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.wFInnerrow2 {
  width: 50%;
  height: 100%;
}

.WFI0 {
  width: 20%;
  height: 100%;
}

.WFI1 {
  width: 20%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: green;
}

.WFI2 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.WFI2 span {
  font-size: 1.1rem;
  font-weight: 700;
  color: #000;
}

.WFI3 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
}

.wfBtn {
  width: 40%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ff7200;
  border-radius: 4px;
  border: 1.3px solid #ff7200;
  color: #fff;
  cursor: pointer;
}

/* add Workforce UI */
.staffElem {
  width: 100%;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.staffElemRow {
  width: 100%;
  height: 80px;
  position: relative;
  top: 20px;
  display: flex;
}

.driverrow1 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dlNum1,
.dlNum2 {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.staffInpNew {
  width: 220px;
  height: 36px;
  padding-left: 5px;
  margin-left: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid silver;
}

.dlNum3 {
  width: 38px;
  height: 74%;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7200;
}

.racket {
  color: #fff;
  font-weight: 1rem;
}

.driverrow2 {
  width: 100%;
  height: auto;
  display: none;
}

.driverrow2.open {
  width: 100%;
  height: 200px;
  position: relative;
  display: block;
  animation: mymove 0.2s;
  animation-timing-function: ease-in;
  border-radius: 6px;
}

@keyframes mymove {
  from {
    height: 0px;
  }

  to {
    height: 200px;
  }
}

.driverThirdOuter {
  width: 100%;
  height: auto;
  display: flex;
  padding: 15px;
  position: relative;
  top: 20px;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 3%);
  border-radius: 5px;
}

.thirdDataR {
  width: 30%;
  height: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.driverInpHgt {
  height: 40px;
}

.brdrBtm {
  padding-bottom: 40px;
}

.staffElemCol {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
}
.staffElemColl {
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
}
.staffElemColl > div {
  background-color: #fff;
}
.makeAbsDriver {
  position: absolute;
  top: 21px;
}
.makeAbs {
  position: absolute;
  top: 25px;
}
.absPostioning {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.staffSpan {
  font-size: 0.8rem;
  color: black;
  font-weight: 600;
}

.staffInp {
  width: 100%;
  height: 32px;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
  position: relative;
}

.staffInp::placeholder {
  font-size: 0.7rem;
  color: #888;
  padding-left: 5px;
}

.validationCheck {
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: green;
  font-size: 0.9rem;
  cursor: pointer;
}

.validationCheckWrong {
  position: absolute;
  bottom: 13px;
  right: 10px;
  color: red;
  font-size: 0.9rem;
}

.validationCheckn1 {
  position: absolute;
  bottom: 15px;
  right: 10px;
  color: green;
  font-size: 0.9rem;
  cursor: pointer;
}

.validationCheckWrongn1 {
  position: absolute;
  bottom: 15px;
  right: 10px;
  color: red;
  font-size: 0.9rem;
}

.validationCheckNew {
  position: absolute;
  bottom: 9px;
  right: 10px;
  color: green;
  font-size: 0.9rem;
  cursor: pointer;
}

.validationCheckWrongNew {
  position: absolute;
  bottom: 9px;
  right: 10px;
  color: red;
  font-size: 0.9rem;
}

.setPostCheck {
  position: absolute;
  bottom: 18px;
  right: 10px;
  color: green;
  font-size: 0.9rem;
}

.setPostWrongCheck {
  position: absolute;
  bottom: 18px;
  right: 10px;
  color: red;
  font-size: 0.9rem;
}

.user-calendaNew {
  position: absolute;
  bottom: 17px;
  right: 10px;
}

.user-calendaNewDriver {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.checkRadio {
  display: flex;
  margin-bottom: 7px;
  /* flex-direction: column; */
}

.staffElembtnCol {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: auto;
}

.algnCent {
  display: flex;
  align-items: center;
}

/*  */
.sliderBtn {
  width: 100%;
  height: 38px;
  display: flex;
  padding: 2px;
  border-radius: 4px;
}

.driverBtn {
  width: 50%;
  height: 94%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  /* background: #e4e4e4; */

  color: black;
  background: #f6f6f6;
}

.staffBtn {
  width: 50%;
  height: 94%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
  background: #f6f6f6;
}

.inactiveWFBtn {
  background: #c7c6c6;
  color: black;
}

.activeWFBtn {
  font-size: 0.8rem;
  font-weight: 700;
  color: #ff7200;
  background: #f6f6f6 !important;
  border-bottom: 3px solid #ff7200 !important;
}

.driverElem {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wfSpan {
  font-size: 1.2rem !important;
  color: #ff7200 !important;
  padding-left: 5px;
}

/* Vaccination certificate ui */
.VaccineMob {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.VaccineMob.hide {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vaccineRow {
  width: 100%;
  height: 55px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: start;
  align-items: flex-start;
}

.vaccineRow.hide {
  display: none;
}

.justify-end {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: start;
  align-items: flex-end;
  position: relative;
}
.just-end {
  justify-content: flex-end;
}
.vaccine_span1 {
  font-size: 0.9rem;
  position: relative;
  bottom: 15px;
  font-weight: 600;
}

.vaccineField {
  width: 89%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid silver;
  padding-left: 10px;
}

.vaccineField::placeholder {
  color: silver;
  padding-left: 5px;
  font-size: 0.85rem;
}

.heightXl {
  height: 43px;
  border: 1px solid #ff7200;
}

.overlapCode {
  position: absolute;
  top: -8px;
  left: 42px;
  background-color: #fff;
  color: #ff7200;
}

.vaccineotpbtn {
  width: 89%;
  height: 38px;
  border: none;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 4px;
  letter-spacing: 0.1rem;
  cursor: pointer;
  background-color: #ff7200;
}

.resendText {
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

.VaccineNum {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.success_text {
  color: green;
  font-weight: 600;
}

/* View document btn hover part */

.myBtn .titleBox {
  width: 150px;
  height: auto;
  padding: 5px;
  background-color: #696c74;
  position: absolute;
  top: 72px;
  border-radius: 4px;
  z-index: 9999999999;
  display: none;
}

.myBtn .titleBox::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 7px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #696c74;
  overflow: hidden;
}

.btn_row_1 > .myBtn:hover > .titleBox {
  width: 150px;
  height: auto;
  padding: 0px;
  background-color: #696c74;
  color: #666666;
  position: absolute;
  top: 26px;
  border-radius: 4px;
  z-index: 9999999999;
  display: block !important;
}

.docOptions {
  width: 100%;
  height: 25px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #696c74;
  border: 1px solid #fff;
}

.docOptions:last-child {
  margin-bottom: 0px;
}

.docOptions span {
  color: #fff;
  font-size: 0.8rem;
}

.docOptions:hover {
  width: 100%;
  height: 25px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #565e64;
}

/* mul docs UI */
.bg_orange {
  background: #ff7200;
  color: #fff;
}

table.no_brdr,
.no_brdr td,
.no_brdr tr,
.no_brdr th {
  text-align: center;
  border-collapse: collapse;
  border: none !important;
}

.bookingDetailDiv {
  width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid #ff7200;
}

.noDataFound {
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataFound span {
  font-size: 1.2rem;
}

.children .children1 {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid #eaecf0;
  background-color: #f6f7f8;
  transition: all 0.2s ease-out 0s;
}

.children > li.active .children1 {
  display: block !important;
  border-top: 0;
  border-bottom: 1px solid #eaecf0;
}

.children .children1 {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid #eaecf0;
  background-color: #f6f7f8;
  transition: all 0.2s ease-out 0s;
}

.children > li.active .children1 {
  display: block;
  border-top: 0;
  border-bottom: 0px solid #eaecf0;
}

.children > li.active .children1:before {
  border-top: 7px solid #ff7200;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 0;
}

.menu-nav > li.active .no-triangle:before {
  border-top: 10px solid #ff7200;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;

  top: 0;
}

.children .children1 > li {
  margin-left: 21px;
  border-left: 1px solid #dbdfe6;
  font-family: GOTHIC;
  text-transform: none;
  letter-spacing: normal;
  display: block;
  font-size: 12px;
}

.children .children1 > li:first-child {
  padding-top: 7px;
}

.children .children1 > li > a {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 5px 0 5px 12px;
  color: #657390;
  position: relative;
  height: 28px;
  transition: all 0.2s ease-out 0s;
  font-size: 9px;
}

.children .children1 > li.active > a {
  color: #ff7200 !important;
}

.children .children1 > li > a:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: -3px;
  top: 50%;
  background-color: #9fa8bc;
  margin-top: -3px;
  border-radius: 50px;
  transition: all 0.2s ease-out 0s;
}

.children1 > li.active > a:before {
  background-color: #ff7200;
}

.children .children1 > li > a:after {
  content: "";
  width: 30px;
  height: 30px;
  border: 1px solid #a0a8b9;
  position: absolute;
  left: -15px;
  top: 50%;
  margin-top: -15px;
  border-radius: 50px;
  opacity: 0;
  transition: all 0.2s ease-out 0s;
}

.children .children1 > li.active > a:after {
  opacity: 1;
  border-color: #ff7200;
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.children .children1 > li > a:hover:before {
  background-color: #ff7200 !important;
}

.children .children1 > li > a:hover:after {
  opacity: 1;
  border-color: #ff7200;
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.children .children1 > li > a:hover {
  text-decoration: none;
  color: #ff7200;
}

.cust-dropdown {
  width: 100%;
  height: 34px;
  border: 1px solid silver;
  border-radius: 4px;
}

.categoryDiv {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.categoryDiv1 {
  width: 90%;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.categorySpan {
  font-size: 0.9rem;
  color: #000;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.categoryInput {
  width: 100%;
  height: 36px;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 5px;
  font-size: 0.7rem;
  color: #888;
}

.categoryInput::placeholder {
  padding-left: 5px;
  font-size: 0.7rem;
  color: #888;
}

.categoryDiv2 {
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid rgb(0 0 0 / 9%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.saveCategory {
  width: 21%;
  height: 28px;
  background-color: #ff7200;
  color: #fff;
  font-size: 0.9rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.overlapCategoryCode {
  position: absolute;
  top: 31px;
  left: 12px;
  background-color: #fff;
  color: #ff7200;
}

.contarctModal {
  width: 100%;
  height: auto;
}

.file_preview > .file_preview_cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.file_preview > .file_preview_cont > .preview_itm {
  width: calc(20% - 10px);
  height: 110px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border: 1px solid #e3e3e3;
}

.file_preview {
  padding: 10px;
}

.file_preview > p {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.file_preview > .file_preview_cont > .preview_itm > img {
  max-width: 98%;
  max-height: 98%;
  opacity: 1;
  cursor: pointer;
}

.file_preview > .file_preview_cont > .preview_itm > img:hover {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}

.imgContainer {
  width: 90%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
}

.setHgt {
  width: 100%;
  height: auto !important;
}

.imgContainer1 {
  width: 90%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
}

.myImgSize {
  width: 100%;
  height: 400px !important;
}

.overSpanName {
  position: absolute;
  bottom: 0px;
  font-size: 0.5rem;
  color: #000;
}

.post-btm {
  margin-top: 22px;
}

.downloadContractdoc {
  width: 100%;
  height: 40px;
  margin-top: 14px;
  border-radius: 7px;
  background-color: red;
}

.downloadContractdoc button {
  width: 100%;
  height: 100%;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  font-size: 1rem;
}

.imgContainer img {
  width: 100%;
  height: 100%;
}

.file_preview > .file_preview_cont > .preview_itm > .closeb {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #e3e3e3;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.downloadIcon {
  color: grey;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  bottom: 2px;
  left: 4px;
}

.previewDiv {
  width: 90%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(0 0 0 / 11%);
}

.previewDiv img {
  width: 70%;
  height: 90%;
}

.closeIcon {
  position: absolute;
  top: -11px;
  right: -8px;
  font-size: 22px;
  cursor: pointer;
  opacity: 1;
  z-index: 10;
}

.font-size {
  font-size: 1rem;
  color: grey;
}

.transporterPlant {
  width: 100%;
  height: auto;
}

.transporterPlantInner {
  width: 100%;
  /* height: 170px; */
  display: flex;
  flex-direction: column;
}

.togglabelDiv {
  width: 100%;
  height: 33px;
  display: flex;
}

.inches {
  height: 36px !important;
}

.togglebTns {
  width: 380px;
  height: 100%;
  display: flex;
  background-color: #fff;
  /* border: 0.01rem solid silver; */
  border-radius: 5px;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 8%);
}

.reduceWidth {
  width: auto !important;
  background-color: #f6f6f6;
}

.contractDetailSpan3 {
  font-size: 0.75rem;
  color: #888 !important;
  font-weight: 700;
}

.contractDetailSpan4.cbdhide {
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
  width: 90%;
  border: none !important;
  background-color: transparent !important;
}
.contractDetailSpan4.bdhide {
  width: 60%;
  height: 30px;
  border-radius: 4px;
  padding-left: 3px;
  background-color: rgb(0 0 0 / 3%);
  font-size: 0.75rem;
  color: #000;
  font-weight: 700;
  border: 1px solid silver !important;
}
.contracBtn {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  color: #fff;
  letter-spacing: 0.4px;
  border-radius: 2px;
  border-bottom-left-radius: 0px;
  background-color: #727376;
}

.transporterBtn {
  width: 50%;
  height: 100%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
  background: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-left: 4px solid #727376;
}

.plantBtn {
  width: 50%;
  height: 100%;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
  border-radius: 4px;
  background: #fff;
  border-left: none;
  background-color: #fff;
  border-top-left-radius: 4px;
}

.activeTPBtn {
  width: 50%;
  height: 100%;
  font-size: 0.8rem;
  color: #fff;
  background: #727376;
  border: none;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 14%);
  letter-spacing: 0.5px;
  border-radius: 2px;
}

.multBtnElem {
  width: 100%;
}

.font-clr {
  color: #888 !important;
}

.iconElem {
  width: 72px;
  display: flex;
}

.inspectionDiv {
  width: 100%;
  height: 220px;
}

.MaterialDiv {
  width: 100%;
  min-height: 80px;
}

.inspectionHeaderElem {
  width: 100%;
  height: 50px;
  display: flex;
}

.inspecH2 {
  width: 98%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e8e8;
}

.inspectVeNum {
  color: #000;
  font-size: 0.75rem;
  font-weight: 700;
}

.inspectVehcValue {
  color: #ff7200;
  font-size: 0.8rem;
  text-decoration: underline;
  text-underline-offset: 5px;
  font-weight: 700;
}

.inspectIn {
  width: 70px;
  margin-left: 11px;
  height: 29px;
  border: none;
  border-bottom: 1px solid #000;
  color: #ff7200;
  font-weight: 700;
  text-align: center;
}

.inspectHeaderElem1 {
  width: 32%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.inpectionInner1 {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* padding-top: 10px; */
}

.inpectionHeader {
  width: 100%;
  height: 40px;
  display: flex;
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  border: 1px solid rgb(0 0 0 / 20%);
  border-right: none;
  border-left: none;
}

.inpectBox1 {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inpectBox2 {
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inpectBox11 {
  width: 8%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inpectBox22 {
  width: 19%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inpectBox33 {
  width: 44%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inpectHeadSpan {
  font-size: 0.85rem;
  color: #000;
  font-weight: 700;
}

.inpectHeadSpanOrange {
  font-size: 0.85rem;
  color: #ff7200 !important;
  font-weight: 700;
}

.fontCCL {
  font-size: 0.7rem !important;
}

.inspectBody {
  width: 100%;
  height: 45px;
  display: flex;
  /* border-bottom: 1px solid rgb(0 0 0 / 8%); */
  border-right: 1px solid rgb(0 0 0 / 14%);
}

.inspectBody:last-of-type {
  border-bottom: none;
}

.inpectHeadBody {
  color: #000;
  font-size: 0.87rem;
  font-weight: 400;
}

.inspectPass {
  color: green;
  font-weight: 700;
}

.inspectFail {
  color: red;
  font-weight: 700;
}

.inspectButtons {
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inspectAccept {
  width: 17%;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  border: none;
  background: green;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.inspectFailBtn {
  width: 17%;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  border: none;
  background: red;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

/* Input clause */

.inputClauseDiv {
  height: 100px;
  display: inline-block;
  overflow: auto;
  display: flex;
  margin-left: 13px;
}

.hideMgRgt {
  visibility: hidden;
  margin-right: 5px !important;
}

.inputClauseDiv-1 {
  align-items: center;
  display: flex;
}

.inputText {
  min-width: 100px;
  max-width: 100%;
  height: 31px;
  border-radius: 4px;
  border: 1px solid silver;
  padding-left: 0px;
}

.inputClauseFields {
  width: 200px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.inpField {
  width: 50%;
  height: 31px;
  border-radius: 4px;
  border: 1px solid silver;
  border-right: none;
}

.selectField {
  width: 50%;
  height: 31px;
  border-radius: 4px;
  border: 1px solid silver;
}

.inputSecondArea {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-right: 10px;
}

.inputClauseDiv-1 {
  align-items: center;
  display: flex;
}

.inputClauseDiv1 {
  width: 100%;
  height: 100px;
  flex-wrap: wrap;
}

.previewInputElem {
  width: 100%;
  height: auto;
  padding: 7px;
  padding-left: 15px;
  margin-bottom: 15px;
  margin-top: 20px;
}

.previewInputInner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.previewItems1 {
  width: fit-content;
}

.previewInputWords {
  font-size: 0.8rem;
  margin-right: 5px;
}

.previewValues {
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 3px;
  margin-right: 10px;
  color: #ff7200;
}

.inputClauseDiv::-webkit-scrollbar {
  height: 6px;
  width: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.inputClauseDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
  border-radius: 10px;
}

.inputClauseDiv::-webkit-scrollbar-thumb:horizontal {
  height: 20px;
  background: #ff7200;
  border-radius: 10px;
  cursor: pointer;
}

.myStaffDrop {
  height: 35px;
  border-radius: 4px;
  border: 1px solid silver;
}

.css-1oy5eth-ReactDropdownSelect {
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #ccc;
  width: 100% !important;
  border-radius: 2px;
  padding: 2px 5px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  direction: ltr;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  min-height: 36px !important;
  pointer-events: all;
  outline: none !important;
  background-color: #fff !important;
}

.css-cvzi4l-DropDown {
  position: absolute;
  top: 43px;
  left: -1px;
  border: 1px solid #ccc;
  width: 290.51251220703125px;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  max-height: 300px;
  overflow: auto;
  z-index: 999999999999 !important;
}

.css-1oy5eth-ReactDropdownSelect:hover,
.css-1oy5eth-ReactDropdownSelect:focus-within {
  border-color: silver !important;
  outline: none !important;
}

.css-1oy5eth-ReactDropdownSelect:focus,
.css-1oy5eth-ReactDropdownSelect:focus-within {
  outline: 0 !important;
  box-shadow: 0 0 0 0px rgb(255 114 0 / 0%) !important;
}

.css-1njzwg6-InputComponent::placeholder {
  border: none;
  /* background: red !important; */
  padding: 0;
  font-size: 0.75rem;
  color: #727376 !important;
}

.css-1oy5eth-ReactDropdownSelect::placeholder {
  border: none;
  /* background: red !important; */
  padding: 0;
  font-size: 0.75rem;
  color: #727376 !important;
}

.addHubBtn {
  width: 17%;
  height: auto;
  margin-top: 26px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

/*  */
.cust-col-6 .titleBoxnew {
  width: 80px;
  height: 28px;
  padding: 5px;
  background-color: #727376;
  position: absolute;
  top: 45px;
  /* right: 15px; */
  border-radius: 4px;
  z-index: 9999999999;
  display: none;
}

.titleBoxnew::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: -7px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #727376;
  overflow: hidden;
}

.cust-col-6 > .button:hover > .titleBoxnew {
  width: 80px;
  height: 28px;
  padding: 5px;
  background-color: #727376;
  color: #666666;
  position: absolute;
  top: 45px;
  border-radius: 4px;
  z-index: 9999999999;
  display: block !important;
}

.titleBoxnew span {
  color: #fff;
  font-size: 0.7rem;
}

/* new contract design */
.newContractDesign {
  width: 100%;
  height: auto;
  /* background-color: rgb(0 0 0 / 8%); */
}

.userTable {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.userRow {
  width: 100%;
  height: 42px;
  display: flex;
  background-color: #ff7200;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 12px rgb(0 0 0 / 15%);
}

.userR1 {
  width: 4%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
}

.userspan {
  color: #fff;
  font-weight: 700;
}

.userR2 {
  width: 16%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
  text-align: center;
}

.userDynamicRow {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #fff;
  margin-top: 5px;
  box-shadow: 1px 2px 12px rgb(0 0 0 / 15%);
}

.hgt4 {
  width: 4%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(0 0 0 / 8%);
}

.hgt100 {
  width: 16%;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 1px solid rgb(0 0 0 / 8%);
  flex-wrap: wrap;
  /* overflow: auto; */
}

.roundSpan {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff7200;
}

.txtColored {
  color: #ff7200 !important;
  font-weight: 700 !important;
  font-size: 0.9rem;
}

.normaltxt {
  color: grey !important;
  font-weight: 700 !important;
  font-size: 0.6rem;
}

.justItem {
  display: flex;
  /* padding: 25px; */
  justify-content: space-evenly !important;
}

.userEye {
  cursor: pointer;
  font-size: 1rem;
  color: #ff7200;
}

.black {
  font-size: 0.7rem;
  color: #000;
}

.userEdit {
  cursor: pointer;
  font-size: 1rem;
  color: green;
}

.userCross {
  cursor: pointer;
  font-size: 1.1rem;
  color: red;
}

.paginationDiv {
  width: 25%;
  height: 40px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  right: 15px;
  bottom: 0px;
  display: flex;
  align-items: flex-end;
  float: right !important;
  justify-content: flex-end;
  align-items: center !important;
}

.paginateNext {
  width: auto;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  margin-right: 20px;
}

/*  */
.userHeading {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.userHeading span {
  display: flex;
  font-size: 1.3rem;
  color: #000;
  font-weight: 900;
  align-items: left !important;
  text-decoration: underline;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.addUserDiv {
  width: 95%;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addUserHeight {
  height: 130px !important;
}

.defHgt {
  height: 280px;
}
.incremented {
  height: 280px !important;
}
.addUserRow {
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-between;
}

.addUserInner {
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
}

.addUserInner > span {
  font-size: 0.75rem;
  font-weight: 700;
}

.addUserInner input {
  width: 90%;
  height: 39px;
  padding-left: 5px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

.addBtnAbs {
  width: auto;
  height: 17px;
  background-color: green;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  top: -1px;
  right: 27px;
  font-size: 0.6rem;
}

.allocateMap {
  width: 93px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: 24px;
}

.addUserInner select {
  width: 95%;
  height: 35px;
  padding-left: 5px;
  border: 1px solid silver;
  border-radius: 4px;
}

input::placeholder {
  padding-left: 5px;
  color: grey;
  font-size: 0.7rem;
}

.mgTop {
  margin-top: 19px;
  height: 180px;
}

.bgclr {
  background-color: rgb(0 0 0 / 2%);
  border-radius: 7px !important;
}
.dynamicPermisiions {
  height: auto;
  flex-wrap: wrap;
}

.flexrow {
  display: flex;
  justify-content: space-between;
}

.selectAll {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  position: relative;
}

.selectText {
  color: #727376 !important;
  font-size: 0.9rem !important;
  margin-right: 49px;
}

.toggleBtns {
  width: 20%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.toggleBtns span {
  color: #000;
  font-weight: 600;
}

.switchWidth {
  position: relative;
  display: flex;
  align-items: center;
  width: 55px;
  height: 23px;
  margin-left: auto;
  margin-right: auto;
}

.switchWidth input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchWidth {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px !important;
}

.switchWidth input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderNew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderNew:before {
  position: absolute;
  content: "";
  height: 12.5px;
  width: 12.5px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderNew {
  background-color: #ff7200 !important;
}

input:focus + .sliderNew {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderNew:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.sliderNew.round {
  border-radius: 34px;
}

.sliderNew.round:before {
  border-radius: 50%;
}

.createUserBtn {
  width: 100%;
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 24px;
}

.createUserBtn button {
  width: 110px !important;
  height: 33px !important;
  font-size: 0.85rem !important;
}

.customCheckbox1[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 1px;
  height: 20px;
  margin-left: 12px;
}

.customCheckbox1[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  border: 3px solid #ff7200;
  border-radius: 20px;
  background-color: #ff7200;
  transition: all 0.2s linear;
}

.customCheckbox1[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  border: 3px solid #ff7200;
  left: 20px;
  top: 0px;
  border-radius: 3px;
  background-color: #fff;
}

.customCheckbox1[type="checkbox"]:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: solid #ffcb9a;
  border-width: 0 0px 0px 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 50px;
  transition: all 0.2s linear;
}

.customCheckbox1[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3.3px;
  left: 27.4px;
}

.loaderDiv {
  width: 10%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 50vh;
}

.loaderDivNone {
  display: none;
}

.noDataClass {
  font-size: 1.2rem;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 65vh;
  top: 50%;
}

.flexRow {
  display: flex;
}

.profileChange {
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 11px;
  position: relative;
  top: 1px;
  border-radius: 0px !important;
}

/* registration form */
.registrationElem {
  width: 100%;
  height: 100vh;
  display: flex;
}

.registrationL {
  width: 45%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regOver {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
}

.registrationL span {
  color: #fff;
  font-size: 3.4rem;
  font-weight: 800;
}

.registrationL img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.registrationR {
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.regInner {
  width: 85%;
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.regRow {
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.regHeading {
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}

.regSelect {
  width: 100% !important;
  position: relative;
}

.brdrOrange {
  border: 1px solid #ff7200 !important;
  border-radius: 5px !important;
}

.regIn {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #ff7200;
  padding-left: 5px;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
}

.regIn::placeholder {
  padding-left: 5px;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
}

.alignRow {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
}

.reg3 {
  width: 31%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.reg50 {
  width: 47%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.alignCen {
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.registerNowBtn {
  width: 24%;
  height: 35px;
  background-color: #ff7200;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  color: #fff;
  font-size: 0.85rem;
  margin-bottom: 7px;
}

.regUpload {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 1rem;
  color: #ff7200;
  cursor: pointer;
  border: none;
}

.registerLink {
  color: #000;
  font-size: 1rem;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.registerBack {
  width: 5%;
  height: 32px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  right: 66px;
}

.newregisterBack {
  width: 4%;
  height: 26px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  right: 50%;
}

.highlightText {
  color: #ff7200;
  font-weight: 800;
  position: relative;
  top: 10px;
  right: 6px;
  padding: 5px;
  font-size: 1rem;
  border-radius: 2px;
  text-decoration: underline;
  text-underline-offset: 3px;
  /* background-color: silver; */
}

.brdrNone {
  border-top: none !important;
}

.hidden {
  visibility: hidden;
}

.notopHide {
  border-top: none !important;
}

.cust-row1 {
  width: 100%;
  height: 50px;
  display: flex;
}

.custInnerR {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.allocateBtns1 {
  width: 30px;
  height: 21px;
  border: none;
  cursor: pointer;
  background-color: rgb(0 0 0 / 0%);
}

.allocateBtns2 {
  width: 23%;
  height: 20px;
  border: none;
  cursor: pointer;
  background-color: green;
  color: #fff;
  border-radius: 3px;
}

.allocateBtns3 {
  width: 23%;
  height: 20px;
  border: none;
  cursor: pointer;
  background-color: red;
  border-radius: 3px;
}

.custSpanDiv {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
}

.custInputDiv {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
}

.wd60 {
  width: 70%;
  height: 37px;
}

/* allocation vehicle */

.allocation1 {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.allR {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
}

.custRspan {
  color: #000;
  font-size: 0.75rem;
  font-size: 800;
}

.custRinput {
  width: 35%;
  height: 32px;
  border: 1px solid silver;
  border-radius: 4px;
  padding-left: 0px;
  background-color: rgb(0 0 0 / 7%);
}

.custRinput::placeholder {
  font-size: 0.9rem !important;
  font-weight: 800;
  color: #ff7200;
}

.mgRight {
  margin-left: 22px;
  display: flex;
  text-align: center;
}

.allocation2 {
  width: 96%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: rgb(0 0 0 / 7%);
  border-radius: 4px;
  margin-bottom: 23px;
}

.allRow1 {
  width: 97%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.allRow1 > span {
  color: #ff7200;
  font-size: 0.9rem;
  font-weight: 700;
}

.allRow2 {
  width: 97%;
  height: 70px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}

.allR40 {
  width: 40%;
  height: 60%;
  display: flex;
  align-items: center;
}

.all40I {
  width: 40%;
  height: 60%;
  display: flex;
  align-items: center;
}

.all30I {
  width: 25%;
  height: 60%;
  display: flex;
  align-items: center;
}

.all60I {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
}

.allInpI80 {
  width: 80%;
  height: 90%;
  display: flex;
  align-items: center;
  position: relative;
}

.typeOverlap {
  position: absolute;
  bottom: -15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #000;
  font-weight: 700;
}

.allInputTag {
  width: 100%;
  height: 90%;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 18%);
  margin-right: 3px;
  display: flex;
  color: #ff7200;
  font-weight: 700;
  padding-left: 6px;
}

.alterWidth {
  width: 62%;
}

.allInpI20 {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.allCheck {
  font-size: 0.6rem;
  border-radius: 50%;
  color: #fff;
  padding: 4px;
  cursor: pointer;
  background-color: green;
}

.allInfo {
  font-size: 1.1rem;
  border-radius: 50%;
  padding: 0px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
}

.allR60 {
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.all30 {
  width: 31%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.overlapping {
  position: absolute;
  top: -2px;
  font-size: 0.55rem;
  color: #000;
}

.all5 {
  width: 4%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.all5 > span {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}

.calInput {
  width: 70%;
  height: 34px;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 18%);
  margin-right: 0px;
  display: flex;
  color: #ff7200;
  font-weight: 700;
  padding-left: 7px;
}

.calInput::placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  color: #ff7200;
  font-weight: 700;
}

.allocation3 {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #fff;
}

.allBtn {
  width: 13%;
  height: 35px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.parentAll {
  border: 1px solid rgb(0 0 0 / 6%);
  border-radius: 4px;
  position: relative;
}

.allAddBtn {
  width: 13%;
  height: 35px;
  background-color: grey;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  margin-left: 20px;
}

.snoAbs {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #000;
  left: 3px;
  font-weight: 700;
}

.allAdd {
  width: 35px;
  height: 25px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.5rem;
}

.allSub {
  width: 45px;
  height: 25px;
  background-color: red;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.5rem;
}

.mobElem {
  width: 95%;
  height: 63%;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.custHeadingSpan {
  font-size: 0.85rem;
  color: #606060;
  text-decoration: underline;
  text-underline-offset: 4px;
  padding-left: 10px;
}

.parentAloocate {
  width: 96%;
  height: auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 10px;
  background-color: rgb(0 0 0 / 2%);
  padding-bottom: 15px;
  padding-top: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  position: relative;
  top: 10px;
  border: 3px solid rgb(0 0 0 / 3%);
}

.cust-row-main {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  display: flex;
}

.grossDiv {
  width: 45%;
  height: 100%;
  position: relative;
}

.grossDiv span {
  font-size: 10px;
  color: #808080;
  font-weight: 700;
}

.subDiv {
  width: 7%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: red !important;
}

.TareDiv {
  width: 45%;
  height: 100%;
  position: relative;
}

.TareDiv span {
  font-size: 10px;
  color: #808080;
  font-weight: 700;
}

.calDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.custInfo {
  font-size: 0.8rem;
  cursor: pointer;
  position: relative;
  bottom: -1px;
}

.custAdd {
  font-size: 0.75rem;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.custMinus {
  font-size: 0.8rem;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.absEqual {
  font-size: 1rem !important;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: -14px;
  color: grey !important;
  font-weight: 800;
}

.grossInput {
  width: 100%;
  height: 90%;
  border: 1px solid #ff7200;
  border-radius: 4px;
  text-align: center;
}

.topUp {
  position: absolute;
  top: -7px;
  background-color: #fff;
  left: 5px;
  font-size: 10px !important;
  color: #ff7200 !important;
}

.mgTopG {
  margin-top: 10px;
}

.mw50 {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid #ff7200;
  border-radius: 3px;
}

.mw50::placeholder {
  color: #000;
  height: 22px;
  padding-top: 8px;
  font-weight: 900;
  /* background-color: #fff; */
  font-size: 0.8rem;
}
.select2-dropdown {
  z-index: 99999;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 35px;
  font-size: 0.7rem;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ff7200;
  color: white;
  font-size: 0.75rem;
}

.select2-results__option--selectable {
  cursor: pointer;
  font-size: 0.75rem;
}

.vehicleIndex {
  width: 21px;
  height: 21px;
  display: flex;
  position: absolute;
  top: -5px;
  left: -8px;
  color: #fff;
  justify-content: center;
  align-items: center;
  background-color: #ff7200;
  border-radius: 50%;
  font-weight: 700;
}

.vehicleMinus {
  width: 21px;
  height: 21px;
  display: flex;
  position: absolute;
  top: -5px;
  right: -8px;
  color: #fff;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 50%;
  font-weight: 700;
  cursor: pointer;
}

.vehicleMinus1 {
  width: 21px;
  height: 21px;
  display: flex;
  position: absolute;
  top: -5px;
  right: -8px;
  color: #fff;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 50%;
  font-weight: 700;
  cursor: pointer;
}

.allocateTruckBtn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.allocateTruckBtn button {
  width: 12%;
  height: 28px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

/* view docs */
.docViewModal {
  width: 100%;
  height: auto;
  border: 1px solid silver;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 10px;
}

.docViewInn {
  width: 98%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.docVehicleHeader {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid silver;
}

.docUploadIcon {
  margin-left: 32px;
  color: #000;
  font-size: 1.1rem;
  cursor: pointer;
}

.docVehicleHeader span {
  font-size: 0.8rem;
  color: #000;
  font-weight: 700;
}

.specialDoc {
  font-size: 0.9rem !important;
  color: #ff7200 !important;
  font-weight: 700;
  padding-left: 4px;
}

.docVehicleBody {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.docImageInfo {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.docCard {
  width: 15%;
  height: 140px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  border: 1px solid grey;
  border-radius: 8px;
  margin-right: 12px;
}

.applyBrdr {
  border-bottom: 1px solid silver !important;
}

.docCard img {
  width: 90px;
  height: 100px;
}

.docCard span {
  color: #ff7200;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-size: 0.65rem;
}

.postRelative {
  position: relative;
}

.usersUpload {
  position: absolute;
  top: 1px;
  right: 30px;
}

.usersUploadIcon {
  position: relative;
  top: 10px;
  right: 15px;
}

.usersEyeIcon {
  position: relative;
  top: 10px;
  right: -7px;
  color: #ff7200;
}

.usersEyeIconabs {
  position: absolute;
  bottom: 10px;
  right: 35px;
  color: #ff7200;
}

.driCustm {
  font-size: 0.9rem;
  color: grey;
  font-weight: 700;
  text-decoration: underline;
}

.swal2-container {
  z-index: 999999999999999999;
}

.pCustRow {
  width: 100%;
  position: relative;
  top: 17px;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.inputBtnAppend {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.DriverInputActive {
  width: 50%;
  height: 29px;
  background-color: #ff7200;
  color: #fff;
  font-size: 0.85rem;
  border: none;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-radius: 4px;
  border-right: 1px solid rgb(0 0 0 / 11%);
  box-shadow: 0px 0px 3px #888888;
  transition-duration: 0.5s;
}

.DriverInputInactive {
  width: 50%;
  height: 29px;
  background-color: #e0e0e0;
  color: #000;
  font-size: 0.85rem;
  border: none;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 0px 2px #888888;
}

.mapOuter {
  width: 100%;
  height: 550px;
  margin-left: auto;
  margin-right: auto;
  /* height: 50vh; */
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.location-search-input {
  position: relative;
  top: -11px;
  width: 30%;
  height: 36px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  z-index: 3;
  padding-left: 8px;
  font-size: 1rem;
}

.alertText {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: red;
  width: 100%;
  font-weight: 700;
  height: 200px;
}

.ccwdt {
  width: 95%;
  text-align: start;
}

.ccwdt > .select2-container {
  width: inherit !important;
}

.viewElem {
  width: 100%;
  height: 270px;
  /* border: 1px solid #D8D8D8; */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.viewRow {
  width: 94%;
  height: 60px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.viewR1 {
  width: 48%;
  height: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
}

.viewR2 {
  width: 48%;
  height: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
}

.viewR3 {
  width: 100%;
  height: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #000;
}

.viewR4 {
  width: 30%;
  height: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
}

.locateMap {
  width: 15%;
  height: 23px;
  background-color: green;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 0.7rem;
  position: absolute;
  bottom: 5px;
  right: 3px;
}

.locateMapNew {
  width: auto;
  height: 23px;
  background-color: green;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 0.7rem;
  position: absolute;
  bottom: 5px;
  right: 3px;
}

.plantlabel {
  color: #000;
  font-weight: 700;
}

.plantspan {
  color: #707070;
  font-size: 0.85rem;
}

/* Add Contract clauses css */

.contractParent {
  width: 100%;
  height: auto;
  display: flex;
  background-color: rgb(0 0 0 / 5%);
  box-shadow: 1px 1px 6px rgb(0 0 0 / 45%);
}

.contractData {
  width: 70%;
  height: auto;
}

.contractFieldsElem {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  position: relative;
}
.InspectionAppChecks {
  flex-basis: 60%;
}
.contract40 {
  width: 40%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 3px;
}

.contract100 {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 15px;
  text-align: start;
  justify-content: flex-start;
  align-items: center;
}

.pdTop {
  padding-top: 20px;
  height: auto;
  padding-bottom: 20px;
}

.contractSpan {
  color: #000;
  font-weight: 700 !important;
}

.contractSpanLight {
  color: #000;
}

.previewDiv {
  display: inline-block;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
  flex-wrap: wrap;
}

.normalTextSpan {
  font-size: 0.95rem;
  color: #000;
}

.colorOrangePreview {
  color: #ff7200 !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
  padding-left: 11px !important;
  padding-right: 10px !important;
}

.contractDropSpan {
  color: #ff7200;
  font-size: 0.9rem;
  font-weight: 700;
}

.contractDrop {
  width: 60%;
  position: relative;
}
.contractDrop > .select2-container {
  width: 100% !important;
}

.contract60 {
  width: 60%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contract30 {
  width: 30%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexend {
  display: flex;
  align-items: center;
  text-align: end;
  justify-content: flex-end;
}

/* .contractInp {
  width: 100%;
  height: 34px;
  border: 1px solid #ff7200;
  border-radius: 3px;
  padding-left: 4px;
  color: #ff7200;
  font-weight: 700;
} */

.contractInpNew {
  width: 100%;
  height: 33px;
  border: 1px solid silver;
  border-radius: 5px;
  padding-left: 4px;
  color: #ff7200;
  font-weight: 700;
  position: relative;
  right: 9px;
}

.contractInp::placeholder {
  color: #ff7200;
  font-weight: 700;
}

.cont30 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.contIcon {
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.fxBtn {
  width: 95%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.formulaIcon {
  font-size: 1.2rem !important;
  color: #000;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 1px;
  padding-bottom: 3px;
}

.wdAuto {
  width: 30%;
  justify-content: end;
}

.editContIc {
  font-size: 1.2rem !important;
  color: #ff7200;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.deleteContIc {
  font-size: 1.2rem !important;
  color: red;
  cursor: pointer;
}

.addContIc {
  font-size: 1rem !important;
  color: green;
  cursor: pointer;
}

.incHgt {
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #e8e8e8;
  border-radius: 5px;
  padding-bottom: 11px;
  padding-top: 11px;
}

.contDropZone {
  width: 98%;
  min-height: 100px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  border: 2px dashed #a8a8a8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.dropZone,
.dropZoneCustomVar {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding-top: 15px;
}

.algnCnt {
  display: flex;
  align-items: center;
}

.contDropZone > span {
  color: #686868;
  font-size: 1rem;
  font-weight: 600;
}

.contBtn {
  width: 32%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}
.addCBtn {
  width: 40%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.contractInput {
  width: 30%;
  height: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggableElem {
  width: 96%;
  height: 96%;
  margin: 0 auto;
  border: 1px solid #b0b0b0;
}

.draggableHead {
  width: 100%;
  height: 12%;
  display: flex;
}

.cont50Inactive {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-color: #e8e8e8;
  cursor: pointer;
  text-align: center;
}

.cont50Active {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-color: #ff7200;
  /* cursor: pointer; */
}

.cont50Inactive > span {
  font-size: 2rem !important;
  color: #000;
  font-weight: 500;
}

.cont50Active > span {
  font-size: 1.1vw !important;
  color: #fff;
  font-weight: 400;
}

.slashEye {
  padding-left: 6px;
}

.contCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  overflow-y: scroll;
  height: 315px;
  margin-top: 1em;
}

.innerCol {
  width: 48%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dragItem {
  width: 98%;
  height: 33px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.dragItem:hover {
  width: 98%;
  height: 33px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #ff7200;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 1px 1px 6px grey;
}

.noClr {
  background-color: transparent;
}

.noClr:hover {
  background-color: transparent;
  box-shadow: none;
  cursor: unset;
}

/* simple Text css */
.FieldElem {
  width: auto;
  height: 30%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 15px;
  margin-right: 5px;
  margin-bottom: 16px;
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.absMius {
  display: none;
  width: 14px;
  height: 11px;
  position: absolute;
  right: -2px;
  top: -6px;
  cursor: pointer;
  color: red;
  background-color: red;
  color: #fff;
  font-size: 0.7rem;
  border-radius: 10px;
}

.operatorElem {
  width: auto;
  height: 27px;
  border: 1px solid rgb(0 0 0 / 14%);
  border-radius: 3px;
  cursor: pointer;
}
.operatorElem:hover .absMius,
.FieldElem:hover .absMius {
  display: block;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.alignCenterDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.incOperHgt {
  min-width: 110px;
  height: 44px !important;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #d8d8d8;
}

.flexCol {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.simpleText {
  width: 130px;
  height: 31px;
  border: 1px solid silver;
  border-radius: 5px;
}

.dropElem {
  width: 130px;
  height: 31px;
  border: 1px solid silver;
  border-radius: 5px;
  outline: none;
}

.inputField {
  width: 130px;
  height: 31px;
  border: 1px solid silver;
  border-radius: 5px;
}

.inlineBlock {
  display: inline-block;
}

/* radio css */
.RadioinputField {
  margin-right: 5px;
}

/* /dropdown */
select {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 2em 0.5em 0.5em;
  border: 1px solid #eee;
  font: inherit;
  line-height: inherit;
  border: 1px solid silver;
  border-radius: 6px;
}

/* date */
.custBrdrBox {
  padding: 5px;
}

/* custom parts */
.customDiv {
  width: 98%;
  height: 88%;
  margin-left: auto;
  margin-right: auto;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overflow {
  overflow: auto !important;
}

.assignHgt {
  position: relative;
  top: 20px;
  height: 170px !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alignEnd {
  display: flex;
  align-items: flex-end;
}

.customRow {
  width: 100%;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.cust10 {
  width: 10%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: flex-start;
}

.cust70 {
  width: 70%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
}

.cust5 {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  justify-content: flex-start;
}

.borderMe {
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 3px;
  /* padding-left: 4px; */
}

.contractLabell {
  color: #000;
  font-weight: 700;
}

.contractInputInner {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}

.custContBtn {
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  margin-left: 4px;
  width: 15%;
  height: 23px;
  position: relative;
  top: 8px;
}

.contAdd {
  color: green;
  cursor: pointer;
}

.constSub {
  color: red;
  cursor: pointer;
  margin-right: 7px;
}

.match {
  color: green;
}

.activeBtn {
  width: auto;
  height: 23px;
  background-color: green;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  color: #fff;
}

.inactiveBtn {
  background-color: red;
}

.absBtn {
  position: absolute;
  width: 100% !important;
  z-index: 1;
}

.algnVert1 {
  display: flex;
  flex-direction: column !important;
}

.locationsElem {
  width: 100% !important;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(0 0 0 / 5%);
  margin-top: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.source {
  width: 99%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  padding-left: 7px;
  border-radius: 2px;
  flex-direction: column;
  background-color: #fff;
}

.source > span:nth-child(1) {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  text-underline-offset: 4px;
}

.source > span:nth-child(2) {
  color: #ff7200;
  font-weight: 700;
  font-size: 1rem;
}

.destinations {
  width: 100%;
  height: 200px;
  padding: 5px;
  display: flex;
  justify-content: center;
  position: relative;
  align-self: center;
  border-radius: 5px;
  /* border: 1px solid #B8B8B8; */
}

.midDestin {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #fff;
  justify-content: center;
  text-align: start;
  flex-direction: column;
  border-radius: 4px;
}

.midDestin > span:nth-child(1) {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-underline-offset: 4px;
  padding-left: 6px;
}

.midDestin > span:nth-child(2) {
  color: #ff7200;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-underline-offset: 4px;
  padding-left: 6px;
}

.templates {
  width: 40%;
  height: 160px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 14%;
  top: 10%;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  background-color: #f8f8f8;
  padding: 5px;
}

.templates:before {
  border-top: 13px solid transparent;
  border-left: 13px solid transparent;
  border-right: 13px solid #e0e0e0;
  border-bottom: 13px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  top: 45%;
  left: -27px;
}

.tempRow {
  width: 100%;
  height: 35px;
  display: flex;
  /* background-color: red; */
}

.destElems {
  width: 48%;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #c0c0c0;
  justify-content: space-between;
}

.destElems1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  padding-left: 6px;
}

.docHeading {
  font-size: 0.7rem;
  color: #666666;
  font-weight: 700;
}

.docValue {
  color: #ff7200;
  font-weight: 700;
}

.alnCent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tempRow > button {
  width: auto;
  height: 25px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  border-radius: 3px;
  margin-right: 8px;
}

.bookingElem {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 3%);
}

.bookingElemRow {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.transBooking {
  width: 95%;
  border-radius: 4px;
  border: 1px solid silver;
}

.bookingElemI {
  width: 31%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.bookingElemI > span {
  color: black;
  font-size: 0.8rem;
  font-weight: 700;
}

.bookingElemI > input {
  width: 95%;
  height: 33px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: transparent;
  padding-left: 5px;
}

.datePickerClass {
  width: 95%;
  height: 33px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  padding-left: 5px;
  background-color: transparent;
}

.datePickerClassNew {
  width: 220px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid silver;
  padding-left: 0px;
  margin-left: 20px;
}

.Asteric {
  color: red;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.addBookingBtn {
  width: 85px;
  height: 33px;
  background-color: #787878;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.formula100 {
  width: 100%;
  height: auto;
  display: flex;
}

.formula70 {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.formula40 {
  width: 39%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.formula1001 {
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 8px;
}

.formula15 {
  width: auto;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  text-align: center;
  align-items: center;
}

.formulaField {
  width: 100px;
  font-size: 0.85rem;
  color: #000;
  font-weight: 700;
}

.formulaValue {
  min-width: auto;
  font-size: 0.8rem;
  color: #ff7200;
  font-weight: 700;
  border: 1px solid grey;
  padding-left: 20px;
  padding-right: 20px;
}

.formula35 {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}

.formulaflexRow {
  display: flex;
}

.formulaInput {
  width: 50%;
  height: 32px;
  border: 1px solid silver;
  border-radius: 3px;
  padding-left: 4px;
}

.formula30 {
  width: 30%;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0px;
  background-color: #f0f0f0;
  margin-top: 5px;
}

.limitFormula__wrapper {
  display: flex;
  padding: 1em 3.75em;
}
.limitFormula {
  display: flex;
  align-items: center;
  gap: 1em;
}

.calculatorElem {
  width: 95%;
  height: 95%;
  border-radius: 5px;
  background-color: #fff;
}

.calHeadingElem {
  width: 100%;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0 0 0 / 10%);
  border-radius: 1px;
}
.customVarPlusIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.calHeading {
  color: #787878;
  font-size: 0.9rem;
  font-weight: 700;
}

.calDragElem {
  width: 100%;
  height: 84%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f0f0f0;
}

.variableElem {
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
}

.calElems {
  width: 100%;
  height: 35px;
  display: flex;
}

.wd48 {
  width: 50% !important;
  height: 35px;
  display: flex;
}

.calElem20 {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  cursor: pointer;
}

.calElem20:hover {
  width: 20%;
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* border: 2px solid #f0f0f0; */
  /* cursor: pointer; */
  background-color: #d0d0d0;
}

.colorOrange {
  background-color: #ff7200;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f0f0f0;
  cursor: pointer;
}

.colorOrange:hover {
  background-color: #d0d0d0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f0f0f0;
  cursor: pointer;
}

.hide {
  display: none;
}

.calElem20 > span {
  color: #000;
  font-size: 0.7vw;
  font-weight: 700;
}

.calElem20 > img {
  width: 12px;
}
.operators__wrapper {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* gap: 0.5em; */
}
.operators {
  flex-basis: 23%;
  /* background-color: #c2c2c2; */
  font-size: 1.25rem;
  font-weight: 700;
}

.operatorImg {
  width: 11px;
}

.formHeader {
  width: 97.5%;
  height: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  background-color: rgb(0 0 0 / 10%);
  border: 2px solid rgb(0 0 0 /3%);
}

.formHeader > span {
  padding-left: 15px;
  color: #696969;
  font-size: 0.9rem;
  font-weight: 900;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.limitHgt {
  width: 100%;
  height: 200px;
}

.formulaBody {
  width: 100%;
  height: 150px;
  padding-left: 10px;
  padding-right: 10px;
}

.formDropZone {
  width: 100%;
  height: 150px;
  background-color: rgb(0 0 0 / 5%);
}

.formulaDrop {
  width: 30%;
  /* height: 205px; */
  margin-top: 6px;
  background-color: rgb(0 0 0 / 5%);
}

.empty {
  width: 100%;
  height: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 2px;
  background-color: rgb(0 0 0 / 3%);
}

.formDropdown {
  width: 80%;
}

.alternateText {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

select {
  outline: none !important;
  cursor: pointer;
}

.selecteFieldElem {
  width: 100%;
  height: 77%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 13px;
  border-radius: 5px;
}

.dynamicElem {
  width: 44%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 2px;
  margin-right: 2px;
  position: relative;
  font-size: 0.8vw;
}

/* operatorElement */
.operatorElement {
  width: auto;
  height: 40px;
  background-color: red;
}

.plusSign {
  font-size: 1rem;
  font-weight: 700;
}

.bthHeight {
  width: 70%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.addBtnClause {
  width: auto;
  height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ff7200;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.assignEye {
  position: absolute;
  right: 25px;
  top: 45px;
}

.confirmassignEye {
  position: absolute;
  right: 50px;
  top: 45px;
}

.Wrong {
  color: red;
}

.right {
  color: green;
}

.referElem {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
}

.referRow {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.referRoww {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.referRoww > div {
  width: 57%;
  align-items: flex-start;
  text-align: left;
}

.unsetJs {
  justify-content: unset;
}

.spanName {
  width: 120px;
  color: #000;
  font-size: 0.8rem;
  font-weight: 700;
  width: 100px;
  text-align: start;
}

.referDrop {
  width: 58%;
  height: 37px;
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: 5px;
}

.referInp {
  width: 57%;
  height: 37px;
  margin-left: 17px;
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: 5px;
}

.newCls {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.referBtn {
  width: 45%;
  height: 25px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.hidden {
  visibility: hidden;
}

.emailBox {
  width: 100%;
  height: 82px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(0 0 0 / 6%);
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  background-color: rgb(0 0 0 / 2%);
  flex-wrap: wrap;
  box-shadow: 1px 0px 10px rgb(0 0 0 / 6%);
}

.emailContainer {
  position: relative;
  display: inline-block;
  width: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 7px;
  height: 20px;
  background-color: #ff7200;
  color: #fff;
  margin-right: 6px;
  margin-bottom: 6px;
}

.addEmailspan {
  color: grey;
  font-size: 0.8rem;
  font-weight: 700;
}

.emailCross {
  position: absolute;
  top: -6px;
  right: -4px;
  color: red;
  cursor: pointer;
}

.alignCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.referBtnn {
  width: auto;
  height: 28px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  border: none;
}

.transComp {
  width: 100%;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.transRow {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trans30 {
  width: 32%;
  height: 90%;
  display: flex;
}

.trans30Inner {
  width: 35%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.transSpan {
  color: #000;
  font-size: 0.8rem;
  font-weight: 700;
}

.transInput {
  width: 100%;
  height: 35px;
  border: 1px solid rgb(0 0 0 / 11%);
  border-radius: 3px;
  padding-left: 3px;
}

.trans70 {
  width: 65%;
  height: 90%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}
.trans700 {
  width: 65%;
  height: 90%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}
.trans700 > div {
  width: 100%;
}
.uploadHandler {
  position: relative;
  right: 40px;
}

.uploadElement {
  width: auto;
  height: auto;
  position: absolute;
  right: 20px;
  overflow: hidden;
}

.topPad {
  font-size: 0.8rem !important;
  position: absolute;
  top: 8px;
  right: 25px;
}

.topPadd {
  font-size: 0.8rem !important;
  position: absolute;
  top: 13px;
  right: 25px;
}

.topPadEye {
  font-size: 0.8rem !important;
  position: absolute;
  top: 12px;
  right: 25px;
}

.transupload {
  position: position;
}

.fileType {
  position: absolute;
  left: 0px;
  opacity: 0;
}

.transEYE {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.transCentr {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.registerTransBtn {
  width: 9%;
  height: 32px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  font-size: 0.9rem;
}

.CalBox {
  width: 40%;
  /* height: 37px; */
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalBox .newTitleBox {
  min-width: 200px;
  height: 28px;
  padding: 5px;
  background-color: red;
  position: absolute;
  top: 39px;
  border-radius: 4px;
  z-index: 9999999999;
  padding-left: 10px;
  padding-right: 10px;
  display: none;
  text-align: center;
}

.newTitleBox::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: -7px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: red;
  overflow: hidden;
}

.CalBox:hover > .newTitleBox {
  width: 200px;
  height: 28px;
  padding: 5px;
  background-color: red;
  color: #666666;
  position: absolute;
  top: 34px;
  border-radius: 4px;
  z-index: 9999999999;
  display: block !important;
}

.newTitleBox span {
  color: #fff;
  font-size: 0.6rem;
}

.addClauseBtn {
  width: 14%;
  height: 30px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.rightMg {
  margin-left: 20px;
  position: relative;
  top: 2px;
}

.addCtegoryModal {
  width: 300px;
  position: absolute;
  top: -160px;
  height: 150px;
  background-color: #727376;
  padding: 2px;
  border-radius: 8px;
}
.addCtegoryModalHgt {
  width: 330px;
  height: 225px;
  position: absolute;
  top: -235px;
  left: 21%;
  z-index: 19999;
}

.addCtegoryModal:before {
  border-top: 10px solid #888888;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 15px;
  bottom: -10px;
}

.addCategInner {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
  padding: 8px;
}

.contSelect {
  width: 100%;
  border-radius: 3px;
  height: 31px;
}

.categoryModalSpan {
  font-size: 0.8rem;
  color: #000;
  font-weight: 700;
}

.categoryModalInput {
  height: 30px;
  border-radius: 5px;
  border: 1px solid silver;
}

.categoryModelBtn {
  width: 22%;
  height: 23px;
  border-radius: 3px;
  background-color: #727376;
  color: #fff;
  cursor: pointer;
  border: none;
}

.closeContModal {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: #727376;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -10px;
  right: -8px;
  cursor: pointer;
}

#headingCross {
  color: #fff;
}

button:focus {
  outline: none !important;
}

button:focus {
  box-shadow: none !important;
}

.showSpan {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 1rem;
  color: red;
}

.showSpanNew {
  width: 100%;
  height: 70px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 1rem;
  color: red;
  background-color: rgb(0 0 0 / 4%);
}

.hideSpan {
  display: none;
}

.warnText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.7rem;
  color: red;
}

.collapseTableInspect {
  margin-left: auto;
  margin-right: auto;
}

.insTableHead {
  width: 100%;
  height: 35px;
  display: flex;
}

.insHeader1 {
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 4px;
}

.insHeader2 {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  text-align: center;
  margin-left: 4px;
}

.insHeader1 > span {
  color: #fff;
  font-weight: 700;
  font-size: 0.8rem;
}

.addPlant {
  height: 25px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  margin-left: 3px;
  font-size: 0.7rem;
}

.hilElem {
  width: 100%;
  height: auto;
}

.hilRow {
  width: 100%;
  height: 60px;
  display: flex;
  background-color: rgb(0 0 0 / 5%);
  justify-content: space-between;
}

.hilR {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hil1 {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: start;
  align-items: center;
}

.hil2 {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: start;
  align-items: center;
}

.inlineBlock {
  display: inline-block;
}

.hilcustSpan {
  font-size: 0.7rem;
  font-weight: 700;
  color: #000;
}

.hilcustSpan1 {
  /* width: 155px; */
  font-size: 0.7rem;
  font-weight: 700;
  color: #000;
}

.hilcustInp {
  width: 90%;
  height: 29px;
  border-radius: 4px;
  border: none;
  border: 1px solid rgb(0 0 0 / 26%);
}

.hilcustInpNew {
  width: 62%;
  height: 29px;
  border-radius: 4px;
  border: none;
  border: 1px solid rgb(0 0 0 / 26%);
}

.hilcustInp1 {
  width: 91%;
  height: 29px;
  border-radius: 4px;
  border: none;
  position: relative;
  left: 10px;
  border: 1px solid rgb(0 0 0 / 26%);
}

.centralise {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hilBtns {
  width: auto;
  padding-left: 13px;
  padding-right: 13px;
  height: 29px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.hilMg {
  margin-bottom: 10px;
}

.cust6Center {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.podCustName {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(0 0 0 / 5%);
}

.podCustName > span {
  font-size: 0.8rem;
  color: #000;
  font-weight: 700;
}

.custInpPod {
  width: 50%;
  height: 33px;
  margin-left: 20px;
  border-radius: 4px;
  border: none;
  border: 1px solid silver;
}

.hideBtn {
  display: none !important;
}

.allocateTruck {
  width: atuo;
  height: 24px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.InspectionEye {
  cursor: pointer;
  font-size: 0.7rem;
}

.centerImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noImgCont {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: red;
}

.classicImg {
  width: 40%;
  /* height: 55px !important; */
}

.reportBtn {
  width: auto;
  height: 23px;
  font-size: 0.7rem;
  background-color: #727376;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 2px;
  border: none;
}

.reportBtnNew {
  width: auto;
  height: 18px;
  font-size: 0.6rem;
  background-color: rgb(0 0 0 / 38%);
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 2px;
  border: none;
}

.reportElem.close {
  display: none;
}

.reportElem.open {
  width: 100%;
  max-height: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 7px;
  display: block !important;
  background-color: rgb(0 0 0 / 7%);
  margin-bottom: 20px;
}

.inspectDetTb {
  width: 97%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.reportElemNotFound {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: red;
  font-size: 0.9rem;
  background-color: rgb(0 0 0 / 12%);
  font-weight: 700;
}

.contractClauseBody {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 2px;
}

.contractClauseCat1 {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 6%);
  padding-top: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(0 0 0 / 6%);
}

.catDiv {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.catspan {
  font-size: 0.7rem;
  color: #000;
  font-weight: 700;
  padding-left: 6px;
}

.catOrangeSpan {
  font-size: 0.85rem;
  color: #ff7200;
  font-weight: 700;
  margin-left: 13px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.catDivBody {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
}

.contractClauseItem {
  cursor: pointer;
}
.contractClauseItem:hover {
  color: white;
  background-color: #c1c1c1;
}

.cat5 {
  width: 5%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  font-weight: 900;
  font-size: 0.6rem;
}

.catIcon {
  width: 9%;
  height: auto;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  right: 0px;
}
.clauseIcon {
  width: auto;
}
.catClauses {
  width: auto;
  display: flex;
  align-items: center;
  font-size: 0.9rem !important;
}

.catClausesGrey {
  color: #606060;
  font-weight: 900;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9rem !important;
}

.checkStyle {
  color: yellow !important;
  margin-right: 200px;
}

.notFound {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: red;
  font-size: 1rem;
}
.btnstage {
  width: 17px !important;
  height: 17px;
  background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 11;
  font-size: 1rem;
  font-weight: 700;
  color: red;
  background-color: rgb(0 0 0 / 0%);
  border-radius: 2px;
  margin-left: 9px;
  cursor: pointer;
  border: 1px solid #727374;
  padding-bottom: 2px;
}
.clauseEditBtn {
  /* width: 10px; */
  background-color: #ff7200;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  margin-right: 5px;
}

.greyBg {
  background-color: rgb(0 0 0 / 4%);
}

.orangeBg {
  width: auto;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.6rem;
  background-color: #ff7200;
  border-radius: 4px;
  cursor: pointer;
}

.catDelete {
  color: red;
  cursor: pointer;
  font-size: 1rem;
}

.editClause {
  position: absolute;
  right: 20px;
  top: 5px;
  background-color: green;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  color: #fff !important;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.lrBtnDetailConfirm {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateElem {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateElem > button {
  width: auto;
  height: 28px;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.vehicleInpField {
  width: 55%;
  height: 26px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background-color: transparent;
  color: #707070;
}

.vehicleInpField.underline {
  width: 55%;
  height: 26px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background-color: #fff;
  color: #707070;
}

.vehicleInpField1 {
  width: 65%;
  height: 26px;
  border-radius: 4px;
  justify-content: end !important;
  align-items: flex-end !important;
  text-align: end !important;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  background-color: transparent;
  color: #ff7200;
}

.vehicleInpField1.underline {
  background-color: #fff;
}

.brdrColor {
  border: 1px solid grey;
  border-radius: 3px;
  padding: 7px;
}

.bgGrey {
  background-color: rgb(0 0 0 / 2%);
  margin-right: 2px;
}

.hubUpdateInp {
  width: 100%;
  border: none;
  text-align: end;
  font-size: 1rem;
  color: #ff7200;
  text-align: end;
  background-color: transparent !important;
}

.hubUpdateInp.underline {
  background-color: #fff !important;
}
.updateCalender {
  font-size: 0.68rem;
  cursor: pointer;
  color: #000;
  position: absolute;
  display: flex;
  right: -10px;
  top: 6px;
}
.hideCalend {
  overflow: hidden;
  position: relative;
  left: 13px;
  /* z-index: 0; */
  opacity: 0;
}
.calenderInp {
  width: 35%;
  height: 23px;
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 4px;
  margin-left: 10px;
}
.calenderInpCont {
  width: 59%;
  height: 27px;
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(0 0 0 / 3%);
}
.alterCalend {
  width: 85%;
  height: 32px;
  border-bottom: 1px solid #ff7200;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
}
.noImgText {
  padding-left: 20px;
  color: red;
  text-decoration: none !important;
}
.updateAllocate.hide {
  display: none;
}
.updateAllocate.show {
  width: 99%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  border: 1px solid #ff7200;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}
.justifyEnd {
  display: flex;
  justify-content: space-between;
}
.justifyEnd > button {
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 28px;
  margin-right: 10px;
}
.addClauseUi.close {
  display: none;
}
.addClauseUi.open {
  display: block;
  /* margin-top: 15px ; */
}
html {
  scroll-behavior: smooth;
}
.updateallocateHeader {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateallocateHeader > span {
  color: #727376;
  font-size: 0.9rem;
  font-weight: 700;
}
.updateallocateBody {
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
}
.updateAllRow1 {
  width: 32%;
  height: 50px;
  display: flex;
}
.update40 {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.update40 > span {
  font-size: 0.8rem;
  color: #727376;
  font-weight: 700;
}
.update60 {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.update60 > input {
  width: 100%;
  height: 29px;
  border: 1px solid silver;
  border-radius: 4px;
}
.updateBtnElem {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateBtnElem > button {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 28px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}
/* Hover status display */
.btnstage .titleBoxnew1 {
  width: 170px;
  height: 28px;
  padding: 5px;
  background-color: #404040;
  position: absolute;
  top: 35px;
  right: 9px;
  border-radius: 4px;
  z-index: 9999999999;
  display: none;
}

.titleBoxnew1::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: -7px;
  left: 85%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #404040;
  overflow: hidden;
}

.btnstage:hover > .titleBoxnew1 {
  width: 170px;
  height: 28px;
  padding: 5px;
  background-color: #404040;
  color: #666666;
  position: absolute;
  top: 39px;
  border-radius: 4px;
  z-index: 9999999999;
  display: block !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.titleBoxnew1 > span {
  color: #fff;
  font-size: 0.7rem;
  position: relative;
  bottom: 3px;
}
.cupdateElem {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cupdateElem > button {
  width: auto;
  height: 30px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}
.reactSelect > div {
  width: 95% !important;
}
.reactSelect1 > div {
  width: 95% !important;
  text-align: left;
}
.alignCenter > div {
  background-color: #fff;
}
.justifyContentDriver {
  display: flex;
  justify-content: space-between;
}
.justifyContentDriver > button {
  margin-right: 20px;
  width: auto;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.partialUpdate {
  width: 70%;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 5px;
}
.contractUpload {
  width: 100%;
  height: auto;
  border-radius: 3px;
  background-color: #fff;
  border-left: 6px solid #7d7d7c;
  /* box-shadow: 0px 0px 4px grey; */
}
.contractUploadHeading {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(0 0 0 / 4%);
}
.contractUploadHeading > span {
  margin-left: 7px;
  font-size: 1.1rem;
  color: #696c74;
}
.contractUpBtn {
  width: auto;
  height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 20px;
  background-color: #ff7200;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  border: none;
  opacity: 1;
  z-index: 1;
}
.contractUpInp {
  position: absolute;
  left: 0px;
  top: 1px;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
}
.uploadedDocs {
  width: 100%;
  height: auto;
  /* padding-top: 10px;
    padding-bottom: 10px; */
}
.contHeaddd {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row !important;
  margin-bottom: 20px;
}
.contHeaddd > span {
  color: #727376;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.removescroll {
  position: relative;
  top: 20px !important;
  overflow-x: hiden !important;
  height: unset !important;
}
.topSide {
  margin-top: 20px;
}
.clauseAdd {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: rgb(0 0 0 / 3%);
}
.typeText {
  color: #000;
  font-weight: 800;
}
@media screen and (max-width: 1430px) {
  .less-size {
    height: 22px !important;
    margin-top: 1px !important;
    margin-bottom: 2px !important;
  }

  .ct-span {
    font-size: 0.7rem;
    color: #000;
    font-weight: 700;
  }
}
