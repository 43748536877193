.contract__data {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 2em;
  padding: 1em;
}

.contract_form_input_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
}

.contract_form_input_wrapper > input {
  height: 38px;
  flex-basis: 60%;
  border: 1px solid #ff7200;
  color: #ff7200;
  border-radius: 4px;
  padding: 0.25em;
  font-size: 1rem;
}

.contract_form_input_select {
  flex-basis: 60%;
}

.contract_form_checkbox_wrapper {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}
