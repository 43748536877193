.profileParent{
    margin: 8px 15px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    background: #fff;
    padding: 10px;
    transition: all 0.4s;
    overflow-x: auto;
}

.profileParent>h1.title {
    font-size: 1.2rem;
    margin: 0;
}
.profile-div{
    width: 100%;
    height: auto;
    border: 1px solid silver;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    background-color: rgb(0 0 0 / 2%);
}

.left-profile{
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    /* border-right: 1px solid #ff7200; */
}
.profile-picture{
    width: 95%;
    /* height: 230px; */
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;   
}
.img-container{
    width: 18%;
    height: 200px;
    border-radius: 50%;
    border: 1px dotted #ff7200;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.img-element{
    width: 20px;
    height: 10%;
    position: absolute;
    right: 0px;
    top: 40px;
    cursor: pointer;
    overflow: hidden ;
}
.edit_img{
    color: #ff7200;
    font-size: 18px;
    cursor: pointer;
    z-index: 11;
    background-color: #fff;
    cursor: pointer;
   
}
.file_upload{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;    
    z-index: 1; 
}
.imageDim{
    width: 70%;
    position: relative;
    height: 70%;
}
.profile-info-1{
    width: 96%;
    height: auto;
    position: relative;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 30px;
}

.profile-info-1 table{
    width: 95%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: separate;
    border-spacing: 0 2em;
}

.borders{
    height: 24px !important;
    border-bottom: 1px solid #ff7200;
}
.borders td:first-child{
    border-right: 1px solid #ff7200;
    border-bottom: 1px solid #ff7200;
    padding-left: 10px;
    color: black;
    font-weight:800;
    font-family: GOTHIC !important;
}
.borders td{
    border-bottom: 1px solid #ff7200;
    padding-left: 10px;
    font-family: GOTHIC !important;
}
#special-text{
    color: green;
}

#check{
    color: green;
    font-size: 15px;
    position: relative;
    left: 2%;
    cursor: pointer;
    border:1px solid green;
    border-radius: 50%;
}
#upload{
    color: green;
    font-size: 18px;
    position: relative;
    left: 6%;
    cursor: pointer;
}
#view{
    color: rgb(73, 73, 230);
    font-size: 18px;
    position: relative;
    left: 5%;
    cursor: pointer;
}
#edit{
    color: #ff7200;
    font-size: 18px;
    position: relative;
    left: 4%;
    cursor: pointer;
}
.middle{
    width: 2px !important;
    height: 10% !important;
    position: relative;
    top: 14px;
    background-color: #ff7200 !important;
}

.right-profile{
    width: 96%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: rgb(0 0 0 / 3%);
    border-radius: 6px;
    /* border-left: 4px solid #727376; */
    box-shadow:  1px 1px 8px #888888;
}
.progress-div{
    width: 95%;
    height: 35px;
    position: relative;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.progress-div label{
    color: black;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    top: 7px;
    font-family: GOTHIC !important;
}
progress{
    width: 30%;
    height: 15px;
    position: relative;
    left: 15px;
    top: 8px;
    border: none;
    background: white !important;
}
progress::-webkit-progress-value {
  background:#ff7200;
}
progress::-moz-progress-bar {
  background: #ff7200;
}

#value{
    color: black;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    top: 7px;
    margin-left: 20px;
}
.progress-div button{
    width: auto;
    height: 30px;
    position: absolute;
    right: 0;
    border-radius: 4px;
   border: 1px solid #ff7200;
   color: #ff7200;
   background-color: #fff;
   cursor: pointer;
   font-size: 0.75rem;
   padding-left: 5px;
   padding-right: 5px;
} 

.progress-div button:hover{
    width: auto;
    height: 30px;
    position: absolute;
    right: 0;
   border: 1px solid #ff7200;
   color: #fff;
   background-color: #ff7200;
   cursor: pointer;
}
.right-profile-table{
    width: 96%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    
}

.right-profile-table table{
width: 100%;
height: auto;
position: relative;
top: 0px;
border-collapse: separate;
border-spacing: 0 1.9em;
background-color: rgb(0 0 0 / 3%);
}
.profElem{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profElem>button{
    width: auto;
    padding-left: 13px;
    padding-right: 13px;
    height: 30px;
    background-color: #ff7200;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}
.lt{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0 0 0 / 3%);
    margin-top:12px ;
    position: relative;
    border-radius: 3px;
    box-shadow:  1px 1px 8px #888888;
}
.ltTop{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ltTop>span{
    color: #000;
    font-size: 1.1rem;
    text-decoration: underline;
    font-weight: 700;
    text-underline-offset: 4px;
}
.ltBot{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ltRow{
    width: 31%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
}
.lt40{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.lt60{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ltspan{
    color: #000;
    font-size: 0.8rem;
    font-weight: 700;
}
.ltInp.nobd{
    width: 90%;
    height: 29px;
    background-color: transparent;
    padding-left: 4px;
    border: none;
    border-bottom: 1px solid #ff7200;
    color: #727376;
}
.ltInp.bd{
    width: 90%;
    height: 29px;
    padding-left: 4px;
    border: none;
    border-bottom: 1px solid #ff7200;
    color: #727376;
    background-color: #fff;
}
.ltInp1.nobd{
    width: 90%;
    height: 29px;
    background-color: transparent;
    padding-left: 4px;
    border: none;
    border-bottom: 1px solid #ff7200;
    color: #727376;
}
.ltInp1.bd{
    width: 90%;
    height: 29px;
    padding-left: 4px;
    border: none;
    border-bottom: 1px solid #ff7200;
    color: #727376;
    background-color: #fff;
}
.bdFull{
    height: 33px;
    border-radius: 4px;
    border: 1px solid #ff7200;
    background-color: #fff;
    color: #727376;
}
.profileEdit{
    width: auto;
    padding-left: 13px;
    padding-right: 13px;
    height: 30px;
    background-color: green;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    position: absolute;
    right: 10px;
}
.profUpdate{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}
.profBtn{
    width: auto;
    padding-left: 13px;
    padding-right: 13px;
    height: 31px;
    background-color: #ff7200;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    border: none;
}