.main-content>.content-body>.contractscontentpanel {
    margin: 8px 15px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    background: #fff;
    padding: 10px;
}
.contract-header{
    width: 100%;
    height: 4%;
    display: flex;
    position: relative;
    top: 8px;
    justify-content: space-between;
}
.contract-b1{
    width: 12%;
    height: 100%;
    display: flex;
    position: relative;
    left: 12px;
    justify-content: space-between;
}
.contract-b1 button{
    width: 50px;
    height: 30px;
    border: none;
    background-color: #ff7200;
    border-radius: 6px;
    cursor: pointer;
}
#contract-back{
    color: #fff;
    font-size: 16px;
}
.contract-b2{
    width: 15%;
    height: 100%;
}
.contract-b2 .contract-btn-1{
    width: 20%;
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #ff7200;
    border: 1px solid #fff;;
    cursor: pointer;
    color: #fff;
}

.contract-b2 .contract-btn-2{
    width: 79%;
    height: 29px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #ff7200;
    border: none;
    color: #fff;
    cursor: pointer;
}
#fa-add-icon{
    color: #fff;
}
.contract-header h1.title {
    font-size: 1.2rem;
    margin: 0;
    position: relative;
    top: 55%;
    transform: translateY(-50%);
} 
.contract-container {
    width: inherit;
    position: relative;
    padding: 5px 1px;
}
.contract-header1{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid rgb(0 0 0 / 5%);
    justify-content: space-around;
    padding: 15px;
    border-radius: 8px;
    background-color: #F5F5F5;
    overflow: auto;
    padding-top: 30px;
}
.contract-header1::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: gray;
}
.contract-header1::-webkit-scrollbar-thumb:horizontal{
    background: #ff7200;
    border-radius: 10px;
}
.contractHeader1-one{
    width: 47%;
    height: 250px;
    position: relative;
    /* border: 1px solid #888888; */
    border-radius: 7px;
    box-shadow: 1px 1px 8px grey;
    background-color: #fff;
    margin-bottom: 20px;
}
/* .contractHeader1-one:before {
    border-top: 13px solid #888888;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 15px;
    bottom: -13px;
} */
.contractNum button{
    border-radius: 50%;
    background-color: #888888;
    color: #ff7200;
    position: relative;  
    border: none;  
    top: -10px;
    left: -9px;
    color: white;
    cursor: pointer;
}
/*  */
.Contractone{
    width: 100%;
    height: 32%;
    display: flex;
    position: relative;
    top: 0px;
    justify-content: space-between;
}
.contractone-1{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractone-2{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractone-3{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractspan1{

    font-size: 0.7rem;
    font-weight: 700;
    padding-left: 10px;
}
.contractspan2{
    font-size: 0.7rem;
    color: #ff7200;
    font-weight: 600;
    padding-left: 10px;
    word-break: break-all;
}
.contractspan3{
    font-size: 0.6rem;
    color: #888;
    font-weight: 600;
    padding-left: 10px;
}
/*  */
.Contracttwo{
    width: 100%;
    height: 32%;
    display: flex;
    justify-content: space-between;
   
}
.contractTwo-1{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.contractTwo-2{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}
.contractTwo-3{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
/*  */
.Contractthree{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.addClausebtn{
    width: auto;
    height: 25px;
    border: 1px solid #ff7200 !important;
    border-radius: 4px;
    background-color: #ff7200;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
}
.addClausebtn:hover{
    height: 25px;
    /* border: 1px solid #ff7200; */
    border-radius: 4px;
    background-color: #fff;
    color: #ff7200;
    cursor: pointer;
}
.viewClausebtn{
    width: 15%;
    height: 25px;
    border: 1px solid #ff7200;
    border-radius: 4px;
    background-color: #ff7200;
    color: #fff;
    cursor: pointer;
   
}
.viewClausebtn:hover{
    height: 25px;
    border-radius: 4px;
    background-color: #fff;
    color: #ff7200;
    cursor: pointer;
}
.performanceBtn{
    width: auto;
    height: 25px;
    border: 1px solid #ff7200;
    border-radius: 4px;
    background-color: #ff7200;
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
}
.performanceBtn:hover{
    width: auto;
    height: 25px;
    border: 1px solid #ff7200;
    border-radius: 4px;
    background-color: #fff;
    color: #ff7200;
    cursor: pointer;
    margin-left: 10px;
}
/*  */
.contractHeader1-two{
    min-width: 35%;
    height: 85%;
    border: 1px solid silver;
    border-radius: 4px;
    margin-right: 20px;
}
.contractHeader1-three{
    width: 23%;
    height: 100%;
    border-radius: 12px;
    position: relative;    
}
.contactAdd{
    width: 77%;
    height: 32px;
    position: absolute;
    top: 45%;
    translate: transformY(-50%);
}

/*  */
.contract-header2{
    width: 100%;
    height: auto;
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 9px;
    border: 1px solid #DCDCDC;
    margin-bottom: 30px;
}
.Contract-h2-div-1{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
}
.contract-h2-r1{
    width: 100%;
    height: 18%;
    display: flex;
    align-items: flex-start;
    text-align: start;
}
.contract-h2-r1 span{
    font-size: 1.1rem;
    padding-left: 10px;
    font-weight: 700;
}
.contract-h2-r2{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-around;
}
.Contract-div-1-c1{
    width: 200px;
    height: 90px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
    margin-right: 10px;
}
.contract-c1-r1{
    width: 25%;
    height: 60%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: white;
}
.contract-c1-r1 img{
    width: 26px;
    height: 24px;
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
}
.contract-c1-r2{
    width: 50%;
    height: 62%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content:space-around ;
}
/*  */
.Contract-h2-div-2{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Contract-h2-div-3{
    width: 100%;
    height: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}
.contract-h2-r3{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-around;
}
.Contract-div-r3-c1{
    width: 23%;
    height: 80px;
    display: flex;
    position: relative;
    justify-content: space-around;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 10px;
    background-color:rgb(239, 239, 239) ;
}
.Contract-div-1-c4{
    width: 20%;
   height: 75px;
   display: flex;
   position: relative;
   justify-content: space-around;
   border: 1px solid rgb(209, 209, 209);
   border-radius: 10px;
}
/*  */
.c1-r2-t1{
    color: #ff7200;
    font-size: 1.5rem;
    font-weight: 600;
}
.c1-r2-t2{
    color:  #888; 
    font-size: 0.7rem;
    font-weight: 700;
}













