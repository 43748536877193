.customVarArea {
  height: 180px;
  overflow-y: scroll;
  justify-content: flex-start;
  gap: 1em;
  padding: 0.5em;
  border: 1px solid rgb(0 0 0 / 10%);
}

.customVarWrapper {
  border: 1px solid rgb(0 0 0 / 20%);
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
}
.customVarDropZone {
  flex-basis: 80%;
  background-color: rgb(0 0 0 / 5%);
  border: none;
  border-right: 1px solid rgb(0 0 0 / 5%);
  min-height: 75px;
  flex-wrap: nowrap;
  gap: 1em;
  padding: 0.5em;
  overflow-x: scroll;
}
.customVarDropZone .operatorElem {
  flex: 0 0 auto;
  height: auto;
  margin: 0;
}
.dragElemCustomVar,
.dragElemCustomVar:hover {
  flex-basis: 80%;
  min-height: 35px;
  background-color: #ff7200;
}
.dragElemCustomVar .operators {
  font-size: 0.7vw;
  color: black;
}
.customVarBtnsWrapper {
  flex-basis: 20%;
  display: flex;
  gap: 1em;
}

.custVarIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.custVarIcon.saveIcon {
  color: rgb(54, 156, 54);
}
.custVarIcon.cancelIcon {
  color: rgb(223, 48, 48);
}

.formulaHighlight {
  scale: 1.25;
  background-color: black;
}
.formulaHighlight--err {
  scale: 1.25;
  background-color: red;
  color: white !important;
}
